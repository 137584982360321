import { GetAsync } from '../api';

/**
 * Método responsável por obter os planos do consultor
*/
const GetPlans = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await GetAsync(`/coach-plans`, config, {})
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

export {
    GetPlans
}