import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import './Select.scss';

export default function Select({
  name,
  label,
  options,
  errors,
  control,
  defaultValue,
  setValue = null,
  freeSolo = false,
  filterOptions = undefined,
  onKeyUp = undefined,
  disabled = false,
  fullWidth = false
}) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) =>
          <Autocomplete
            fullWidth={fullWidth}
            name={name}
            options={options}
            filterOptions={filterOptions}
            disabled={disabled}
            value={value && value}
            renderInput={(params) => <TextField {...params} label={label} />}
            onChange={(_, data) => {
              onChange(data);
              if (setValue) setValue(data);
              return data;
            }}
            onKeyUp={onKeyUp}
            defaultValue={defaultValue}
            freeSolo={freeSolo}
            noOptionsText="Não há opções"
          />
        }
      />
      {errors && <p className='error'>{errors.message}</p>}
    </>

  );
}