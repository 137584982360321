import React from 'react';
// import { Alert, AlertTitle, Button } from '@mui/material';

import SideMenu from '../SideMenu/SideMenu';
import WhatsAppSupport from '../WhatsAppSupport/WhatsAppSupport';

import './PageWrapper.scss';

const PageWrapper = ({ children, menuItem }) => {
    // const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    // const [showAlert, setShowAlert] = useState(consultant.marketplaceToken === null || consultant.marketplaceToken === '');

    return (
        <div className="page-wrapper">
            {/* {showAlert && (
                <Alert
                    severity="warning"
                    onClose={() => setShowAlert(false)}
                    sx={{
                        position: 'absolute',
                        top: 30,
                        left: '50%',
                        transform: 'translate(-50%)',
                        transition: 'all ease 0.5s',
                        width: '50%',
                        zIndex: 999,
                        fontWeight: '600',
                        boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.2)'
                    }}>
                    <AlertTitle>Pendência MercadoPago</AlertTitle>
                    Para receber pagamentos você precisa conectar sua conta com o integrador de pagamentos e criar uma chave PIX. (caso já tenha feito, recarregue a página).
                    <Button
                        rel="noreferrer"
                        target="_blank"
                        href={`${process.env.REACT_APP_MERCADO_PAGO_URL.replace('{STATE}', consultant.id)}`}
                        sx={{ fontSize: 13 }}
                    > Clique aqui para conectar.
                    </Button>
                </Alert>
            )} */}
            <div className="container">
                <div className="side-menu-container">
                    <SideMenu menuItem={menuItem} />
                </div>

                {children}
            </div>
            <WhatsAppSupport />
        </div>
    );
};

export default PageWrapper;