import React, { useRef, useState } from "react";
import Compressor from 'compressorjs';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ShareIcon from '@mui/icons-material/IosShare';
import {
    IconButton,
    TextField,
    Button,
    Typography
} from "@mui/material";

import { GetMonthName } from '../../../../Helpers/Date';

import { UpdateProfilePic } from '../../../../Services/Consultants';
import { GetConsultant } from '../../../../Services/Auth';

import "./ProfileInfo.scss";

const ProfileInfo = ({
    name,
    occupation,
    students,
    newStudents,
    lastMonthRevenue,
    yearRevenue
}) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = GetMonthName(date.getMonth());

    const hiddenFileInput = useRef(null);
    const [file, setFile] = useState();
    const [link, setLink] = useState();

    const handleChangeProfilePicture = (e) => {
        const image = e.target.files[0];
        new Compressor(image, {
            quality: 0.5,
            success: async (compressedResult) => {
                const access_token = localStorage.getItem('access_token');
                const newProfile = URL.createObjectURL(compressedResult);
                setFile(newProfile);
                await UpdateProfilePic(access_token, consultant.id, compressedResult);

                setTimeout(async () => {
                    const consultant = await GetConsultant(access_token);
                    sessionStorage.setItem('consultant', JSON.stringify(consultant.data));
                }, 500);
            },
        });
    };

    const generateLink = () => {
        const baseUrl = process.env.REACT_APP_NEW_STUDENT_BASE_URL;
        const referralLink = `${baseUrl}/signup?referralId=${consultant.id}`;
        const url = new URL(referralLink).href;
        navigator.clipboard.writeText(url);
        setLink(url);
    };

    return (
        <div className="profile-info">
            <IconButton
                onClick={() => hiddenFileInput.current.click()}
                color="info"
                aria-label="upload picture"
                component="span"
                style={{ backgroundColor: 'transparent' }}
            >
                <div className="profile-picture-container">
                    {(!file && !consultant.profileImagePath) && <img
                        src="https://img.freepik.com/vetores-premium/icone-de-um-rosto-de-homem-com-pele-clara_238404-798.jpg"
                        alt="avatar"
                        height={200}
                        width={200}
                    />}
                    {!file && consultant.profileImagePath && <img
                        src={`${process.env.REACT_APP_S3_BUCKET_URL}/${consultant.profileImagePath}?dummy${new Date().getMilliseconds()}`}
                        alt="avatar"
                        height={300}
                        width={250}
                    />}
                    {file && <img
                        src={file}
                        alt="avatar"
                        height={300}
                        width="100%"
                    />}

                    <div>
                        <PhotoCameraIcon />
                        <input type="file"
                            name="myImage"
                            ref={hiddenFileInput}
                            style={{ display: 'none' }}
                            onChange={handleChangeProfilePicture}
                        />
                    </div>
                </div>
            </IconButton>

            <strong>{name}</strong>
            <span>{occupation}</span>

            <div className="revenue-info">
                <span>Protocolos</span>
                <span className="value">{students}</span>
            </div>
            <div className="revenue-info">
                <span>Novos Protocolos (30 dias)</span>
                <span className="value">{newStudents}</span>
            </div>
            <div className="revenue-info">
                <span>Receita ({currentMonth}.)</span>
                <span className="value money">{lastMonthRevenue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
            </div>
            <div className="revenue-info">
                <span>Faturamento ({currentYear})</span>
                <span className="value money">{yearRevenue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
            </div>


            <div className="revenue-info" style={{
                cursor: "pointer",
                paddingTop: 15,
                marginTop: 1,
            }} onClick={() => generateLink()}>
                {!link && (<>
                    <Button style={{ backgroundColor: "#c7427b" }} variant="contained" endIcon={<ShareIcon sx={{ color: "white" }} />}>
                        Gerar convite da plataforma
                    </Button>
                </>)}

                {link && (
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Typography variant="title2" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            Envie para um amigo
                            <ShareIcon />
                        </Typography>
                        <TextField
                            style={{ backgroundColor: '#e0e0e0' }}
                            variant="outlined"
                            fullWidth
                            value={link}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProfileInfo;