import React from 'react';

import './EmptyState.scss';

const EmptyState = ({ title, subtitles, showImage = true }) => {
    const APP_PATH = process.env.REACT_APP_PUBLIC_URL;

    return (
        <section className="empty-state">
            <div>
                {showImage && <div className="images-container">
                    <img src={APP_PATH + "group-top.svg"} alt="group-top" className="image-top" />
                    <img src={APP_PATH + "group-quite.svg"} alt="group-quite" className="image-quite" />
                    <img src={APP_PATH + "group-bottom.svg"} alt="group-bottom" className="image-bottom" />
                </div>}
                <h4>{title}</h4>
                <div>
                    {subtitles.map((sub, i) => <p key={i}>{sub}</p>)}
                </div>
            </div>
        </section>
    );
};

export default EmptyState;