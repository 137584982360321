import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Modal } from '@mui/material';

import { Sleep } from "../../../../../../Helpers/Timer";

import TextFieldTrainMe from '../../../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../../../Components/PrimaryButton/PrimaryButton';
import Title from '../../../../../../Components/Title/Title';
import Select from '../../../../../../Components/Select/Select';

import { CreateExercise, UpdateExercise } from '../../../../../../Services/Exercises/index';

import './ModalExercise.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo nome do exercício é obrigatório!"),
    repetitions: yup.string().required("O campo quantidade de repetições é obrigatório!"),
    series: yup.number().required("O campo quantidade de séries é obrigatório!"),
    rest: yup.number().required("O campo de descanso é obrigatório!"),
    cadency: yup.string().required("O campo cadência é obrigatório!"),
    executionMethod: yup.string().required("O campo forma de execução é obrigatório!"),
    observations: yup.string(),
});

const ModalExercise = ({
    open,
    handleModal,
    exercises,
    setExercises,
    exerciseItem,
    workoutId,
    callbackExercises
}) => {
    const CARDIO_GROUP_ID = Number(process.env.REACT_APP_CARDIO_GROUP_ID);

    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);
    const access_token = localStorage.getItem('access_token');

    const saveExercise = async (payload) => {
        // Verifica se é criação ou update 
        if (exerciseItem === undefined) {
            const dataCreate = {
                training: workoutId,
                name: payload.name,
                exerciseLibrary: payload.exerciseLibrary.id,
                repetitions: String(payload.repetitions),
                series: String(payload.series),
                rest: String(payload.rest),
                cadency: payload.cadency,
                observations: payload.observations,
                executionMethod: payload.executionMethod
            };
            await CreateExercise(access_token, dataCreate);
        } else {
            const dataUpdate = {
                id: exerciseItem.id,
                training: workoutId,
                name: payload.name,
                exerciseLibrary: exerciseItem.exerciseLibrary.id,
                repetitions: String(payload.repetitions),
                series: String(payload.series),
                rest: String(payload.rest),
                cadency: payload.cadency,
                observations: payload.observations,
                executionMethod: payload.executionMethod
            };
            UpdateExercise(access_token, dataUpdate);
        }
    };

    const onSubmitHandler = async (payload) => {
        setLoading(true);
        await saveExercise(payload);
        await Sleep(1000);

        const newExercises = [...exercises];
        let indexExercise = newExercises.findIndex((ex) => ex.id === exerciseItem.id);
        if (indexExercise === -1) {
            await callbackExercises();
        } else {
            newExercises[indexExercise].name = payload.name;
            newExercises[indexExercise].executionMethod = payload.executionMethod;
            newExercises[indexExercise].repetitions = payload.repetitions;
            newExercises[indexExercise].series = payload.series;
            newExercises[indexExercise].rest = payload.rest;
            newExercises[indexExercise].cadency = payload.cadency;
            newExercises[indexExercise].observations = payload.observations;
            setExercises(newExercises);
        }

        handleModal();
        setLoading(false);
    };

    useEffect(() => {
        if (exerciseItem !== undefined) {
            setValue('name', exerciseItem.name);
            setValue('executionMethod', exerciseItem.executionMethod);
            setValue('repetitions', exerciseItem.repetitions);
            setValue('series', exerciseItem.series);
            setValue('rest', exerciseItem.rest);
            setValue('cadency', exerciseItem.cadency);
            setValue('observations', exerciseItem.observations);
        }
    }, [setValue, exerciseItem]);

    return (
        <Modal
            open={open}
            onClose={handleModal}
        >
            <div className='modal-edit-exercise'>
                <form className='modal-add' id='edit-exercise-form'>
                    <Box sx={{ p: 2 }} className='titles'>
                        <div>
                            <div className='div-image'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-spare-exercise.svg"} alt={"icon-modal-spare-exercise"} />
                            </div>
                            <div className='div-title'>
                                <Title title="Editar exercício" />
                                <p>Insira as informações abaixo</p>
                            </div>
                        </div>

                        <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleModal} />
                    </Box>

                    <Box sx={{
                        overflow: { xs: 'scroll', sm: 'unset' },
                        height: { xs: '40vh', sm: 'inherit' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        px: 3,
                        py: 2
                    }}
                    >
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextFieldTrainMe
                                control={control}
                                name={'name'}
                                label={'Nome do exercício'}
                                fullWidth
                                errors={errors.name}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Select
                                name="executionMethod"
                                options={exerciseItem && exerciseItem.group.id === CARDIO_GROUP_ID ? ['Padrão', 'HIIT'] : ['Padrão', 'Rest and Pause', 'Drop Set', 'Bi-set', 'Tri-set', 'Super Série', 'Circuito', 'FST-7', 'Cluster Set']}
                                defaultValue={exerciseItem.executionMethod ?? 'Padrão'}
                                setValue={(data) => setValue('executionMethod', data)}
                                label="Forma de execução"
                                control={control}
                                errors={errors.executionMethod}
                                fullWidth
                            />
                        </Box>

                        {(exerciseItem && exerciseItem.group.id === CARDIO_GROUP_ID) && (<>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <TextFieldTrainMe
                                    control={control}
                                    name={'repetitions'}
                                    label={exerciseItem && exerciseItem.group.id === CARDIO_GROUP_ID ? 'Tempo de cardio (minutos)' : 'Quantidade de repetições'}
                                    fullWidth
                                    errors={errors.repetitions}
                                />
                            </Box>
                        </>)}
                        {(!exerciseItem || exerciseItem.group.id !== CARDIO_GROUP_ID) && (<>

                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <TextFieldTrainMe
                                    control={control}
                                    name={'repetitions'}
                                    label={exerciseItem && exerciseItem.group.id === CARDIO_GROUP_ID ? 'Tempo de cardio (minutos)' : 'Quantidade de repetições'}
                                    fullWidth
                                    errors={errors.repetitions}
                                />
                                <TextFieldTrainMe
                                    control={control}
                                    name={'series'}
                                    type="number"
                                    label="Quantidade de séries"
                                    fullWidth
                                    errors={errors.series}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <TextFieldTrainMe
                                    control={control}
                                    name={'rest'}
                                    label={'Descanso (em segundos)'}
                                    type="number"
                                    fullWidth
                                    errors={errors.rest}
                                />
                                <TextFieldTrainMe
                                    control={control}
                                    name={'cadency'}
                                    label="Cadência"
                                    fullWidth
                                    errors={errors.cadency}
                                />
                            </Box>
                        </>)}

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextFieldTrainMe
                                control={control}
                                name={'observations'}
                                label={'Adicione as observações'}
                                fullWidth
                                errors={errors.observations}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <PrimaryButton
                            text="Editar"
                            onClick={handleSubmit(onSubmitHandler)}
                            loading={loading}
                        />
                    </Box>
                </form>
            </div>
        </Modal>
    );
};

export default ModalExercise;