import { GetAsync, PostAsync, PutAsync } from '../api';

/**
 * Método responsável por obter os chats com os estudantes
*/
const GetChats = (token, page = 1, size = 15) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/chats?userId=${consultant.id}&page=${page}&size=${size}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por obter os chats com os estudantes
*/
const GetChatMessages = (token, page = 1, id, size = 10) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/chats/${id}?page=${page}&size=${size}&orderBy=DESC&order=createdAt`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};


/**
 * Método responsável criar mensagens em um chat
*/
const CreateMessage = (token, chatId, message) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/chats/${chatId}`, config, message);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por ler as mensagens
*/
const ReadMessages = (token, chatId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/chats/${chatId}`, config, {});
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}



export {
    GetChats,
    GetChatMessages,
    CreateMessage,
    ReadMessages
}