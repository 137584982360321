import React, { useState } from 'react';
import {
    TextField,
    InputAdornment,
    IconButton,
    Box,
} from '@mui/material';
import { FileCopy, Check } from '@mui/icons-material';

const PaymentLink = ({ link }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(link).then(() => {
            setCopied(true);  // Define o estado como copiado
            setTimeout(() => setCopied(false), 5000); // Reverte para o ícone original após 5 segundos
        });
    };

    return (
        <TextField
            value={link}
            disabled
            sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                    borderRadius: '8px',  // Bordas arredondadas
                    backgroundColor: '#f5f5f5',  // Cor de fundo do input
                    paddingRight: 5, // Espaço para o ícone do botão
                },
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Box
                            sx={{ bgcolor: '#FFF', px: 1, fontSize: 14, fontWeight: 600, borderRadius: 2 }}
                            onClick={handleCopy}
                        >
                            <IconButton
                                sx={{
                                    color: '#1976d2', // Cor do ícone
                                    '&:hover': {
                                        backgroundColor: '#e3f2fd', // Efeito hover
                                    }
                                }}
                            >
                                {copied ? <Check /> : <FileCopy />}
                            </IconButton>
                            {copied ? 'Copiado' : 'Copiar'}
                        </Box>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PaymentLink;