import React, { useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import {
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Grid,
    Box,
    Paper
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ptBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { AppContext } from "../../Helpers/Context";

import { AuthLogin, AuthRefresh } from '../../Services/Auth/index';
import { GetConsultant } from '../../Services/Auth/index';

import './SignIn.scss';

const SignIn = () => {
    const { signIn } = useContext(AppContext);
    const navigate = useNavigate();
    const theme = createTheme({}, ptBR);

    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [keepConnected, setKeepConnected] = useState(false);

    const refreshToken = async () => {
        const refresh_token = localStorage.getItem('refresh_token');
        const response = await AuthRefresh(refresh_token);

        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {
            setLoading(true);
            const response = await AuthLogin({
                email: data.get('email'),
                password: data.get('password'),
            });
            const consultant = await GetConsultant(response.access_token)
            sessionStorage.setItem('consultant', JSON.stringify(consultant.data));

            await signIn({ access_token: response.access_token });

            if (consultant.data.isNewUser) {
                navigate('/manage-account', { state: { changePassword: true } });
            }

            localStorage.setItem('access_token', response.access_token);
            localStorage.setItem('refresh_token', response.refresh_token);
            localStorage.setItem('keep_connected', keepConnected);
            localStorage.removeItem('device-warning');

            const intervalId = setInterval(async () => {
                await refreshToken();
            }, (response.expires_in - 300) * 1000);
            localStorage.setItem('refresh_token_interval_id', intervalId);

            const isMobile = window.innerWidth < 768;
            if (isMobile) {
                navigate('/manage-students');
            }
        } catch {
            setErrorMessage('Credenciais inválidas');
        } finally {
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container className="sign-in" sx={{ height: "100%" }}>
                <CssBaseline />

                <Grid
                    item
                    xs={false}
                    sm={false}
                    md={false}
                    lg={6}
                    xl={6}
                    sx={{
                        backgroundImage: `url(Assets/Images/homem-halteres.jpg)`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            mx: { lg: 15, sm: 10, md: 7, xs: 2 },
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "90%"
                        }}

                    >
                        <img className="logo" src="/logo-logotipo.png" alt="logo" width="100%" />
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                sx={{ mt: 0 }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            {errorMessage && (
                                <p style={{ color: 'red' }}>{errorMessage}</p>
                            )}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}>
                                <FormControlLabel
                                    control={<Checkbox value={keepConnected} color="primary" />}
                                    value={keepConnected}
                                    onChange={() => setKeepConnected(!keepConnected)}
                                    label="Mantenha conectado"
                                />
                                <Box mt={1}>
                                    <Link to="/reset-password" style={{ textDecoration: "none" }} variant="body2">Esqueceu a senha?</Link>
                                </Box>
                            </Box>

                            <LoadingButton
                                type="submit"
                                loading={loading}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, backgroundColor: '#1e306e', fontWeight: '600' }}
                            > Entrar
                            </LoadingButton>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                <p>Não tem uma conta?</p> &nbsp;
                                <Link style={{ textDecoration: "none" }} to="/signup" variant="body2">Crie agora</Link>
                            </Box>

                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default SignIn;