import React, { useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Modal } from '@mui/material';

import TextFieldTrainMe from '../../../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../../../Components/PrimaryButton/PrimaryButton';
import Title from '../../../../../../Components/Title/Title';
import Select from '../../../../../../Components/Select/Select';

import { CreateExercise } from "../../../../../../Services/Exercises/index";

import { ExercisesContext } from '../../../../../../Context/ExercisesContext';

import './ModalNewExercise.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo nome do exercício é obrigatório!"),
    executionMethod: yup.string().required("O campo forma de execução é obrigatório!"),
    repetitions: yup.string().required("O campo quantidade de repetições é obrigatório!"),
    series: yup.number().required("O campo quantidade de séries é obrigatório!"),
    rest: yup.number().required("O campo de descanso é obrigatório!"),
    cadency: yup.string().required("O campo cadência é obrigatório!"),
    observations: yup.string(),
});

const ModalNewExercise = ({ open, handleModal, exercise, trainingId }) => {
    const access_token = localStorage.getItem('access_token');
    const { exercises, setExercises } = useContext(ExercisesContext);
    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const handleAddExercise = async (data) => {
        const lastItem = exercises[exercises.length - 1];
        const payload = {
            training: trainingId,
            exerciseLibrary: exercise.id,
            name: data.name,
            group: data.group,
            executionMethod: data.executionMethod,
            repetitions: data.repetitions,
            series: String(data.series),
            rest: data.rest.toString(),
            cadency: data.cadency,
            observations: data.observations,
            order: lastItem ? lastItem.order + 1 : 1,
            spareExercise: undefined,
            linkedExercises: []
        };
        const exerciseData = await CreateExercise(access_token, payload);
        setExercises(
            [
                ...exercises,
                {
                    ...payload,
                    ...data,
                    id: exerciseData.data.id,
                    order: exerciseData.data.order,
                    exerciseLibrary: { id: exercise.id, media: exercise.media, image: exercise.image }
                }
            ]
        );
    };

    const onSubmitHandler = (payload) => {
        handleAddExercise({ ...payload, group: exercise.group });
        handleModal();
    };

    useEffect(() => {
        if (exercise) {
            setValue('name', exercise.name);
            setValue('executionMethod', 'Padrão');
            setValue('repetitions', '12');
            setValue('series', '4');
            setValue('rest', 60);
            setValue('cadency', '1:1');
            setValue('observations', '');
        }
    }, [setValue, exercise]);

    return (
        <Modal
            open={open}
            onClose={handleModal}
        >
            <div className='modal-new-exercise'>
                <form className='modal-add' id='edit-exercise-form'>
                    <Box sx={{ p: 2 }} className='titles'>
                        <div>
                            <div className='div-image'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-spare-exercise.svg"} alt={"icon-modal-spare-exercise"} />
                            </div>
                            <div className='div-title'>
                                <Title title="Adicionar exercício" />
                                <p>Insira as informações abaixo</p>
                            </div>
                        </div>

                        <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleModal} />
                    </Box>

                    <Box sx={{
                        overflow: { xs: 'scroll', sm: 'unset' },
                        height: { xs: '40vh', sm: 'inherit' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        px: 3,
                        py: 2
                    }}
                    >
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextFieldTrainMe
                                control={control}
                                name="name"
                                label="Nome do exercício"
                                fullWidth
                                errors={errors.name}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
                            <Select
                                name="executionMethod"
                                options={['Padrão', 'Rest and Pause', 'Drop Set', 'Bi-set', 'Tri-set', 'Super Série', 'Circuito', 'FST-7', 'Cluster Set']}
                                defaultValue={'Padrão'}
                                setValue={(data) => setValue('executionMethod', data)}
                                label="Forma de execução"
                                control={control}
                                errors={errors.executionMethod}
                                fullWidth
                            />
                        </Box>

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextFieldTrainMe
                                control={control}
                                name="repetitions"
                                defaultValue=""
                                label="Quantidade de repetições"
                                fullWidth
                                errors={errors.repetitions}
                            />
                            <TextFieldTrainMe
                                control={control}
                                name="series"
                                defaultValue=""
                                type="number"
                                label="Quantidade de séries"
                                fullWidth
                                errors={errors.series}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextFieldTrainMe
                                control={control}
                                name="rest"
                                defaultValue={60}
                                label={'Descanso (em segundos)'}
                                type="number"
                                fullWidth
                                errors={errors.rest}
                            />
                            <TextFieldTrainMe
                                control={control}
                                name="cadency"
                                defaultValue="1:1"
                                label="Cadência"
                                fullWidth
                                errors={errors.cadency}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextFieldTrainMe
                                control={control}
                                name="observations"
                                defaultValue=""
                                label="Adicione as observações"
                                fullWidth
                                errors={errors.observations}
                            />
                        </Box>
                    </Box>
                    <div className='button-container'>
                        <PrimaryButton text="Adicionar" onClick={handleSubmit(onSubmitHandler)} />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ModalNewExercise;