import React, { useState, useContext } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { cpf } from "cpf-cnpj-validator";
import {
    CssBaseline,
    FormControlLabel,
    Checkbox,
    Grid,
    Box,
    Paper,
    Typography,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ptBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Feedback from '../../Components/Feedback/Feedback';
import TextFieldTrainMe from '../../Components/TextField/TextField';
import CustomInput from './Components/CustomInput/CustomInput';

import { AppContext } from "../../Helpers/Context";

import { AuthLogin, AuthRefresh, GetConsultant } from '../../Services/Auth/index';
import { CreateAccount } from '../../Services/Consultants/index';

import './SignUp.scss';

const phoneRegExp = /^\(\d{2}\) \d{4,5}-\d{4}$/gi;
const schemaPersonalData = yup.object().shape({
    name: yup.string().required("O campo de nome é obrigatório!"),
    birthDate: yup.string()
        .required("O campo de data de nascimento é obrigatório!")
        .nullable()
        .test('birthDate', 'Data inválida', (value) => {
            var dateParts = value.split("/");
            if (Number(dateParts[0]) > 31) return false;
            if (Number(dateParts[1]) > 12) return false;
            if (Number(dateParts[2]) > new Date().getFullYear() || Number(dateParts[2]) < new Date().getFullYear() - 100) return false;
            return true;
        }),
    phone: yup.string()
        .required("O campo de telefone é obrigatório!")
        .matches(phoneRegExp, 'Celular inválido, formato correto: (11) 12345-6789'),
    cpf: yup.string()
        .required("O campo de CPF é obrigatório!")
        .max(14, 'Número máximo de digitos atingido')
        .test('cpf', 'CPF Inválido', (value) => cpf.isValid(value.replaceAll('.', '').replaceAll('-', ''))),
    email: yup.string()
        .required("O campo de email é obrigatório!")
        .email('Formato de email incorreto!'),
    occupation: yup.string(),
    password: yup.string()
        .required("O campo de senha é obrigatório!"),
    confpassword: yup.string()
        .required("O campo de confirmação de senha é obrigatório!")
        .oneOf([yup.ref('password'), null], 'Suas senhas estão diferentes'),
});

const SignUp = () => {
    const { signIn } = useContext(AppContext);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const theme = createTheme({}, ptBR);
    const { control, handleSubmit, formState: { errors }, register, setValue } = useForm({
        resolver: yupResolver(schemaPersonalData),
    });

    const referralId = searchParams.get('referralId');

    const [loading, setLoading] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [gender, setGender] = useState('M');
    const [termsMessage, setTermsMessage] = useState('');
    const [error, setError] = useState('');

    const refreshToken = async () => {
        const refresh_token = localStorage.getItem('refresh_token');
        const response = await AuthRefresh(refresh_token);
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);
    };

    const signInConsultant = async (data) => {
        try {
            setLoading(true);
            const response = await AuthLogin({
                email: data.email,
                password: data.password,
            });
            const consultant = await GetConsultant(response.access_token)
            sessionStorage.setItem('consultant', JSON.stringify(consultant.data));

            await signIn({ access_token: response.access_token });

            localStorage.setItem('access_token', response.access_token);
            localStorage.setItem('refresh_token', response.refresh_token);
            localStorage.removeItem('device-warning');

            const intervalId = setInterval(async () => {
                await refreshToken();
            }, (response.expires_in - 300) * 1000);
            localStorage.setItem('refresh_token_interval_id', intervalId);
            navigate('/assistente-protocolos');
        } catch {
            // setErrorMessage('Credenciais inválidas');
        } finally {
            setLoading(false);
        }
    };

    const createAccount = async (data) => {
        try {
            setTermsMessage('');
            if (!agreeTerms) {
                setTermsMessage('É necessário aceitar os termos de uso e condições da plataforma.');
                return;
            }
            setLoading(true);

            const dateParts = data.birthDate.split("/");
            const birth = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

            const payload = {
                name: data.name,
                email: data.email,
                document: data.cpf.replaceAll('.', '').replaceAll('-', ''),
                birthDate: birth,
                phone: data.phone,
                password: data.password,
                credentialType: "CREF",
                credential: "",
                occupation: data.occupation,
                gender: gender,
                referralId
            };
            var response = await CreateAccount(payload);
            if (response) {
                setError(Array.isArray(response?.error) ? 'Ocorreu um erro ao tentar criar sua conta.' : response?.error);
                return;
            }

            setLoading(false);
            signInConsultant({ email: data.email, password: data.password })
        } catch (ex) {
            console.log(ex);
            setError(ex?.response?.data?.error ?? 'Ocorreu um erro ao tentar criar sua conta.');
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container className="sign-in" sx={{ height: "100%" }}>
                <CssBaseline />
                <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            mt: 2,
                            mx: { lg: 15, sm: 10, md: 7, xs: 2 },
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "90%"
                        }}
                    >
                        <Typography variant="h2" className="sign-in-title" mt={3}>Crie sua conta.</Typography>
                        <Box my={1} width="100%">
                            {error && (<Feedback severity="error" text={error} />)}
                        </Box>

                        <Box component="form">
                            <Box mt={1} mb={2}>
                                <TextFieldTrainMe
                                    control={control}
                                    required
                                    fullWidth
                                    id="name"
                                    label="Nome"
                                    name="name"
                                    autoComplete="name"
                                    autoFocus
                                    errors={errors.name}
                                />
                            </Box>
                            <Box mt={1} mb={2}>
                                <TextFieldTrainMe
                                    control={control}
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    errors={errors.email}
                                />
                            </Box>

                            <Box mt={1} mb={2} width="100%" sx={{
                                display: "flex",
                                flexDirection: { lg: "row", sm: "row", md: "row", xs: "column" },
                                alignItems: { lg: "center", xs: false },
                                gap: 2
                            }}>
                                <CustomInput
                                    label="CPF"
                                    name="cpf"
                                    mask="000.000.000-00"
                                    errors={errors.cpf}
                                    register={register}
                                    onChange={(e) => setValue('cpf', e.target.value)}
                                />
                                <CustomInput
                                    label="Data de nascimento"
                                    name="birthDate"
                                    mask="00/00/0000"
                                    errors={errors.birthDate}
                                    register={register}
                                    onChange={(e) => setValue('birthDate', e.target.value)}
                                />
                            </Box>

                            <Box mt={1} mb={2}>
                                <CustomInput
                                    label="Celular"
                                    name="phone"
                                    mask="(00) 00000-0000"
                                    errors={errors.phone}
                                    register={register}
                                    onChange={(e) => setValue('phone', e.target.value)}
                                />
                            </Box>

                            <Box mt={2} mb={1}>
                                <TextFieldTrainMe
                                    control={control}
                                    required
                                    fullWidth
                                    id="occupation"
                                    label="Ocupação (Personal Trainer, Nutricionista...)"
                                    name="occupation"
                                    errors={errors.occupation}
                                />
                            </Box>
                            <Box mb={2} width="100%">
                                <FormControl>
                                    <FormLabel id="radio-buttons-group-label">
                                        <Typography component="h3" variant="subtitle1" color="#000" sx={{ mb: 1 }}>
                                            Gênero
                                        </Typography>
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="radio-buttons-group-label"
                                        defaultValue="M"
                                        name="radio-buttons-group"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row"
                                        }}
                                    >
                                        <FormControlLabel value="M" control={<Radio />} label="Masculino" />
                                        <FormControlLabel value="F" control={<Radio />} label="Feminino" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <Box mt={1} mb={2}>
                                <TextFieldTrainMe
                                    control={control}
                                    required
                                    fullWidth
                                    name="password"
                                    label="Senha"
                                    type="password"
                                    id="password"
                                    errors={errors.password}
                                />
                            </Box>
                            <Box mt={1} mb={2}>
                                <TextFieldTrainMe
                                    control={control}
                                    required
                                    fullWidth
                                    name="confpassword"
                                    label="Confirme sua senha"
                                    type="password"
                                    id="confpassword"
                                    errors={errors.confpassword}
                                />
                            </Box>
                            <Box my={1}>
                                <FormControlLabel
                                    control={<Checkbox value={agreeTerms} onChange={() => setAgreeTerms(!agreeTerms)} color="primary" />}
                                    label={
                                        <p>
                                            ESTOU CIENTE DAS CONDIÇÕES E DOS &nbsp;
                                            <a
                                                style={{ textDecoration: "none" }}
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.coach.trainme.com.br/privacy-police"
                                            >TERMOS DE USO</a> E &nbsp;
                                            <a
                                                style={{ textDecoration: "none" }}
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.coach.trainme.com.br/privacy-police"
                                            >
                                                POLÍTICAS DE PRIVACIDADE.
                                            </a>
                                        </p>
                                    }
                                />
                                {termsMessage && <p style={{ color: '#db3d60' }}>{termsMessage}</p>}
                            </Box>

                            <LoadingButton
                                loading={loading}
                                fullWidth
                                variant="contained"
                                onClick={handleSubmit(createAccount)}
                                sx={{ mt: 3, mb: 2, backgroundColor: '#1e306e', fontWeight: '600' }}
                            > Criar Conta
                            </LoadingButton>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                <p>Já tem uma conta?</p> &nbsp;
                                <Link style={{ textDecoration: "none" }} to="/" variant="body2">Entre agora</Link>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={false}
                    sm={false}
                    md={7}
                    lg={7}
                    sx={{
                        display: { xs: "none", sm: "none", md: "block" },
                        backgroundImage: `url(Assets/Images/gym-motivation.jpeg)`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: { sm: "20%", md: "35%", lg: "40%", xl: "center" },
                    }}
                >
                    <img
                        className="logo"
                        src="/logo-logotipo.png"
                        alt="logo"
                        width="100%"
                    />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default SignUp;