import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Typography, Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';

import { PostPix, GetPayment } from "../../../../Services/Payments";

const PixPayment = ({ personalData, callback, consultantName, planInfo, consultantId, gender, isMobile, plansActivityId }) => {
    const [paymentInfo, setPaymentInfo] = useState();
    const [generateQrCode, setGenerateQrCode] = useState(false);
    const [loading, setLoading] = useState(false);
    const intervalFn = useRef(null);

    const validatePayment = useCallback(async (id, data) => {
        try {
            const response = await GetPayment(id, consultantId);
            if (response.data.approved) {
                clearInterval(intervalFn.current);
                callback(id, data);
            } else if (!intervalFn.current) {
                intervalFn.current = setInterval(async () => {
                    await validatePayment(id, data);
                }, 5000);
            }
        } catch (ex) {
            clearInterval(intervalFn.current);
        }
    }, [intervalFn, callback, consultantId]);

    const getPaymentInfo = async () => {
        try {
            setLoading(true);
            const name = personalData.name.split(' ');
            const payload = {
                firstName: name[0],
                lastName: name.slice(1).join().replaceAll(',', ' '),
                email: personalData.email,
                identification: personalData.cpf.replaceAll('.', '').replaceAll('-', ''),
                consultantName: consultantName,
                planName: planInfo.name,
                price: Number(planInfo.price),
                planId: planInfo.id,
                consultantId: consultantId,
                plansActivityId: plansActivityId ?? undefined,
                idStudent: personalData.id,
            };
            const response = await PostPix(payload);
            setPaymentInfo(response.data);
            setGenerateQrCode(true);

            setLoading(false);
            setTimeout(async () => {
                await validatePayment(response.data.id, { ...personalData, gender });
            }, 15000);
        }
        catch (ex) {
            console.log(ex);
            alert('Ocorreu um erro ao gerar o pagamento, verifique se o consultor possui uma chave PIX cadastrada.');
        }
    };

    useEffect(() => {
        if (intervalFn && !paymentInfo) {
            clearInterval(intervalFn);
        }

        return () => {
            clearInterval(intervalFn.current);
        };
    }, [intervalFn, paymentInfo]);

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: 500,
                    margin: { xs: 3, lg: '0 auto' },
                    padding: 3,
                }}
            >
                {!generateQrCode && (
                    <>
                        <Typography>A confirmação de pagamento é realizada em alguns minutos.</Typography>

                        <Alert severity="warning" sx={{ mt: 2 }}>
                            O prazo de validade do código PIX é de 30 minutos após gerado. Efetue o pagamento dentro do prazo para garantir a compra.
                        </Alert>

                        <LoadingButton
                            style={{ color: '#FFF', fontWeight: '600', fontSize: 16, backgroundColor: 'rgb(21 216 141 / 1)' }}
                            sx={{ mt: 2 }}
                            fullWidth
                            variant="contained"
                            loading={loading}
                            disabled={loading}
                            onClick={getPaymentInfo}
                        >{loading ? '...' : 'Finalizar'}
                        </LoadingButton>
                    </>
                )}

                {paymentInfo && (
                    <>
                        <Typography variant="h3">Utilize o aplicativo do seu banco para efetuar o pagamento.</Typography>

                        {!isMobile && <img
                            width={250}
                            height={250}
                            alt="qrcode"
                            src={`data:image/jpeg;base64,${paymentInfo.qr_code_base64}`}
                        />}

                        <input
                            style={{ width: isMobile ? '85%' : '50%' }}
                            type="text"
                            disabled
                            defaultValue={paymentInfo.qr_code}
                        />
                        <PrimaryButton
                            text="Clique aqui para copiar o PIX"
                            onClick={() => navigator.clipboard.writeText(paymentInfo.qr_code)}
                        />
                    </>
                )}
            </Stack>
        </>
    );
};

export default PixPayment;