import React, { useEffect, useState, useCallback } from "react";
import { CommonLoading } from 'react-loadingg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    InputBase,
    Paper,
    Typography
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PendingIcon from '@mui/icons-material/Pending';

import Header from "../../Components/Header/Header";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import PersonalInfo from "./Components/PersonalInfo/PersonalInfo";
import Questionnaires from "./Components/Questionnaires/Questionnaires";
import Protocols from "./Components/Protocols/Protocols";
import StudentItem from "./Components/StudentItem/StudentItem";
import PhotosEvolution from "./Components/PhotosEvolution/PhotosEvolution";
import ChatMessage from "./Components/ChatMessage/ChatMessage";
import OnboardingTour from "../../Components/OnboardingTour/OnboardingTour";
import ModalAddStudent from "../../Components/ModalAddStudent/ModalAddStudent";
import EmptyState from "../../Components/EmptyState/EmptyState";
import PaymentLink from "../../Components/PaymentLink/PaymentLink";

import { GetStudentQuizzes } from "../../Services/Questionnares/index";
import { GetStudents } from "../../Services/Students/index";
import { GetLibrary } from "../../Services/Meals/index";
import Grid from '@mui/material/Grid2';

import "./Students.scss";
const Students = () => {
    sessionStorage.removeItem('prevTab');
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const access_token = localStorage.getItem('access_token');
    const targetPathMenu = '.page-wrapper .container .content .content-students';
    const steps = [
        {
            target: `aaaa`,
            content: '',
        },
        {
            target: `${targetPathMenu} .student-item`,
            content: 'Lista com todos seus alunos.',
            title: "Seus alunos"
        },
        {
            target: `${targetPathMenu} .new-student-btn`,
            content: 'Botão para adicionar um novo aluno à sua lista.',
            title: "Novo aluno"
        },
        {
            target: `${targetPathMenu} .itens-container .MuiBox-root .MuiBox-root .personal-info`,
            content: 'Dados pessoais do aluno.',
            title: "Dados pessoais"
        },
        {
            target: `${targetPathMenu} .itens-container .MuiBox-root .MuiBox-root .photos-evolution`,
            content: 'Acompanhamento das fotos de evolução do aluno.',
            title: "Evolução"
        },
        {
            target: `${targetPathMenu} .itens-container .MuiBox-root .MuiBox-root .protocols`,
            content: 'Um protocolo é um programa estruturado com prazo definido onde será montado o treino/dieta do seu aluno. Pode ser adicionar no botão de "+".',
            title: "Protocolos"
        },
        {
            target: `${targetPathMenu} .itens-container .questionnaires`,
            content: 'Questinários personalizados por você que serão respondidos pelo aluno.',
            title: "Questionários"
        },
        {
            target: `${targetPathMenu} .itens-container .chat-message`,
            content: 'Para você poder trocar mensagens diretamente com seu aluno sem necessidade de outras ferramentas.',
            title: "Mensagens"
        },
    ];
    const doneTour = localStorage.getItem('guided-tour-students');
    const storedStudent = localStorage.getItem('studentSelected');

    const [show, setShow] = useState(false);
    const [students, setStudents] = useState([]);
    const [showAddStudentModal, setShowAddStudentModal] = useState(false);
    const [currentStudent, setCurrentStudent] = useState(() => {
        return storedStudent ? JSON.parse(storedStudent) : null;
    });
    const [search, setSearch] = useState('');
    const [run, setRun] = useState(!doneTour && window.innerWidth > 768);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [isMobile, setIsMobile] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [link, setLink] = useState();

    const checkIsMobile = () => {
        const mobile = window.innerWidth <= 768;
        setIsMobile(mobile);
    };

    const getStudentQuizzes = useCallback(async (studentId) => {
        const response = await GetStudentQuizzes(access_token, studentId);
        return response.data?.rows || [];
    }, [access_token]);

    const handleChangeStudent = useCallback(async (student, handle = false) => {
        const qst = await getStudentQuizzes(student.id);
        student.questionnaire = qst;
        if (handle) {
            setCurrentStudent(student);
            localStorage.setItem('studentSelected', JSON.stringify(student))
        }
    }, [getStudentQuizzes]);

    const loadStudents = useCallback(async (id, remove) => {
        const response = await GetStudents(access_token, page);
        let dataStudents = response.data?.rows.map((s) => ({
            ...s,
            profileImagePath: s.profileImagePath ? `${process.env.REACT_APP_S3_BUCKET_URL}/${s.profileImagePath}` : 'https://img.freepik.com/vetores-premium/icone-de-um-rosto-de-homem-com-pele-clara_238404-798.jpg'
        }));
        if (dataStudents.length === 0) {
            setHasMore(false);
            setStudents([]);
        } else {
            const studentSelected = localStorage.getItem('studentSelected');
            if (!studentSelected) {
                handleChangeStudent(dataStudents[0], true);
            } else {
                const currStudent = dataStudents.find(s => s.id === JSON.parse(studentSelected).id);
                if (currStudent) {
                    handleChangeStudent(currStudent, true);
                } else {
                    handleChangeStudent(dataStudents[0], true);
                }
            }
            setStudents((prevStudents) => {
                let prevArr = prevStudents;
                if (remove) {
                    prevArr = prevStudents.filter(obj => obj.studentId !== id);
                    handleChangeStudent(prevArr[0], true);
                }
                const mergedStudents = [...dataStudents, ...prevArr].reduce((acc, obj) => {
                    const existingIndex = acc.findIndex(item => item.id === obj.id);
                    if (existingIndex === -1) {
                        acc.push(obj);
                    }
                    return acc;
                }, []);

                if (mergedStudents.length === response.data.count) {
                    setHasMore(false);
                }
                return mergedStudents;
            });
        }

        if (id && dataStudents && dataStudents.length > 0) {
            const currStudent = dataStudents.find((s) => s.studentId === id);
            handleChangeStudent(currStudent);
        }
        return dataStudents;
        // eslint-disable-next-line
    }, [access_token, handleChangeStudent, page]);

    const generateLink = async (studentId, plan) => {
        await loadStudents(studentId);

        if (Object.keys(plan).length > 1) {
            const baseUrl = process.env.REACT_APP_NEW_STUDENT_BASE_URL;
            const planLink = `${baseUrl}/novo-aluno?pid=${plan.plans.id}&cn=${consultant.name}&cid=${consultant.id}&sid=${studentId}&paid=${plan.id}`;
            const url = new URL(planLink).href;
            navigator.clipboard.writeText(url);
            setLink(url);
            setOpenDialog(true);
        }
    };

    const sendViaWhatsapp = () => {
        const student = students[0];
        const phoneNumber = `55${student.phone.replace(/\D/g, '')}`;
        const message = `Olá, ${student.name}!
        Estou enviando o link para o pagamento do seu protocolo.
        Agradeço se puder realizar o pagamento o mais breve possível.
        Se tiver qualquer dúvida ou precisar de assistência, estou à disposição para ajudar.
        Obrigado! Link de pagamento: ${link}`;
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    const fetchMoreData = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const filteredItems = students.filter(student => student.name.toLowerCase().includes(search.toLowerCase()));

    useEffect(() => {
        const getStudents = async () => {
            const dataStudents = await loadStudents();

            if (dataStudents && dataStudents.length > 0) {
                handleChangeStudent(dataStudents[0]);
            }

            setShow(true);
        };

        getStudents(page);
    }, [page, handleChangeStudent, loadStudents]);

    useEffect(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    useEffect(() => {
        const getLibrary = async () => {
            const foods = await GetLibrary(access_token);
            const library = foods.data.map(f => ({ ...f, label: f.name }))
            sessionStorage.setItem('foodsLibrary', JSON.stringify(library));
        };
        getLibrary();
    }, [access_token]);

    return (
        <div className="students">
            {show && !isMobile && (<OnboardingTour page="students" run={run} setRun={setRun} steps={steps} />)}
            <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 3 }}>
                    <DialogTitle>Link de pagamento do <b>{currentStudent?.name}</b></DialogTitle>
                    <Typography textAlign="center">Envie esse link para seu aluno efetuar o pagamento.</Typography>
                    <Box my={2}>
                        <PaymentLink link={link} />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', pr: 2, gap: 1 }}>
                        <Typography textAlign="center">Enviar via whatsapp: </Typography>
                        <IconButton
                            color="primary"
                            aria-label="WhatsApp"
                            style={{
                                backgroundColor: '#25D366',
                                color: 'white',
                                boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.2)',
                                mt: 2
                            }}
                            onClick={sendViaWhatsapp}
                        >
                            <WhatsAppIcon style={{ fontSize: 40 }} />
                        </IconButton>
                    </Box>
                </Box>
            </Dialog>

            <PageWrapper menuItem="/manage-students">
                {show && (
                    <Box className="content">
                        <Header
                            title="Gerenciar alunos"
                            subtitle="Veja os alunos abaixo e faça as configurações de treinos e dietas"
                        />

                        <Box className="content-students">
                            <Box className="students-title" sx={{ my: 3 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                    <div className="search-container">
                                        <Button
                                            className="new-student-btn"
                                            aria-label="Novo aluno"
                                            onClick={() => setShowAddStudentModal(true)}
                                            startIcon={<AddCircleOutlineIcon />}
                                            sx={{
                                                backgroundColor: '#553872',
                                                color: 'white',
                                                boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.2)',
                                                borderRadius: 2,
                                                width: 250,
                                                height: 40,
                                                paddingX: 2,
                                                ":hover": { backgroundColor: '#553872' }
                                            }}
                                        >
                                            <Typography sx={{
                                                color: 'white',
                                                fontWeight: '700',
                                                fontSize: 14,
                                            }}>Novo aluno</Typography>
                                        </Button>
                                    </div>
                                    <Paper
                                        component="form"
                                        sx={{ mx: { xs: 3, sm: 0 }, display: 'flex', alignItems: 'center', width: { sx: '100%', sm: '35%', md: '25%', lg: '25%', xl: '25%' } }}
                                    >
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Pesquisar aluno"
                                            type="search"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            inputProps={{ 'aria-label': 'pesquisar aluno' }}
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                    </Paper>
                                </Box>
                            </Box>
                            <Grid container spacing={4}>
                                <Grid container xs={12} md={4} spacing={1}>
                                    <PendingIcon color="error" />
                                    <Typography>Sem Protocolos</Typography>
                                </Grid>
                                <Grid container xs={12} md={4} spacing={1}>
                                    <MoneyOffIcon color="error" />
                                    <Typography>Não pago</Typography>
                                </Grid>
                                <Grid container xs={12} md={4} spacing={1}>
                                    <NewReleasesIcon color="warning" />
                                    <Typography>Pendente montagem</Typography>
                                </Grid>
                                <Grid container xs={12} md={4} spacing={1}>
                                    <CheckCircleIcon color="success" />
                                    <Typography>Finalizado</Typography>
                                </Grid>
                            </Grid>

                            {students.length === 0 && <EmptyState
                                showImage={false}
                                title="Você não possui nenhum aluno."
                                subtitles={['Clique em Novo Aluno para criar o seu primeiro aluno.']}
                            />}
                            {students.length > 0 && <Box sx={{
                                display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'grid', xl: 'grid' },
                                flexDirection: { xs: 'column', sm: 'column', md: 'column' },
                                gap: 1,
                                gridTemplateColumns: '20% 80%'
                            }}>
                                {currentStudent &&
                                    <>
                                        <StudentItem
                                            students={filteredItems}
                                            currentStudent={currentStudent}
                                            handleChangeStudent={handleChangeStudent}
                                            fetchMoreData={fetchMoreData}
                                            loadStudents={loadStudents}
                                            hasMore={hasMore}
                                        />

                                        {isMobile && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 3,
                                                    width: '100%'
                                                }}>
                                                <PersonalInfo student={currentStudent} show={show} />
                                                <PhotosEvolution student={currentStudent} />
                                                <Protocols
                                                    student={currentStudent}
                                                    handleUpdate={loadStudents}
                                                />
                                                <Questionnaires
                                                    student={currentStudent}
                                                    handleUpdate={loadStudents}
                                                />
                                                {currentStudent && <ChatMessage student={currentStudent} />}
                                            </Box>
                                        )}
                                    </>
                                }

                                {currentStudent &&
                                    <>
                                        {!isMobile && (
                                            <Box
                                                className="itens-container"
                                                sx={{
                                                    height: '100%',
                                                    display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }
                                                }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    height: 'inherit',
                                                    gap: 2
                                                }}>
                                                    <Box sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: '56% 42%',
                                                        gap: 3,
                                                        margin: 1
                                                    }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, }}>
                                                            <PersonalInfo student={currentStudent} show={show} />
                                                            <Protocols
                                                                student={currentStudent}
                                                                handleUpdate={loadStudents}
                                                            />
                                                            <PhotosEvolution student={currentStudent} />
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, }}>
                                                            <Questionnaires
                                                                student={currentStudent}
                                                                handleUpdate={loadStudents}
                                                            />
                                                            {currentStudent && <ChatMessage student={currentStudent} />}
                                                        </Box>
                                                    </Box>

                                                </Box>
                                            </Box>
                                        )}
                                    </>
                                }
                            </Box>
                            }
                        </Box>
                    </Box>
                )}
                {!show && (
                    <CommonLoading color="#553872" size="large" />
                )}
                <ModalAddStudent
                    onSubmit={generateLink}
                    handleModal={setShowAddStudentModal}
                    open={showAddStudentModal}
                />
            </PageWrapper>
        </div>
    )
}

export default Students;