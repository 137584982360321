import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Sleep } from "../../../../../../Helpers/Timer";

import TextFieldTrainMe from '../../../../../../Components/TextField/TextField';
import Title from '../../../../../../Components/Title/Title';
import PrimaryButton from '../../../../../../Components/PrimaryButton/PrimaryButton';
import Select from '../../../../../../Components/Select/Select';

import { CreateExerciseReposition, UpdatedExerciseReposition } from '../../../../../../Services/Exercises/index';

import './ModalSpareExercise.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo nome do exercício é obrigatório!"),
    repetitions: yup.string().required("O campo quantidade de repetições é obrigatório!"),
    series: yup.number().required("O campo quantidade de séries é obrigatório!"),
    rest: yup.number().required("O campo de descanso é obrigatório!"),
    cadency: yup.string().required("O campo cadência é obrigatório!"),
    executionMethod: yup.string().required("O campo forma de execução é obrigatório!"),
    observations: yup.string(),
});

const ModalSpareExercise = ({
    handleModal,
    exercises,
    setExercises,
    reposition,
    selectedId,
    callbackExercises
}) => {
    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);
    const access_token = localStorage.getItem('access_token');

    const onSubmitHandler = async (data) => {
        setLoading(true);
        const payload = {
            name: data.name,
            executionMethod: data.executionMethod,
            repetitions: String(data.repetitions),
            series: String(data.series),
            rest: String(data.rest),
            observations: data.observations || '',
            cadency: data.cadency,
        };
        let repositionExercise;
        if (reposition === undefined) {
            repositionExercise = await CreateExerciseReposition(access_token, payload, selectedId);
        } else {
            repositionExercise = await UpdatedExerciseReposition(access_token, payload, selectedId);
        }
        await Sleep(1000);

        const newExercises = [...exercises];
        let indexExercise = newExercises.findIndex((ex) => ex.id === selectedId);
        if (indexExercise === -1) {
            await callbackExercises();
        } else {
            newExercises[indexExercise].reposition = repositionExercise;
            setExercises(newExercises);
        }

        handleModal();
        setLoading(false);
    };

    useEffect(() => {
        if (reposition) {
            setValue('name', reposition.name);
            setValue('executionMethod', reposition.executionMethod);
            setValue('repetitions', reposition.repetitions);
            setValue('series', reposition.series);
            setValue('rest', reposition.rest);
            setValue('cadency', reposition.cadency);
            setValue('observations', reposition.observations);
        }
    }, [setValue, reposition]);

    return (
        <div className="modal-add-container" id="modal-add-spare">
            <form className="modal-add" id="add-spare-exercise">
                <div className="titles">
                    <div>
                        <div className="div-image">
                            <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-spare-exercise.svg"} alt={"icon-modal-spare-exercise"} />
                        </div>
                        <div className="div-title">
                            <Title title={`${reposition ? 'Editar' : 'Adicionar novo'} exercício`} />
                            <p>Insira as informações abaixo</p>
                        </div>
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleModal} />
                </div>

                <div className='new-quiz'>
                    <div className='input'>
                        <TextFieldTrainMe
                            control={control}
                            name={'name'}
                            defaultValue={reposition ? reposition.name : ''}
                            label={'Nome do exercício'}
                            fullWidth
                            errors={errors.name}
                        />
                    </div>
                </div>

                <div className='new-quiz'>
                    <div className='input'>
                        <Select
                            name="executionMethod"
                            options={['Padrão', 'Rest and Pause', 'Drop Set', 'Bi-set', 'Tri-set', 'Super Série', 'Circuito', 'FST-7', 'Cluster Set']}
                            defaultValue={reposition.executionMethod ?? 'Padrão'}
                            setValue={(data) => setValue('executionMethod', data)}
                            label="Forma de execução"
                            control={control}
                            errors={errors.executionMethod}
                        />
                    </div>
                </div>

                <div className='new-quiz'>
                    <div className='input'>
                        <TextFieldTrainMe
                            control={control}
                            name={'repetitions'}
                            defaultValue={reposition ? reposition.repetitions : ''}
                            label={'Adicione a quantidade de repetições'}
                            fullWidth
                            errors={errors.repetitions}
                        />
                    </div>
                </div>

                <div className='new-quiz'>
                    <div className='input'>
                        <TextFieldTrainMe
                            control={control}
                            name={'series'}
                            defaultValue={reposition ? reposition.series : ''}
                            label={'Adicione a quantidade de séries'}
                            fullWidth
                            type="number"
                            errors={errors.series}
                        />
                    </div>
                </div>

                <div className='new-quiz'>
                    <div className='input'>
                        <TextFieldTrainMe
                            control={control}
                            name={'rest'}
                            defaultValue={reposition ? reposition.rest : '60'}
                            label={'Adicione o tempo de descanso (em segundos)'}
                            type="number"
                            fullWidth
                            errors={errors.rest}
                        />
                    </div>
                </div>

                <div className='new-quiz'>
                    <div className='input'>
                        <TextFieldTrainMe
                            control={control}
                            name={'cadency'}
                            label={'Adicione a cadência'}
                            fullWidth
                            errors={errors.cadency}
                        />
                    </div>
                </div>

                <div className='new-quiz'>
                    <div className='input'>
                        <TextFieldTrainMe
                            control={control}
                            name={'observations'}
                            label={'Adicione as observações'}
                            fullWidth
                            errors={errors.observations}
                        />
                    </div>
                </div>

                <div className="button">
                    <PrimaryButton
                        text={`${reposition ? 'Editar' : 'Adicionar'} exercício`}
                        onClick={handleSubmit(onSubmitHandler)}
                        loading={loading}
                    />
                </div>
            </form>
        </div>
    );
};

export default ModalSpareExercise;