import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    Snackbar,
    Button,
    Chip
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModalAddProtocol from '../ModalAddProtocol/ModalAddProtocol';
import DeleteIcon from '@mui/icons-material/Delete';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PaymentLink from '../../../../Components/PaymentLink/PaymentLink';

import ModalDelete from '../../../../Components/ModalDelete/ModalDelete';

import { DeleteProtocol, PutProtocolPaid } from '../../../../Services/Plans/index';

const Protocols = ({ student, handleUpdate }) => {
    const COLORS = ['#C7427B', '#1E306E', '#3ac49c', '#db3d60', '#c2c627', '#C7427B', '#1E306E', '#3ac49c', '#db3d60', '#c2c627'];
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const baseUrl = process.env.REACT_APP_NEW_STUDENT_BASE_URL;

    const navigate = useNavigate();

    const [modal, setModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [link, setLink] = useState();
    const [error, setError] = useState();

    const navigateProtocol = (route, studentParam, plan, e) => {
        e.stopPropagation();
        navigate(route, { state: { client: { ...studentParam, plan: plan } } });
    };

    const handleNewProtocol = (page, studentId, protocol) => {
        handleUpdate(studentId);
        generateLink(undefined, protocol);
    }

    const setProtocolPaid = async (event, protocol) => {
        if (event) event.stopPropagation();
        await PutProtocolPaid(protocol.id);
        handleUpdate(student.studentId);
    }

    const generateLink = (event, plan) => {
        if (event) event.stopPropagation();

        const planLink = `${baseUrl}/novo-aluno?pid=${plan.plans.id}&cn=${consultant.name}&cid=${consultant.id}&sid=${student.studentId}&paid=${plan.id}`;
        const url = new URL(planLink).href;
        navigator.clipboard.writeText(url);
        setLink(url);
        setOpenDialog(true);
    };

    const sendViaWhatsapp = () => {
        const phoneNumber = `55${student.phone.replace(/\D/g, '')}`;
        const message = `Olá, ${student.name}!
        Estou enviando o link para o pagamento do seu protocolo.
        Agradeço se puder realizar o pagamento o mais breve possível.
        Se tiver qualquer dúvida ou precisar de assistência, estou à disposição para ajudar.
        Obrigado! Link de pagamento: ${link}`;
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    const handleModalDelete = (event, plan) => {
        if (event) event.stopPropagation();
        setModalDelete(!modalDelete);
        setSelectedPlan(plan);
    };

    const handleDeleteProtocol = async (id, e) => {
        try {
            await DeleteProtocol(id);
            handleUpdate(student.studentId, false);
        } catch (ex) {
            setError(ex.response.data.error);
            setTimeout(() => setError(), 15000);
        } finally {
            handleModalDelete(e);
        }
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                open={error}
                onClose={() => setError()}
                message={error}
            />
            <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 3 }}>
                    <DialogTitle>Link de pagamento do <b>{student.name}</b></DialogTitle>
                    <Typography textAlign="center">Envie esse link para seu aluno efetuar o pagamento.</Typography>
                    <Box my={2}>
                        <PaymentLink link={link} />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', pr: 2, gap: 1 }}>
                        <Typography textAlign="center">Enviar via whatsapp: </Typography>
                        <IconButton
                            color="primary"
                            aria-label="WhatsApp"
                            style={{
                                backgroundColor: '#25D366',
                                color: 'white',
                                boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.2)',
                                mt: 2
                            }}
                            onClick={sendViaWhatsapp}
                        >
                            <WhatsAppIcon style={{ fontSize: 40 }} />
                        </IconButton>
                    </Box>
                </Box>
            </Dialog>

            <Box className="protocols" sx={{
                width: { xs: '100%', sm: '100%' },
                height: { xs: '90%', md: '420px' },
                maxHeight: { xs: '380px', md: '420px' },
                bgcolor: 'background.paper',
                overflowY: 'scroll',
                scrollbarColor: 'grey white',
                padding: 0,
                px: 2,
                borderRadius: 1,
                boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.2)'
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', px: 2, pl: 4, py: 1, pb: 0, mb: 0 }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#553872' }} onClick={() => setModal(true)}>Protocolos ({student?.plans?.length ?? 0})</Typography>
                    <IconButton onClick={() => setModal(true)}>
                        <AddCircleIcon style={{ color: '#1e306e', fontSize: 28 }} />
                    </IconButton>
                </Box>
                <List>
                    {student && student.plans.map((plan, key) => (
                        <>
                            <ListItemButton onClick={(e) => navigateProtocol('/manage-plan', student, plan, e)}>
                                <ListItem
                                    alignItems="center"
                                    secondaryAction={
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            {(plan.pendingPayment && plan.plans) && (<>
                                                <Tooltip title="Apenas planos que não foram pagos podem ser removidos." arrow>
                                                    <IconButton edge="end" aria-label="delete" onClick={(e) => handleModalDelete(e, plan)}>
                                                        <DeleteIcon sx={{ color: '#c7427b' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </>)}
                                            {(!plan.pendingPayment && plan.manualPayment && plan.plans) && (<>
                                                <Tooltip title="Apenas planos que não foram pagos podem ser removidos." arrow>
                                                    <IconButton edge="end" aria-label="delete" onClick={(e) => handleModalDelete(e, plan)}>
                                                        <DeleteIcon sx={{ color: '#c7427b' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </>)}
                                        </Box>
                                    }>
                                    <ListItemText
                                        primary={<Box>
                                            <Chip
                                                label={plan.plans?.name ? `${plan.plans.name} - ${new Date(plan.dueDate) - new Date() > 0 ? `${Math.ceil((new Date(plan.dueDate) - new Date()) / (1000 * 60 * 60 * 24))} dias restantes` : 'Expirado'}` : 'Protocolo Demonstração'}
                                                color="primary"
                                                sx={{ fontWeight: '700', bgcolor: `${COLORS[plan.plans?.id % 10 ?? 0]}1a`, color: COLORS[plan.plans?.id % 10 ?? 0] }} />
                                            <IconButton edge="end" aria-label="navegar">
                                                <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Box>
                                        }
                                        secondary={
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <AttachMoneyIcon />
                                                    <Typography variant="body1" fontWeight={700} fontSize={14}>Valor:</Typography>
                                                    <Typography variant="body1" fontSize={12}>{`${plan.plans ? formatCurrency(Number(plan?.profit)) : 'R$ 0,00'}`}</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <EventNoteIcon />
                                                    <Typography variant="body1" fontWeight={700} fontSize={14}>Vencimento:</Typography>
                                                    <Typography variant="body1" fontSize={12}>{new Date(plan.dueDate).toLocaleDateString()}</Typography>
                                                </Box>
                                                <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <Typography variant="body1" fontWeight={700}>Estado:</Typography>
                                                    <Typography fontSize={12}>{plan.pendingPayment && plan.plans ? 'Aguardando Pagamento' : plan.completed ? 'Concluido' : 'Pendente montagem do protocolo'}</Typography>
                                                    {plan.pendingPayment && plan.plans &&
                                                        <Tooltip title="Marcar protocolo como pago" arrow>
                                                            <Button variant="outlined" edge="end" aria-label="link" onClick={(e) => setProtocolPaid(e, plan)}>
                                                                Definir como pago
                                                            </Button>
                                                        </Tooltip>
                                                    }
                                                </Typography>
                                                {plan.pendingPayment && plan?.plans && <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <Typography variant="body1" fontWeight={700}>Link:</Typography>
                                                    <PaymentLink link={new URL(`${baseUrl}/novo-aluno?pid=${plan?.plans?.id}&cn=${consultant.name}&cid=${consultant.id}&sid=${student.studentId}&paid=${plan?.id}`).href} />
                                                </Box>}
                                            </Box>
                                        }
                                    />
                                </ListItem>
                            </ListItemButton>
                            {key !== student.plans.length - 1 && <Divider component="div" />}
                        </>
                    ))}
                </List>
            </Box>

            <ModalAddProtocol
                open={modal}
                handleModal={setModal}
                student={student}
                handleUpdate={handleNewProtocol}
            />

            {
                modalDelete &&
                <ModalDelete
                    handleModal={handleModalDelete}
                    title="Excluir protocolo"
                    subtitle="Deseja remover o protocolo ?"
                    text={`Protocolo ${selectedPlan.plans?.name}`}
                    id={selectedPlan.id}
                    handleDelete={handleDeleteProtocol}
                />
            }
        </>
    );
};

export default Protocols;