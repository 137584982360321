import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { matchSorter } from 'match-sorter';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    Box,
    Modal,
    Typography
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Title from '../../../../Components/Title/Title';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';
import Select from '../../../../Components/Select/Select';

import { PostStudentManual } from "../../../../Services/Students/index";
import { GetPlans } from '../../../../Services/Plans/index';

import './ModalAddProtocol.scss';

const schema = yup.object().shape({
    protocol: yup.object().required("Protocolo é obrigatório!").nullable(),
});
const ModalAddProtocol = ({ open, handleModal, student, handleUpdate }) => {
    const access_token = localStorage.getItem('access_token');

    const [plans, setPlans] = useState([]);
    const [error, setError] = useState(false);

    const { control, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            protocol: '',
        },
    });

    const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['label'] });

    const handleCloseModal = () => {
        reset();
        handleModal(false);
        setError(false);
    };

    const onSubmitHandler = async (data) => {
        const consultant = JSON.parse(sessionStorage.getItem('consultant'));

        const payload = {
            name: student.name,
            email: student.email,
            phone: student.phone,
            gender: student.gender,
            document: student.document,
            birthDate: student.birthDate,
            id: consultant.id,
            planId: data.protocol.id,
            newUser: false
        };
        try {
            const response = await PostStudentManual(payload);
            handleUpdate(1, student.studentId, response);
            handleCloseModal(true);
            reset();
        } catch (ex) {
            setError(true);
        }
    };

    useEffect(() => {
        const getProtocols = async () => {
            let plansList = await GetPlans(access_token);
            const options = plansList.data.rows.map((r => ({ label: `${r.name} - ${r.weeks} Semanas`, id: r.id })));
            setPlans(options);
        };
        getProtocols();
    }, [access_token]);

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="Adicionar protocolo"
            aria-describedby="Adicionar protocolo"
        >
            <div className='form-add-protocol'>
                <div className='titles'>
                    <div>
                        <div className='div-image'>
                            <PersonAddIcon />
                        </div>
                        <div className='div-title'>
                            <Title title="Adicionar novo protocolo" />
                            <p>Selecione o protocolo</p>
                        </div>
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleCloseModal} />
                </div>
                {error && <Box>
                    <Typography>Você já atingiu o limite de alunos com protocolos ativos no seu plano.</Typography>
                    <Link to="/upgrade" variant="body2">
                        Clique aqui para alterar seu plano
                    </Link>
                </Box>}
                {!error && <form className='modal-add-student' id='modal-add-student'>
                    {plans && plans.length > 0 && (
                        <>
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 5, mx: 3, mt: 1 }}>
                                <Box sx={{ width: "100%" }}>
                                    <Select
                                        name="protocol"
                                        options={plans}
                                        defaultValue=""
                                        setValue={(data) => setValue('protocol', data)}
                                        filterOptions={filterOptions}
                                        label="Protocolos"
                                        control={control}
                                        errors={errors.protocol}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <PrimaryButton text="Adicionar protocolo" onClick={handleSubmit(onSubmitHandler)} />
                            </Box>
                        </>
                    )}
                    {(!plans || plans.length === 0) && (
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 5, mx: 3, mt: 1, py: 3 }}>
                            <Typography>Você ainda não criou protocolos, &nbsp;
                                <Link to="/plans" variant="body2">clique aqui para criar.</Link>
                            </Typography>
                        </Box>
                    )}
                </form>}
            </div>
        </Modal>
    );
};

export default ModalAddProtocol;