import React, { useState, useEffect, useMemo } from "react";
import {
    Box,
    Stack,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Autocomplete,
    TextField
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import IntlCurrencyInput from "react-intl-currency-input"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PrimaryButton from "../../../../Components/PrimaryButton/PrimaryButton";
import TextFieldTrainMe from "../../../../Components/TextField/TextField";
import Feedback from "../../../../Components/Feedback/Feedback";
import TextArea from "../../../../Components/TextArea/TextArea";

import { CreatePlans, UpdatePlans } from '../../../../Services/Plans/index';
import { GetQuestionnares } from "../../../../Services/Questionnares/index";

import "./PlanForm.scss";

const schema = yup.object().shape({
    name: yup.string().required("O campo nome é obrigatório!"),
    price: yup.string().required("O campo preço é obrigatório!"),
    description: yup.string().required("O campo descrição é obrigatório!"),
});

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

const PlanForm = ({ plan }) => {
    const { id } = JSON.parse(sessionStorage.getItem('consultant'));
    const quizId = plan?.quiz?.id;
    const access_token = localStorage.getItem('access_token');
    const navigate = useNavigate();

    const [questionnaires, setQuestionnaires] = useState([]);
    const [feedback, setFeedback] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [selectedOption, setSelectedOption] = useState(quizId);
    const [showError, setShowError] = useState(false);
    const [showPriceError, setShowPriceError] = useState(false);

    const periodData = useMemo(() => [
        { label: "Mensal (4 semanas)", id: 4 },
        { label: "Bimestral (9 semanas)", id: 9 },
        { label: "Trimestral (13 semanas)", id: 13 },
        { label: "Quadrimestral (17 semanas)", id: 17 },
        { label: "Pentamestral (21 semanas)", id: 21 },
        { label: "Semestral (26 semanas)", id: 26 },
        { label: "Anual (51 semanas)", id: 51 }
    ], []);
    const [period, setPeriod] = useState(periodData[0]);

    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            id: plan?.id,
            name: plan?.name,
            price: plan?.price,
            weeks: plan?.weeks,
            description: plan?.description,
            available: true,
            quiz: plan?.quiz?.id
        }
    });

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        setValue('quiz', event.target.value);
    }

    const updatePlan = async (data) => {
        const dataUpdate = {
            id: data.id,
            name: data.name,
            price: Number(data.price),
            weeks: period.id,
            description: data.description,
            available: true,
            quiz: selectedOption || null
        };
        await UpdatePlans(access_token, dataUpdate);
    };

    const insertPlan = async (data) => {
        const dataInsert = {
            name: data.name,
            price: Number(data.price),
            weeks: period.id,
            description: data.description,
            available: true,
            quiz: selectedOption || null
        };
        await CreatePlans(access_token, dataInsert);
    }

    const savePlan = async (data, e) => {
        try {
            if (!data.quiz) {
                setShowError(true);
                return;
            }
            data.available = true;
            data.price = data.price.replace("R$ ", "").replace(",", ".");
            if (data.price < 5) {
                setShowPriceError(true);
                return;
            }

            if (data.id) {
                await updatePlan(data);
            } else {
                await insertPlan(data);
            }

            setFeedback(true);
            setMessage('Nova consultoria salva com sucesso');
            setSeverity('success');
            navigate(-1);
        } catch {
            setFeedback(true);
            setMessage('Não foi possivel salvar a consultoria.');
            setSeverity('error');
        }
    };

    const handlePriceChange = (event, value, maskedValue) => {
        setValue('price', value);
    };

    useEffect(() => {
        const fetchData = async () => {
            // Busca todos os questionarios
            let questionnaireList = await GetQuestionnares(access_token, id);
            questionnaireList = questionnaireList?.data?.rows;
            setQuestionnaires(questionnaireList || []);
        };
        fetchData();
    }, [access_token, id]);

    useEffect(() => {
        setValue('id', plan?.id);
        setValue('name', plan?.name);
        setValue('weeks', plan?.weeks);
        setValue('description', plan?.description);
        setValue('available', true);
        setValue('quiz', plan?.quiz?.id);
        setSelectedOption(plan?.quiz?.id);
        if (plan) {
            setPeriod(periodData.find(p => p.id === plan?.weeks));
        }
    }, [plan, setValue, periodData]);

    useEffect(() => {
        if (feedback) {
            setTimeout(() => {
                setFeedback(false);
                setMessage('');
                setShowError(false);
            }, 4500);
        }
    }, [feedback]);

    useEffect(() => {
        if (errors && errors.quiz) {
            setTimeout(() => {
                setShowError(false);
            }, 2500);
        }
    }, [errors, errors.quiz]);

    return (
        <>
            <div className="plan-form">
                <div className="data">
                    {feedback && (
                        <Feedback severity={severity} text={message} />
                    )}
                    <div className="plan-header">
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Stack direction="column" spacing={1}>
                                <Typography variant="h3">Consultoria</Typography>
                                <Typography variant="subtitle2">Edite os dados da consultoria abaixo</Typography>
                            </Stack>
                        </Stack>
                    </div>
                    <form className="form" id="form">
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 3 }}>
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 3 }}>
                                <Box sx={{ width: { xs: '100%', sm: '65%' } }}>
                                    <TextFieldTrainMe
                                        control={control}
                                        name={'name'}
                                        label={'Nome (Ex: Plano de emagrecimento)'}
                                        fullWidth
                                        errors={errors.name}
                                    />
                                </Box>
                                <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
                                    <Autocomplete
                                        name="weeks"
                                        disableClearable
                                        options={periodData}
                                        renderInput={(params) => <TextField {...params} label="Periodo" />}
                                        value={period}
                                        onChange={(_, data) => {
                                            setPeriod(data);
                                            return data;
                                        }}
                                        defaultValue={{ label: "Mensal", id: 30 }}
                                    />
                                </Box>
                                <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
                                    <div className="price-container">
                                        <label
                                            data-shrink="true"
                                            htmlFor=":rp:"
                                            id=":rp:-label"
                                        > Valor
                                        </label>
                                        <div>
                                            <IntlCurrencyInput
                                                currency="BRL"
                                                config={currencyConfig}
                                                onChange={handlePriceChange}
                                                defaultValue={Number(plan?.price)}
                                            />
                                            <fieldset>
                                                <legend>
                                                    <span>Valor</span>
                                                </legend>
                                            </fieldset>
                                        </div>
                                    </div>
                                    {showPriceError && <p className='error'>O valor minimo é de R$ 5,00</p>}
                                </Box>
                                <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
                                    <Stack>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <Select
                                                control={control}
                                                name="quiz"
                                                label="quiz"
                                                labelId="select-label"
                                                value={selectedOption}
                                                onChange={handleSelectChange}
                                                autoWidth
                                            >
                                                {questionnaires.map((option, index) => (
                                                    <MenuItem key={index} value={option.id}>{option.name}</MenuItem>
                                                ))}
                                            </Select>
                                            <InputLabel id="select-label">Questionnário</InputLabel>
                                        </FormControl>
                                    </Stack>
                                    {showError && <p className='error'>O campo de questionário é obrigatório!</p>}
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                gap: 3,
                                alignItems: { xs: 'start', sm: 'center' }
                            }}>
                                <Box sx={{ width: '100%' }}>
                                    <TextArea
                                        control={control}
                                        name={'description'}
                                        label="Descrição"
                                        fullWidth
                                        errors={errors.description}
                                        multiline
                                        placeholder="(Ex: Montarei seu plano de alimentação e treinos baseado em suas necessidades com foco no emagrecimento)"
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'end' }, width: '100%' }}>
                                    <PrimaryButton text="Salvar" onClick={handleSubmit(savePlan)} />
                                </Box>
                            </Box>
                        </Box>
                    </form>
                </div>
            </div>
        </>
    );
};

export default PlanForm;
