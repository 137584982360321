import { GetAsync, PostAsync } from '../api';
import qs from 'qs';

/**
 * Método responsável por autenticar na api
*/
const AuthLogin = (login) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = qs.stringify({ 'username': login.email, 'password': login.password });
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Application-ID': process.env.REACT_APP_APPLICATION_ID,
                    'X-Application-Key': process.env.REACT_APP_APPLICATION_KEY,
                }
            }
            const response = await PostAsync('/auth/login', config, data);

            if (response.status >= 400) {
                reject(response.data);
            } else {
                resolve(response.data);
            }
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por atualizar um token na api
*/
const AuthRefresh = (refreshToken) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = qs.stringify({ 'refresh_token': refreshToken });
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Application-ID': process.env.REACT_APP_APPLICATION_ID,
                    'X-Application-Key': process.env.REACT_APP_APPLICATION_KEY,
                }
            }
            const response = await PostAsync('/auth/refresh-token', config, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por validar um token
*/
const AuthToken = (token) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/auth/login`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por retornar um consultor logado
*/
const GetConsultant = (token) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/auth/consultant/me`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável pelo reset de senha
*/
const ResetConsultantPassword = (email) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await PostAsync('/consultant/reset/password', {}, { email })
            resolve(response);
        } catch (err) {
            reject(err);
        }
    });
}

export { AuthLogin, AuthToken, GetConsultant, AuthRefresh, ResetConsultantPassword };
