import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';
import TextFieldTrainMe from '../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';

import { CreateQuestionnaire} from "../../../../Services/Questionnares/index";

import './ModalAddQuestionnaire.scss';

const schema = yup.object().shape({
    questionnaireName: yup.string().required("O campo nome do questionário é obrigatório!")
});

const ModalAddQuestionnaire = ({ handleModal, questionnaires, setQuestionnaires, reloadQuestionnaires }) => {

    const access_token = localStorage.getItem('access_token');

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = (data) => {
        createQuestionnaire(data.questionnaireName)
        handleCloseModal();
        reset();
    };

    const createQuestionnaire = async (name) => {
        // Cria um questionario
        await CreateQuestionnaire(access_token, name);
        const payload = {
            questionnaireName: name,
            countQuestions: 0
        }
        const newList = [...questionnaires, payload];
        setQuestionnaires(newList);
        reloadQuestionnaires();
    };

    const handleCloseModal = () => {
        handleModal();
    };

    return (
        <div className="modal-add-container">
            <form className='modal-add' id='form'>
                <div className='titles'>
                    <div>
                        <div className='div-image'>
                            <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-quiz.svg"} alt={"icon-modal-quiz"} />
                        </div>
                        <div className='div-title'>
                            <Title title={'Criar novo questionário'} />
                            <p>Insira o nome do questionário</p>
                        </div>
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleCloseModal} />
                </div>

                <div className='new-quiz'>
                    <Subtitle subtitle="Nome do questionário:" />

                    <div className='input'>
                        <TextFieldTrainMe
                            control={control}
                            name="questionnaireName"
                            label="Questionário de boas vindas"
                            fullWidth
                            errors={errors.questionnaireName}
                        />
                    </div>
                </div>

                <div className='button'>
                    <PrimaryButton text="Criar questionário" onClick={handleSubmit(onSubmitHandler)}/>
                </div>
            </form>
        </div>
    );
};

export default ModalAddQuestionnaire;