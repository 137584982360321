import React, { useState } from "react";
import {
    AlertTitle,
    Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Alert from '@mui/material/Alert';
import * as yup from "yup";

import TextField from "../../../../Components/TextField/TextField";
import PrimaryButton from "../../../../Components/PrimaryButton/PrimaryButton";

import { ChangePassword } from '../../../../Services/Consultants';

import "./Password.scss";

const schema = yup.object().shape({
    oldPassword: yup.string(),
    newPassword: yup.string()
        .required("O campo de senha é obrigatório!")
        .min(6, 'Sua senha deve conter pelo menos 6 digitos.'),
    confirmNewPassword: yup.string()
        .required("O campo de confirmação de senha é obrigatório!")
        .oneOf([yup.ref('newPassword'), null], 'Senhas devem ser iguais'),
});

const Password = ({ showOldPassword }) => {
    const [success, setSucess] = useState(false);
    const access_token = localStorage.getItem('access_token');

    const { control, handleSubmit, formState: { errors }, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {}
    });

    const saveNewPassword = async (body) => {
        try {
            if (showOldPassword && !body.oldPassword) {
                setSucess(false);
                setError('oldPassword', { type: 'custom', message: 'O campo de senha atual obrigatório!' });
                return;
            }

            const consultant = JSON.parse(sessionStorage.getItem('consultant'));
            const payload = {
                oldPassword: showOldPassword ? body.oldPassword : "",
                newPassword: body.newPassword
            };
            await ChangePassword(access_token, consultant.id, payload);
            setSucess(true);
        } catch (ex) {
            setSucess(false);
            setError('oldPassword', { type: 'custom', message: ex.response.data.error });
        } finally {
            setTimeout(() => {
                setSucess(false);
            }, 2500);
        }
    };

    return (
        <div className="password">
            {success && (
                <Alert severity="success">
                    <AlertTitle>Senha alterada com sucesso!</AlertTitle>
                </Alert>
            )}
            <div className="header">
                <div className="container-left">
                    <Typography variant="h3">Senhas</Typography>
                    <Typography variant="subtitle2">Edite sua senha</Typography>
                </div>
            </div>

            <div className="password-form">
                <form id="form" onSubmit={handleSubmit(saveNewPassword)}>
                    {showOldPassword && <div className="input-container">
                        <TextField
                            control={control}
                            name={'oldPassword'}
                            label={'Senha atual'}
                            fullWidth
                            errors={errors.oldPassword}
                            type="password"
                        />
                    </div>}
                    <div className="input-container">
                        <TextField
                            control={control}
                            name={'newPassword'}
                            label={'Nova senha'}
                            fullWidth
                            errors={errors.newPassword}
                            type="password"
                        />
                    </div>
                    <div className="input-container">
                        <div>
                            <TextField
                                control={control}
                                name={'confirmNewPassword'}
                                label={'Confirmar nova senha'}
                                fullWidth
                                errors={errors.confirmNewPassword}
                                type="password"
                            />
                        </div>
                    </div>
                </form>

                <div className="button-container">
                    <PrimaryButton text="Alterar senha" />
                </div>
            </div>
        </div>
    );
};

export default Password;
