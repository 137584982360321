import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
    Box,
    IconButton,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Button,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import PlanCreationWizard from "./components/PlanCreationWizard/PlanCreationWizard";

import "./PlansWizard.scss";

const PlansWizard = () => {
    const steps = [
        { label: 'Nome do plano', description: 'O nome do seu plano que será vendido para o aluno. Ex: Emagrece Fácil.' },
        { label: 'Descrição', description: 'Uma breve descrição do que você pode oferecer dentro desse plano, como acompanhamento, treinos e dietas individualizadas e etc.' },
        { label: 'Duração', description: 'O periodo em semanas que você irá acompanhar esse aluno após a compra, montando treinos/dietas e tirando dúvidas.' },
        { label: 'Preço', description: 'O valor do seu plano, recomendamos entender seu público e indicamos valor com final 7. Ex: R$ 197,00' },
        { label: 'Anamnese', description: 'O questionário vinculado ao seu plano, será o questionário enviado para seu aluno após o pagamento ser efetuado e ficará disponivel para ele no aplicativo e para você visualzar as respostas também.' }
    ];

    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [start, setStart] = useState(false);
    const [finished, setFinished] = useState(false);

    const handleNext = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleFinish = () => {
        setFinished(true);
        handleNext();
    };

    return (
        <Box
            className="plans-wizard"
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box'
            }}
        >
            <Box
                className="content"
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingBottom: 2,
                        marginTop: 2,
                        boxShadow: 'rgb(236, 238, 241) 0px -1px 0px inset',
                        flexShrink: 0,
                    }}
                >
                    <img className="logo" src="/logo-logotipo.png" alt="logo" height={50} />
                    <Stepper activeStep={currentStep} alternativeLabel sx={{ width: '100%', maxWidth: 600 }}>
                        {steps.map((step) => (
                            <Step key={step.label}>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <IconButton onClick={() => navigate('/')}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Header - Somente para telas menores */}
                <Box
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        px: 1,
                        flexShrink: 0,
                    }}
                >
                    <img className="logo" src="/logo-logotipo.png" alt="logo" height={50} />
                    <IconButton onClick={() => navigate('/')}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        display: { xs: 'flex', sm: 'grid' },
                        flexDirection: { xs: 'column' },
                        gridTemplateColumns: { xs: '100%', sm: !finished ? '30% 70%' : '100%' },
                        flex: 1,
                        height: '100%',
                        minHeight: '100%',
                        py: 2,
                        transform: { xs: !start ? 'translateY(0)' : 'translateY(20px)', sm: 'none' },
                        transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
                    }}
                >
                    {start && <Box sx={{ display: { xs: 'block', sm: 'none' }, mt: 3, mb: 2 }}>
                        <Stepper activeStep={currentStep} alternativeLabel sx={{ width: '100%', maxWidth: 600 }}>
                            {steps.map((step) => (
                                <Step key={step.label}>
                                    <StepLabel>{step.label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>}

                    {/* Barra Lateral */}
                    {!finished && <Box sx={{ bgcolor: '#00186dd3', height: '100%', display: { xs: 'none', sm: 'block' } }}>
                        {!start && <Typography color="#FFF" variant="h6" px={5} pt={3}>Seja bem vindo, vamos te ajudar a configurar sua conta.</Typography>}
                        <Typography color="#FFF" p={5}>
                            {!start ?
                                'Você está no assistente de configuração de consultoria, aqui você irá criar o primeiro plano da sua consultoria e estará pronto para vender sem complicações.'
                                : steps[currentStep].description}
                        </Typography>
                    </Box>}
                    {!start && <Box sx={{ bgcolor: '#00186dd3', height: '30%', mb: 5, display: { xs: 'block', sm: 'none' } }}>
                        <Typography color="#FFF" variant="h6" px={5} pt={3}>Seja bem vindo, vamos te ajudar a configurar sua conta.</Typography>
                        <Typography color="#FFF" px={5} py={1}>Você está no assistente de configuração de consultoria, aqui você irá criar o primeiro plano da sua consultoria e estará pronto para vender sem complicações.</Typography>
                    </Box>}
                    {start && !finished && <Box sx={{ bgcolor: '#00186dd3', height: '25%', mb: 5, display: { xs: 'block', sm: 'none' } }}>
                        <Typography color="#FFF" p={5}>{steps[currentStep].description}</Typography>
                    </Box>}

                    {!start && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" sx={{
                            borderRadius: '50%',
                            width: 75,
                            height: 75,
                            minWidth: 0,
                            backgroundColor: '#553872',
                            color: 'white',
                            mt: { xs: 0, lg: 15 },
                            boxShadow: 3,
                            '&:hover': {
                                backgroundColor: '#553872',
                            },
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                border: '2px solid #553872',
                                animation: 'wave 1.5s infinite',
                                pointerEvents: 'none',
                            }
                        }}
                            onClick={() => setStart(true)}
                        > Iniciar
                        </Button>
                    </Box>}

                    {start && !finished && <PlanCreationWizard
                        activeStep={currentStep}
                        steps={steps}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        handleFinish={handleFinish}
                    />}

                    {finished && <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column', mt: 4 }}>
                        <Typography variant="h6" align="center">Parabéns, você configurou sua consultoria.</Typography>
                        <Typography variant="body2" align="center">Agora você pode gerar links de pagamento para vender esse plano através de suas redes sociais.</Typography>
                        <Typography variant="body2" align="center">Também é possivel adicionar alunos manualmente na plataforma através da página de alunos.</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                            <Button variant="contained" onClick={() => navigate('/')}>
                                Acessar Plataforma
                            </Button>
                        </Box>
                    </Box>}
                </Box>
            </Box>
        </Box >
    );
};

export default PlansWizard;
