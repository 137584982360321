import React, { useEffect, useState } from "react";
import { AlertTitle, Autocomplete } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import * as yup from "yup";

import PrimaryButton from "../../../../Components/PrimaryButton/PrimaryButton";
import { CpfMask } from '../../../../Helpers/Masks';
import TextFieldTrainMe from "../../../../Components/TextField/TextField";
import TextArea from '../../../../Components/TextArea/TextArea';
import CustomInput from "../../../SignUp/Components/CustomInput/CustomInput";

import { UpdateConsultant } from '../../../../Services/Consultants/index';

import "./MyData.scss";

const schema = yup.object().shape({
    name: yup.string().required("O campo nome é obrigatório!"),
    document: yup.string().required("O campo CPF é obrigatório!"),
    phone: yup.string().required("O campo registro é obrigatório!"),
    credentialType: yup.string(),
    credential: yup.string(),
    occupation: yup.string(),
    description: yup.string(),
});

const MyData = () => {
    const [success, setSucess] = useState(false);
    const access_token = localStorage.getItem('access_token');

    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const { control, handleSubmit, register, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: consultant
    });

    const [typeRegistry, setTypeRegistry] = useState('CREF');

    const saveData = async (values) => {
        try {
            const payload = {
                ...values,
                credentialType: typeRegistry,
                document: values.document.replaceAll('.', '').replaceAll('-', '')
            };
            await UpdateConsultant(access_token, payload);
            sessionStorage.setItem('consultant', JSON.stringify(payload));
            setSucess(true);
        } catch {
            setSucess(false);
        }

        setTimeout(() => {
            setSucess(false);
        }, 2500)
    };

    useEffect(() => {
        setValue("document", consultant?.document ? CpfMask(consultant?.document) : consultant?.document);
        setValue("phone", consultant?.phone);
        setTypeRegistry(consultant?.credentialType);
    }, [consultant?.document, consultant?.credentialType, consultant?.phone, setValue]);

    return (
        <div className="my-data">
            {success && (
                <Alert severity="success">
                    <AlertTitle>Dados salvos com sucesso!</AlertTitle>
                </Alert>
            )}
            <div className="header">
                <div className="container-left">
                    <h3>Meus Dados</h3>
                    <span>Edite seus dados abaixo</span>
                </div>
                <div className="container-right">
                    <PrimaryButton text="Salvar Alterações" />
                </div>
            </div>

            <div className="data">
                <form className="form" id="form" onSubmit={handleSubmit(saveData)}>
                    <div className="column">
                        <div>
                            <TextFieldTrainMe
                                control={control}
                                name={'name'}
                                label={'Nome'}
                                fullWidth
                                errors={errors.name}
                            />
                        </div>
                        <div>
                            <CustomInput
                                label="Celular"
                                name="phone"
                                mask="(00) 00000-0000"
                                errors={errors.phone}
                                register={register}
                                onChange={(e) => setValue('phone', e.target.value)}
                            />
                        </div>
                        <div>
                            <TextFieldTrainMe
                                control={control}
                                name={'document'}
                                label={'CPF'}
                                fullWidth
                                errors={errors.document}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="column">
                        <div className="credential-container">
                            <div>
                                <Autocomplete
                                    name="typeCredential"
                                    options={["CREF", "CRN", "CRM"]}
                                    renderInput={(params) => <TextField {...params} label="Conselho" />}
                                    value={typeRegistry}
                                    onChange={(_, data) => {
                                        setTypeRegistry(data);
                                        return data;
                                    }}
                                    defaultValue="CREF"
                                />
                            </div>
                            <div>
                                <TextFieldTrainMe
                                    control={control}
                                    name={'credential'}
                                    label={'Registro'}
                                    fullWidth
                                    errors={errors.credential}
                                />
                            </div>
                        </div>
                        <div>
                            <TextFieldTrainMe
                                control={control}
                                name={'occupation'}
                                label={'Ocupação'}
                                fullWidth
                                errors={errors.occupation}
                            />
                        </div>
                        <div>
                            <TextArea
                                control={control}
                                name={'description'}
                                label={'Descrição'}
                                fullWidth
                                errors={errors.description}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MyData;
