import React from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Button,
    Typography
} from "@mui/material";

import "./Plans.scss";

const Plans = () => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));

    const handleCancel = () => {
        const phoneNumber = '551151968694';
        const message = 'Olá, gostaria de cancelar o meu plano na plataforma Coach.';
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    return (
        <Box className="plans">
            <div className="header-plans">
                <div className="container-left">
                    <Typography variant="h3">Planos</Typography>
                </div>
            </div>
            <Box className="content">
                <Typography>{consultant.plan ? `Plano Ativo: ${consultant.plan?.name}` : 'Você ainda não possui um plano ativo.'}</Typography>
                {!consultant.plan &&
                    <Link to="/upgrade" variant="body2">
                        Clique aqui para contratar seu plano
                    </Link>}
                {consultant.plan && (<>
                    <Box>
                        <Link to="/upgrade" variant="body2">
                            Alterar Plano
                        </Link>
                    </Box>
                    <Button sx={{ fontSize: 12, color: 'red', p: 0, mt: 5 }} onClick={handleCancel}>
                        Cancelar Plano
                    </Button>
                </>)}
            </Box>
        </Box>
    );
};

export default Plans;
