import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { CommonLoading } from 'react-loadingg';

import Header from "../../Components/Header/Header";
import ModalDelete from "../../Components/ModalDelete/ModalDelete";
import SecondaryButton from "../../Components/SecondaryButton/SecondaryButton";
import Subtitle from "../../Components/Subtitle/Subtitle";
import Title from "../../Components/Title/Title";
import ModalAddQuestionnaire from "./Components/ModalAddQuestionnaire/ModalAddQuestionnaire";

import { GetQuestionnares, DeleteQuestionnare } from "../../Services/Questionnares/index";

import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import Feedback from '../../Components/Feedback/Feedback';

import "./Questionnaire.scss";

const Questionnaire = () => {
    const PUBLIC_PATH = process.env.REACT_APP_PUBLIC_URL;
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const access_token = localStorage.getItem('access_token');
    const navigate = useNavigate();

    const [questionnaires, setQuestionnaires] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [questionnaire, setQuestionnaire] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const handleModal = () => {
        setModal(!modal);
    };

    const handleModalDelete = () => {
        setModalDelete(!modalDelete);
    };

    const handleSetQuestionnaire = (questionnaire) => {
        setQuestionnaire(questionnaire);
        handleModalDelete();
    };

    const handleDeleteQuestion = async (id) => {
        try {
            await deleteQuestionnaire(id)
            const newList = questionnaires.filter(q => q.id !== id);
            setQuestionnaires(newList);
        } catch (ex) {
            setError(ex?.error || 'Erro ao remover questionário.');
            setTimeout(() => {
                setError(undefined);
            }, 10000);
        }
        finally {
            handleModalDelete();
        }
    };

    const deleteQuestionnaire = async (id) => {
        // Delete o questionario
        await DeleteQuestionnare(access_token, id);
    };

    const fetchData = useCallback(async () => {
        // Busca todos os questionarios
        let questionnaireList = await GetQuestionnares(access_token, consultant.id);
        questionnaireList = questionnaireList?.data?.rows.map(questionnaire => {
            return {
                id: questionnaire.id,
                questionnaireName: questionnaire.name,
                countQuestions: questionnaire.countQuestions
            };
        });
        setQuestionnaires(questionnaireList || []);
        setLoading(false);
    }, [access_token, consultant.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="questionnaire">
            <PageWrapper menuItem="/questionnaire">
                <div className="content">
                    <Header
                        title="Gerenciar questionários"
                        subtitle="Veja os questionários cadastrados abaixo e edite-os"
                    />

                    {error && (<Feedback severity="error" text={error} />)}
                    {loading && (<CommonLoading color="#553872" size="large" />)}
                    {!loading && (
                        <div className="questionnaire-container">
                            <div>
                                <div>
                                    <Title title="Questionários" />
                                    <Subtitle subtitle="Questionários cadastrados" />
                                </div>

                                <SecondaryButton onClick={handleModal} text="Criar novo" />
                            </div>
                            {questionnaires.length > 0 && (
                                questionnaires.map((questionnaire, key) => (
                                    <div key={key} className={key % 2 === 0 && 'background-gray'}>
                                        <div>
                                            <p className="title">Questionário de {questionnaire.questionnaireName}</p>
                                            <p className="subtitle">
                                                {questionnaire.countQuestions} {questionnaire.countQuestions === 1 ? 'pergunta' : 'perguntas'}
                                            </p>
                                        </div>

                                        <div className="actions">
                                            <img src={PUBLIC_PATH + "icon-delete.svg"} className="delete" alt="icon-delete"
                                                onClick={() => handleSetQuestionnaire(questionnaire)} />
                                            <div className="edit" onClick={() => navigate('/questionnaire/edit', { state: { questionnaire: questionnaire } })}>
                                                <img src={PUBLIC_PATH + "icon-edit.svg"} alt={"icon-edit"} />
                                                <span>Editar questionário</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                            {questionnaires.length === 0 && (
                                <section className="empty-list">
                                    <div>
                                        <div className="images-container">
                                            <img src={PUBLIC_PATH + "group-top.svg"} alt="group-top" className="image-top" />
                                            <img src={PUBLIC_PATH + "group-quite.svg"} alt="group-quite" className="image-quite" />
                                            <img src={PUBLIC_PATH + "group-bottom.svg"} alt="group-bottom" className="image-bottom" />
                                        </div>
                                        <h4>Ainda não há nenhum questionário criado!</h4>
                                        <div>
                                            <p>Clique em criar novo questionário e configure o primeiro</p>
                                            <p>questionário do seu aluno.</p>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </div>
                    )}

                    {modal &&
                        <ModalAddQuestionnaire
                            handleModal={handleModal}
                            questionnaires={questionnaires}
                            setQuestionnaires={setQuestionnaires}
                            reloadQuestionnaires={fetchData}
                        />
                    }
                    {modalDelete && (
                        <ModalDelete
                            handleModal={handleModalDelete}
                            title="Excluir formulário"
                            subtitle="Deseja excluir o questionário:"
                            text={`${questionnaire.questionnaireName} ?`}
                            id={questionnaire.id}
                            handleDelete={handleDeleteQuestion}
                        />
                    )}
                </div>
            </PageWrapper>
        </div>
    );
}

export default Questionnaire;