import React from 'react';
import { Box, Chip, IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Subtitle from '../../../../../../Components/Subtitle/Subtitle';
import Title from '../../../../../../Components/Title/Title';
import SecondaryButton from '../../../../../../Components/SecondaryButton/SecondaryButton';

import './FormMeals.scss';

const FormMeals = ({ title, meals, handleModalAddMeal, handleModalEditMeal, handleModalDeleteMeal }) => {
    const APP_PATH = process.env.REACT_APP_PUBLIC_URL;
    const totalCalories = meals.reduce((prev, curr) => prev + Number(curr.calories), 0).toFixed(0);
    const totalProtein = meals.reduce((prev, curr) => prev + Number(curr.protein), 0).toFixed(0);
    const totalCarbo = meals.reduce((prev, curr) => prev + Number(curr.carbohydrates), 0).toFixed(0);
    const totalLipids = meals.reduce((prev, curr) => prev + Number(curr.lipids), 0).toFixed(0);
    const totalFiber = meals.reduce((prev, curr) => prev + Number(curr.fiber), 0).toFixed(0);

    const onAddMeal = (e) => {
        e.preventDefault();
        handleModalAddMeal();
    };

    return (
        <form id="form-meals">
            <div className='form-meals'>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    gap={{ xs: 2, sm: 0 }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <div className='item-title'>
                        <Box pl={1}>
                            <Title title={title} />
                            <Subtitle subtitle="Edite o plano alimentar" />
                        </Box>

                        <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                            <Chip label={`${totalProtein} PTN`} variant="outlined" color="success" size="small" />
                            <Chip label={`${totalCarbo} CHO`} variant="outlined" color="info" size="small" />
                            <Chip label={`${totalLipids} LIP`} variant="outlined" color="warning" size="small" />
                            <Chip label={`${totalFiber} FIB`} variant="outlined" color="default" size="small" />
                            <Chip label={`${totalCalories} KCAL`} variant="outlined" color="error" size="small" />
                        </Box>
                    </div>

                    <SecondaryButton text="Adicionar Alimento" onClick={onAddMeal} />
                </Stack>

                <div className="exercise-item-container">
                    {meals.length > 0 && meals.map((meal, key) => (
                        <div key={key} className='exercise-item'>
                            <div className='exercise-title'>
                                <div>
                                    <div>
                                        <p className='p-title'>{meal.name}</p>
                                        <Subtitle subtitle={`${meal.quantity ? meal.quantity : ''} ${meal.type} | ${meal.calories} kcal `} />
                                        <Subtitle subtitle={`${meal.protein} P | ${meal.carbohydrates} C | ${meal.lipids} G | ${meal.fiber} F`} />
                                    </div>
                                </div>
                                <div>
                                    <IconButton edge="end" aria-label="edit" onClick={(e) => handleModalEditMeal(meal, key)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={(e) => handleModalDeleteMeal(meal)}>
                                        <DeleteIcon sx={{ color: '#c7427b' }} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {meals.length === 0 && (
                    <section className="empty-list">
                        <div>
                            <div className="images-container">
                                <img src={APP_PATH + "group-top.svg"} alt="group-top" className="image-top" />
                                <img src={APP_PATH + "group-quite.svg"} alt="group-quite" className="image-quite" />
                                <img src={APP_PATH + "group-bottom.svg"} alt="group-bottom" className="image-bottom" />
                            </div>
                            <h4>Não há refeição cadastrada</h4>
                            <div>
                                <p>Crie uma agora mesmo</p>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        </form>
    );
};

export default FormMeals;