import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import { Controller } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import {
    InputAdornment,
    IconButton,
    FormHelperText
} from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import './TextField.scss';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={props.mask}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const TextFieldTrainMe = ({
    label,
    name,
    fullWidth = false,
    errors,
    type = "text",
    multiline = false,
    control,
    defaultValue,
    onChange = undefined,
    mask = undefined,
    showInput = false,
    disabled = false,
    onBlur = undefined,
    handleKeyDown = undefined
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const getInput = (field) => {
        if (onChange) {
            return <TextField
                {...field}
                name={name}
                onChange={onChange}
                label={label}
                variant="outlined"
                type={type}
                fullWidth={fullWidth}
                multiline={multiline}
            />;
        }
        if (!onChange && showInput === true) {
            return <Input
                {...field}
                name={name}
                label={label}
                variant="outlined"
                type={type}
                fullWidth={fullWidth}
                multiline={multiline}
                onKeyDown={handleKeyDown}
                inputProps={{ mask }}
                onBlur={onBlur}
                inputComponent={mask && TextMaskCustom}
            />;
        }
        if (!onChange) {
            return <TextField
                {...field}
                name={name}
                label={label}
                variant="outlined"
                type={type !== 'password' ? type : showPassword ? "text" : "password"}
                fullWidth={fullWidth}
                multiline={multiline}
                disabled={disabled}
                onBlur={onBlur}
                InputProps={{
                    endAdornment: type === 'password' && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />;
        }
    }

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field }) => getInput(field)}
            />
            {errors && <FormHelperText style={{ color: 'red' }}>{errors.message}</FormHelperText>}
        </>
    );
};

export default TextFieldTrainMe;