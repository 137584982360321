import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import {
    Grid,
    Stack,
} from "@mui/material";
import InfiniteScroll from 'react-infinite-scroll-component';

import InputMessage from "../InputMessage/InputMessage";
import MessageBubble from "../MessageBubble/MessageBubble";
import ChatTopBar from "../ChatTopBar/ChatTopBar";

import { CreateMessage, GetChatMessages } from "../../../../Services/Chats/index";

import { ChatContext } from '../../../../Context/ChatContext';

import "./Messages.scss";

const Messages = () => {
    const paragraphRef = useRef(null);
    const access_token = localStorage.getItem('access_token');
    const { messages, userInfo, chatId, setMessages, totalMessages } = useContext(ChatContext);
    const [message, setMessage] = useState("");
    const [, setOpen] = useState(false);
    const [page, setPage] = useState(2);
    const [hasMore, setHasMore] = useState(true);

    const handleCreateMessage = async () => {
        if (message && message !== null && message !== '') {
            const consultant = JSON.parse(sessionStorage.getItem('consultant'));
            const createPayload = {
                content: message
            };
            await CreateMessage(access_token, chatId, createPayload);

            setMessages((prevState) => [{
                ...{
                    ...createPayload,
                    sender: {
                        id: consultant.userId
                    }
                },
                createdAt: new Date()
            }, ...prevState]);

            setMessage("");
        } else {
            setOpen(true);
        }
    };

    const getChatMessages = useCallback(async () => {
        const response = await GetChatMessages(access_token, page, chatId);
        setMessages((prevState) => prevState.concat(response.data?.rows));
        setPage((prevState) => (prevState + 1));
    }, [access_token, setMessages, chatId, page]);

    const handleScroll = async (e) => {
        await getChatMessages();
    };

    useEffect(() => {
        if (messages.length >= totalMessages) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    }, [messages, totalMessages]);

    return (
        <Grid item xs={12} md={7} lg={8} xl={9} sx={{ paddingTop: 0 }}>
            <Stack sx={{ height: "100%", justifyContent: "space-between" }} paddingTop={0}>
                <ChatTopBar userInfo={userInfo} />

                <div
                    id="scrollableDiv"
                    style={{
                        height: "70vh",
                        width: "100%",
                        overflow: "none",
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}>
                    <InfiniteScroll
                        dataLength={messages.length}
                        next={handleScroll}
                        hasMore={hasMore}
                        height="69vh"
                        inverse={true}
                        scrollThreshold={0.8}
                        style={{ display: 'flex', flexDirection: 'column-reverse', scrollbarColor: "grey white", borderTop: "0.1px solid grey" }}
                        scrollableTarget="scrollableDiv"
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Não há {messages.length > 0 ? 'mais' : ''} mensagens.</b>
                            </p>
                        }
                    >
                        {messages
                            .map(m => (
                                <MessageBubble
                                    key={m.id}
                                    message={m}
                                    userInfo={userInfo}
                                    showAvatar
                                />
                            ))}
                    </InfiniteScroll>
                </div>

                <p ref={paragraphRef}></p>
                {chatId && <InputMessage
                    setMessage={setMessage}
                    message={message}
                    handleCreateMessage={handleCreateMessage}
                />}
            </Stack>
        </Grid>
    );
};

export default Messages;
