import React from 'react';
import {
    Typography,
    Box
} from '@mui/material';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const PersonalInfo = ({ student, show }) => {
    return (
        <>
            <Card sx={{ display: 'flex' }}>
                <CardMedia
                    component="img"
                    sx={{ width: 120 }}
                    image={student.profileImagePath}
                    alt={student.name}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h6">
                            {student.name} {student.gender === 'M' && <>
                                <MaleIcon />
                            </>}
                            {student.gender === 'F' && <>
                                <FemaleIcon />
                            </>}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{ color: 'text.secondary' }}
                        >
                            {student.email}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{ color: 'text.secondary' }}
                        >
                            {student.phone}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{ color: 'text.secondary' }}
                        >
                            {`Senha provisória: ${student.tempPassword ?? '-'}`}
                        </Typography>
                    </CardContent>
                </Box>

            </Card>


            <Grid container >
                {student && show && (
                    <></>

                )}
                {
                    !student && show && (
                        <Typography variant="h3">Você não possui alunos</Typography>
                    )
                }
            </Grid >
        </>
    );
};

export default PersonalInfo;