import React, { useState, useEffect } from 'react';
import Carousel, { Modal, ModalGateway } from "react-images";
import { CommonLoading } from 'react-loadingg';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as RRCarousel } from 'react-responsive-carousel';
import { Card, CardContent } from '@mui/material';

import Subtitle from '../Subtitle/Subtitle';
import Title from '../Title/Title';


import './Evolution.scss';

const Evolution = ({ client }) => {
    const access_token = localStorage.getItem('access_token');
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [evolutionPhotos, setEvolutionPhotos] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleSetPhotos = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setViewerIsOpen(false);
    };

    useEffect(() => {
        const getPhotos = async () => {
            try {
                const photos = sessionStorage.getItem('latest_photos');
                if (photos) {
                    const latestPhotos = JSON.parse(photos);
                    const images = [
                        { src: `${process.env.REACT_APP_S3_BUCKET_URL}/${latestPhotos.frontImage}` },
                        { src: `${process.env.REACT_APP_S3_BUCKET_URL}/${latestPhotos.backImage}` },
                        { src: `${process.env.REACT_APP_S3_BUCKET_URL}/${latestPhotos.leftImage}` },
                        { src: `${process.env.REACT_APP_S3_BUCKET_URL}/${latestPhotos.rightImage}` }
                    ];
                    setEvolutionPhotos(images);
                }
            } catch (ex) { }
            finally {
                setLoading(false);
            }
        };
        getPhotos();
    }, [client.plan.id, access_token]);

    return (
        <Card
            variant="outlined"
            sx={{
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                flexGrow: 1,
            }}
        >
            <div className="evolution">
                <header>
                    <div>
                        <Title title="Evolução" />
                        <Subtitle subtitle="Confira as fotos do aluno" />
                    </div>
                </header>

                <div className="divider" />

                <CardContent
                    sx={{
                        mt: 2,
                        flexGrow: 1,
                    }}>
                    <div className="slider-container">
                        {(!loading && evolutionPhotos && evolutionPhotos.length > 0) && (
                            <RRCarousel
                                showThumbs={false}
                                infiniteLoop
                                onClickItem={(index) => handleSetPhotos(index)}
                            >
                                {evolutionPhotos.map((evolution, index) => (
                                    <div className="image-carousel" key={index}>
                                        <img
                                            src={evolution.src}
                                            alt=""
                                        />
                                    </div>
                                ))}
                            </RRCarousel>
                        )}
                        {!loading && evolutionPhotos && evolutionPhotos.length === 0 && (
                            <p>Ainda não há fotos da evolução do aluno!</p>
                        )}
                        {loading && (<CommonLoading color="#553872" size="large" />)}
                    </div>

                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    styles={{
                                        view: (base, state) => ({ ...base, maxWidth: '100%', maxHeight: '100%' })
                                    }}
                                    views={evolutionPhotos.map(x => ({
                                        ...x,
                                        srcset: x.srcSet,
                                        caption: x.title
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </CardContent>
            </div>
        </Card>
    );
};

export default Evolution;