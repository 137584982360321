import React, { useState, useEffect } from 'react';
import { Box, Checkbox } from '@mui/material';
import { Modal, ModalGateway } from "react-images";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Subtitle from '../../../../../../Components/Subtitle/Subtitle';
import ExercisePlayer from '../../../../../../Components/ExercisePlayer/ExercisePlayer';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import './NewExerciseBox.scss';

const NewExerciseBox = ({
    exercise,
    handleAgreggate,
    index,
    linked,
    parentIndex,
    linkedExercises,
    handleModalEditExercise,
    handleModalDeleteExercise,
    showImage
}) => {
    const APP_PATH = process.env.REACT_APP_PUBLIC_URL;
    const CARDIO_GROUP_ID = Number(process.env.REACT_APP_CARDIO_GROUP_ID);

    const [checked, setChecked] = useState(false);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const onChange = (ev, value) => {
        handleAgreggate(value, exercise);
        setChecked(!checked);
    };

    const closeLightbox = () => {
        setViewerIsOpen(false);
    };

    useEffect(() => {
        if (linkedExercises && linkedExercises.length === 0) {
            setChecked(false);
        }
    }, [linkedExercises]);

    return (
        <Paper
            className={`exercise-box ${index % 2 === 0 && linked && 'background-gray'}`}
            sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1
            }}
        >
            <Grid container spacing={2} className={`exercise-title ${linked ? 'linked' : ''}`}>
                <Grid item>
                    <Checkbox
                        onChange={onChange}
                        disabled={linked}
                        defaultChecked={linked}
                        checked={checked}
                        sx={{
                            display: { xs: "none", sm: "none", md: "block" },
                        }}
                    />
                </Grid>
                {showImage && <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Box sx={{
                        width: "100%",
                        height: "160px",
                        backgroundImage: `url(${exercise.exerciseLibrary?.image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }} onClick={() => setViewerIsOpen(true)} >

                    </Box>
                </Grid>}
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography gutterBottom variant="subtitle1 p-title " component="div">
                                {parentIndex !== undefined ? `${(parentIndex + 1)}.` : ''}{index + 1}. {exercise.name} | {exercise.group?.name}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <Subtitle subtitle={exercise.executionMethod} />
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {exercise.group?.id !== CARDIO_GROUP_ID ? (
                                    <>
                                        <Subtitle subtitle={` ${exercise.series} séries | ${exercise.repetitions} repetições`} />
                                        <Subtitle subtitle={`${exercise.rest}s descanso`} />
                                        <Subtitle subtitle={`Cadência: ${exercise.cadency}`} />
                                    </>
                                ) : (
                                    <Subtitle subtitle={`${exercise.repetitions} minutos`} />
                                )}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ButtonGroup variant="outlined" aria-label="Basic button group">
                                <Button sx={{
                                    display: { lg: "none", md: "block", xl: "none" },
                                }}>
                                    <Checkbox
                                        onChange={onChange}
                                        disabled={linked}
                                        defaultChecked={linked}
                                        checked={checked}
                                    />
                                </Button>

                                <Button edge="end" aria-label="edit" onClick={(e) => handleModalEditExercise(exercise)}>
                                    <EditIcon />
                                </Button>
                                <Button edge="end" aria-label="delete" onClick={(e) => handleModalDeleteExercise(exercise)}>
                                    <DeleteIcon sx={{ color: '#c7427b' }} />
                                </Button>
                                <Button onClick={() => setViewerIsOpen(true)}> <img src={APP_PATH + "icon-play.svg"} alt="icon-play" /></Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <ExercisePlayer
                            src={exercise.exerciseLibrary?.media}
                            name={exercise.name}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </Paper>


    );
};

export default NewExerciseBox;