import React, { useEffect, useState } from 'react';
import { useForm, } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal } from '@mui/material';

import TextFieldTrainMe from '../../../../../../Components/TextField/TextField';
import Select from '../../../../../../Components/Select/Select';
import PrimaryButton from '../../../../../../Components/PrimaryButton/PrimaryButton';
import Title from '../../../../../../Components/Title/Title';

import { ListMuscleGroups } from "../../../../../../Services/Exercises/index";

import './ModalNewExerciseLibrary.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo nome do exercício é obrigatório!"),
    description: yup.string().required("O campo de descrição é obrigatório!"),
    media: yup.string()
        .required("O campo de media é obrigatória!")
        .test('media', 'Os tipos de midias aceitas são: .gif, .mp4 ou youtube.', (value) => {
            const ext = value?.split('.')?.slice(-1)[0];
            if (ext !== 'mp4' && ext !== 'gif' && !value.includes('youtube')) return false;

            return true;
        }),
    image: yup.string()
        .when("media", (media, schema) => {
            if (media.includes('youtube'))
                return schema
                    .required("Campo de imagem é obrigatório com video do youtube.")
                    .test('image', 'Os tipos de imagens aceitas são: .png, .jpeg e .jpg', (value) => {
                        const ext = value?.split('.')?.slice(-1)[0];
                        if (ext !== 'png' && ext !== 'jpeg' && ext !== 'jpg') return false;
                        return true;
                    });
            return schema;
        }),
    group: yup.object().required("O grupo muscular é obrigatório!").nullable()
});

const ModalNewExerciseLibrary = ({ onSubmit, open, handleModal }) => {
    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const access_token = localStorage.getItem('access_token');

    const [muscleType, setMuscleType] = useState();
    const [muscleGroups, setMuscleGroups] = useState([]);
    const [media, setMedia] = useState();

    const onSubmitHandler = (payload) => {
        const exercise = {
            name: payload.name,
            description: payload.description,
            image: media.includes('youtube') ? payload.image : payload.media,
            media: payload.media,
            group: muscleType.id
        };
        onSubmit(exercise);
        handleModal();
    };

    useEffect(() => {
        const fetchGroups = async () => {
            const response = await ListMuscleGroups(access_token);
            setMuscleGroups(response.data.map((group) => ({
                id: group.id,
                label: group.name
            })));
        }
        fetchGroups();
    }, [access_token]);

    return (
        <Modal
            open={open}
            onClose={handleModal}
        >
            <div className='modal-new-exercise-lib'>
                <form className='modal-add' id='edit-exercise-form'>
                    <div className='titles'>
                        <div>
                            <div className='div-image'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-spare-exercise.svg"} alt={"icon-modal-spare-exercise"} />
                            </div>
                            <div className='div-title'>
                                <Title title="Adicionar exercício" />
                                <p>Insira as informações abaixo</p>
                            </div>
                        </div>

                        <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleModal} />
                    </div>

                    <div className='new-quiz'>
                        <div className='input'>
                            <TextFieldTrainMe
                                control={control}
                                name="name"
                                label="Nome do exercício"
                                fullWidth
                                errors={errors.name}
                            />
                        </div>
                    </div>

                    <div className='new-quiz'>
                        <div className='input'>
                            <TextFieldTrainMe
                                control={control}
                                name="description"
                                defaultValue=""
                                label="Descreva o exercicio"
                                fullWidth
                                errors={errors.description}
                            />
                        </div>
                    </div>

                    <div className='new-quiz'>
                        <div className='input'>
                            <TextFieldTrainMe
                                control={control}
                                name="media"
                                defaultValue=""
                                label="Adicione a url da midia de execução do exercicio"
                                fullWidth
                                errors={errors.media}
                                onChange={(value) => {
                                    setValue('media', value.target.value);
                                    setMedia(value.target.value);
                                }}
                            />
                        </div>
                    </div>

                    {media && media.includes('youtube') && (
                        <div className='new-quiz'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    name="image"
                                    defaultValue=""
                                    label="Adicione a url da imagem do exercicio"
                                    fullWidth
                                    errors={errors.image}
                                />
                            </div>
                        </div>
                    )}

                    <div className='new-quiz'>
                        <div className='select'>
                            <Select
                                name="group"
                                options={muscleGroups}
                                label="Selecione o tipo"
                                setValue={setMuscleType}
                                control={control}
                                errors={errors.group}
                            />
                        </div>
                    </div>

                    <div className='button'>
                        <PrimaryButton text="Adicionar" onClick={handleSubmit(onSubmitHandler)} />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ModalNewExerciseLibrary;