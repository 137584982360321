import { PostAsync, PutAsync, GetAsync, DeleteAsync } from '../api'

/**
 * Método responsável criar um treino
*/
const CreateTraining = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/training`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável edita um treino
*/
const UpdatedTraining = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/training`, config, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por listar todos os treinos disponiveis
*/
const GetTrainings = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/training/plans/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por deletar uma treino
*/
const RemoveTraining = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await DeleteAsync(`/training/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por obter as estatisticas
*/
const GetTrainingsStatistics = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await GetAsync(`/training/protocol/${id}/statistics`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

export {
    CreateTraining,
    UpdatedTraining,
    GetTrainings,
    RemoveTraining,
    GetTrainingsStatistics
}