import { PostAsync, PutAsync, DeleteAsync, GetAsync } from '../api'

/**
 * Método responsável por criar uma refeição
*/
const CreateMeal = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/foods`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável edita um treino
*/
const UpdatedMeal = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/foods`, config, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por deletar uma refeição
*/
const DeleteMeal = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await DeleteAsync(`/foods/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por listar todos os alimentos da biblioteca
*/
const GetLibrary = (token) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/food-library`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

export {
    CreateMeal,
    UpdatedMeal,
    DeleteMeal,
    GetLibrary
}