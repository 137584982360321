import React from 'react';
import { useDrop } from 'react-dnd';

const DraggableTarget = ({ children, callback, context, targetIdx }) => {
    const [{ isOverCurrent }, drop] = useDrop(() => ({
        accept: 'ITEM',
        drop: (item, monitor) => handleDrop(item, monitor),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
        }),
    }));

    const handleDrop = (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (item.context !== 'all' && item.context !== context) {
            return;
        }
        if (didDrop) return;
        callback(item.item, targetIdx, item.context);
    };

    return (
        <div
            ref={drop}
            style={{
                backgroundColor: isOverCurrent ? 'lightgrey' : '',
            }}
        >
            {children}
        </div>
    );
};

export default DraggableTarget;
