import React from 'react';
import Calendar from 'react-calendar';

import './ReactCalendar.scss';

const ReactCalendar = ({ onChange, value, selectRange }) => {
    return (
        <Calendar className={selectRange && 'background-blue'} onChange={onChange} value={value} />
    );
}

export default ReactCalendar;