import axios from 'axios';

import Logout from './logout';

/**
 * Método responsável por enviar dados na API
*/
const PostAsync = (url, customHeader, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                baseURL: process.env.REACT_APP_BASE_URL,
                ...customHeader
            };
            const instance = axios.create(config);
            const response = await instance.post(url, data);
            resolve(response);
        } catch (err) {
            if (!url.includes('auth/login') && err.response && err.response.status === 401) {
                await Logout();
            }
            if (err && err.response && err.response.status === 400) {
                resolve(err.response);
            } else {
                reject(err);
            }
        }
    });
}

/**
 * Método responsável por atualizar dados na API
*/
const PutAsync = (url, customHeader, data, logoutOnError = true) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                baseURL: process.env.REACT_APP_BASE_URL,
                ...customHeader
            };
            const instance = axios.create(config);
            const response = await instance.put(url, data);
            resolve(response);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por enviar um put via formdata
*/
const PutFormAsync = (url, customHeader, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                baseURL: process.env.REACT_APP_BASE_URL,
                ...customHeader
            };
            const instance = axios.create(config);
            const response = await instance.putForm(url, data);
            resolve(response);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                await Logout();
            }
            reject(err);
        }
    });
}

/**
 * Método responsável por buscar dados na API
*/
const GetAsync = (url, customHeader, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                baseURL: process.env.REACT_APP_BASE_URL,
                ...customHeader
            };
            const instance = axios.create(config);
            const response = await instance.get(url, data);
            resolve(response);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                await Logout();
            }
            reject(err);
        }
    });
}

/**
 * Método responsável por buscar dados na API
*/
const DeleteAsync = (url, customHeader) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                baseURL: process.env.REACT_APP_BASE_URL,
                ...customHeader
            };
            const instance = axios.create(config);
            const response = await instance.delete(url);
            resolve(response);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                await Logout();
            }
            reject(err);
        }
    });
}

export {
    PostAsync,
    GetAsync,
    PutAsync,
    DeleteAsync,
    PutFormAsync
}
