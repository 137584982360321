import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import Box from '@mui/material/Box';

import Title from '../../../../Components/Title/Title';
import Subtitle from '../../../../Components/Subtitle/Subtitle';

import './GeneralInfo.scss';

const GeneralInfo = ({ currentYear, revenue, plansInfo }) => {
    const COLORS = ['#C7427B', '#1E306E', '#3ac49c', '#db3d60', '#c2c627'];
    var totalProtocols = plansInfo?.reduce((a, b) => {
        return a + b.totalProtocols;
    }, 0);

    const getPercentage = (value) => {
        return ((value * 100) / totalProtocols).toFixed(2);
    };

    return (
        <Box
            className="general-info"
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "column", lg: "row", xl: "row" } }}>
            <Box className="card-left">
                <Title title="Valor total no ano" />
                <Subtitle subtitle={`Faturamento com base no ano de ${currentYear}`} />

                <div className='card-left-content'>
                    <div className='card-left-image mt-7'>
                        <img src={process.env.REACT_APP_PUBLIC_URL + 'icon-card-home.svg'} alt="" />
                    </div>

                    <div className='card-left-block'>
                        <p className='content-cards-subtitle'>Faturamento:</p>
                        <Title
                            title={revenue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}
                        />
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + 'alert-circle.svg'} className="mt-7" alt="" />
                </div>
            </Box>

            <Box className="card-right" width={"100%"} sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row", xl: "row" } }}>
                <Box>
                    <Title title="Dados dos protocolos" />
                    <Subtitle subtitle="Alocação dos alunos por protocolos" />

                    <PieChart width={180} height={100}>
                        <Pie
                            data={plansInfo}
                            cx={80}
                            cy={100}
                            startAngle={179}
                            endAngle={0}
                            innerRadius={50}
                            outerRadius={80}
                            paddingAngle={0}
                            nameKey="planName"
                            dataKey="totalProtocols"
                        >
                            {plansInfo.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index]} />
                            ))}
                        </Pie>
                    </PieChart>
                    <div className='card-right-students'>
                        <span>Protocolos</span>
                        <strong>{totalProtocols}</strong>
                    </div>
                </Box>
                <Box className="right">
                    {plansInfo && plansInfo.map((plan, index) => (
                        <div className="right-container" key={index}>
                            <span style={{ color: COLORS[index] }}>{`${getPercentage(plan.totalProtocols)}%`}</span>
                            <div className='right-divider' style={{ background: COLORS[index] }} />
                            <div className='right-content'>
                                <p className='right-content-title'>
                                    <span style={{ color: COLORS[index] }}>{plan.planName}</span>
                                </p>
                                <p className='right-content-subtitle'><strong>{plan.totalProtocols}</strong> protocolos</p>
                                <p className='right-content-subtitle'><strong>{plan.studentQuantity}</strong> alunos</p>
                            </div>
                        </div>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default GeneralInfo;