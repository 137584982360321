import React, { createContext, useReducer } from 'react';

const Reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SIGN_IN':
            return {
                isSignedIn: true,
                isLoading: false,
                userToken: 'asd'
            };
        case 'SIGN_OUT':
            return {
                isSignedIn: false,
                userToken: ""
            };
        default: return {};
    };
}

function _createDataContext(reducer, actions, defaultValue) {
    const AppContext = createContext();

    const Provider = ({ children }) => {
        const [state, dispatch] = useReducer(reducer, defaultValue);

        const boundActions = {};
        for (let key in actions) {
            boundActions[key] = actions[key](dispatch);
        }

        return (
            <AppContext.Provider value={{ state, ...boundActions }}>
                {children}
            </AppContext.Provider>
        );
    };

    return { AppContext, Provider };
};

const signIn = dispatch => values => {
    dispatch({ type: 'SIGN_IN', payload: values });
};
const signOut = dispatch => values => {
    dispatch({ type: 'SIGN_OUT', payload: values });
};

export const { Provider, AppContext } = _createDataContext(
    Reducer,
    { signIn, signOut },
    { isLoading: true, isSignedIn: false, userToken: null }
); 