import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { matchSorter } from 'match-sorter';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Subtitle from '../../../../../Components/Subtitle/Subtitle';
import Title from '../../../../../Components/Title/Title';
import TextFieldTrainMe from '../../../../../Components/TextField/TextField';
import TextArea from '../../../../../Components/TextArea/TextArea';
import CustomDatePicker from '../../../../../Components/CustomDatePicker/CustomDatePicker';
import PrimaryButton from '../../../../../Components/PrimaryButton/PrimaryButton';
import Select from '../../../../../Components/Select/Select';

import { CreateSchedule } from "../../../../../Services/Schedules/index";
import { GetStudents } from "../..//../../../Services/Students/index";

import './ModalAddSchedule.scss';

const schema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório!").nullable(),
    description: yup.string().required("Descrição é obrigatório!"),
    ScheduleStart: yup.string().required("O campo data inicial é obrigatório!"),
    ScheduleEnd: yup.string()
        .required("O campo data final é obrigatório!")
});

const ModalAddSchedule = ({ open, handleModal, client }) => {
    const access_token = localStorage.getItem('access_token')
    const [, setOpenModal] = useState(open);
    const [students, setStudents] = useState([]);

    const { control, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            description: '',
            ScheduleStart: new Date(),
            ScheduleEnd: new Date(),
        },
    });

    const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['label'] });

    const handleCloseModal = () => {
        setOpenModal(false);
        handleModal(true);
    };

    const onSubmitHandler = async (data) => {
        const scheduleStartDate = new Date(data.ScheduleStart);
        const scheduleEndDate = new Date(data.ScheduleEnd);

        scheduleStartDate.setHours(scheduleStartDate.getHours() - 3);
        scheduleEndDate.setHours(scheduleEndDate.getHours() - 3);

        const createPayload = {
            name: data.name,
            description: data.description,
            idStudent: client ? client.studentId : data.student?.id,
            ScheduleStart: scheduleStartDate.toISOString(),
            ScheduleEnd: scheduleEndDate.toISOString(),
        };

        await CreateSchedule(access_token, createPayload);
        handleCloseModal(true);
        reset();
    };

    useEffect(() => {
        const getActiveStudents = async () => {
            const response = await GetStudents(access_token, 1, 500, "actives");
            const options = response.data.rows.map((r => ({ label: r.name, id: r.studentId })));
            setStudents(options);
        };
        getActiveStudents();
    }, [access_token]);

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='form-add-schedule'>
                <div className='titles'>
                    <div>
                        <div className='div-image'>
                            <img src={process.env.REACT_APP_PUBLIC_URL + "icon-bell.svg"} alt={"icon-bell"} />
                        </div>
                        <div className='div-title'>
                            <Title title={'Criar novo agendamento'} />
                            <p>Insira as informações abaixo </p>
                        </div>
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleCloseModal} />
                </div>

                <div className='new-quiz'>
                    <div className='input'>
                        <TextFieldTrainMe
                            control={control}
                            defaultValue=""
                            name={'name'}
                            label={'Insira o nome do agendamento'}
                            fullWidth
                            errors={errors.name}
                        />
                    </div>
                </div>

                <Box sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
                    <CustomDatePicker
                        label={"Data Inicial"}
                        name={"ScheduleStart"}
                        control={control}
                        errors={errors.ScheduleStart}
                        inputFormat={"dd/MM/yyyy HH:mm"}
                        views={['day', 'hours', 'minutes']}
                    />
                    <CustomDatePicker
                        label={"Data Final"}
                        name={"ScheduleEnd"}
                        control={control}
                        errors={errors.ScheduleEnd}
                        inputFormat={"dd/MM/yyyy HH:mm"}
                        views={['day', 'hours', 'minutes']}
                    />
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
                    <Box sx={{ width: "50%" }}>
                        <Select
                            name="student"
                            options={students}
                            defaultValue={client ? client.name : ''}
                            setValue={(data) => setValue('student', data)}
                            filterOptions={filterOptions}
                            label="Alunos ativos (opcional)"
                            control={control}
                            errors={errors.student}
                            disabled={client}
                        />
                    </Box>
                </Box>

                <div className='new-quiz'>
                    <Subtitle subtitle="Observação sobre o agendamento:" />

                    <div className='textarea'>
                        <TextArea
                            control={control}
                            name={'description'}
                            label={'Insira sua descrição'}
                            fullWidth
                            errors={errors.description}
                        />
                    </div>
                </div>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <PrimaryButton text="Criar agendamento" onClick={handleSubmit(onSubmitHandler)} />
                </Box>
            </div>
        </Modal >
    );
};

export default ModalAddSchedule;