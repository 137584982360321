import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { CommonLoading } from 'react-loadingg';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import StudentHeader from '../../Components/StudentHeader/StudentHeader';

import Library from './Components/Library/Library';
import WorkoutInfo from './Components/WorkoutInfo/WorkoutInfo';
import ExercisesList from './Components/ExercisesList/ExercisesList';
import LibraryMobile from './Components/LibraryMobile/LibraryMobile';

import { ListTrainingExercises } from "../../Services/Exercises/index";
import { GetStudentQuizzes } from "../../Services/Questionnares/index";

import { ExercisesContext } from '../../Context/ExercisesContext';

import './WorkoutDetails.scss';

const WorkoutDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const access_token = localStorage.getItem('access_token');
    const backend = window.innerWidth <= 1024 ? TouchBackend : HTML5Backend;

    let { training, client } = location.state;

    const [exercises, setExercises] = useState([]);
    const [questionnaire, setQuestionnaire] = useState();
    const [loading, setLoading] = useState(true);

    const getExercises = useCallback(async () => {
        try {
            const response = await ListTrainingExercises(access_token, training.id);
            setExercises(response?.data);
        } catch (ex) { }
        finally {
            setLoading(false);
        }
    }, [access_token, training.id]);

    useEffect(() => {
        const getStudentAnswers = async () => {
            // Busca todos os questionarios
            const response = await GetStudentQuizzes(access_token, client.id);
            setQuestionnaire(response.data?.rows[0]);
        };
        getStudentAnswers();
    }, [access_token, client.id]);

    useEffect(() => {
        getExercises();
    }, [getExercises]);

    return (
        <div className="workout-details">
            <ExercisesContext.Provider value={{ exercises, setExercises }}>
                <PageWrapper menuItem="/manage-students">
                    <DndProvider
                        options={{ enableMouseEvents: true }}
                        backend={backend}>
                        <div className="content">
                            <StudentHeader client={client} tab="1" />

                            {questionnaire && (
                                <div
                                    className="preview-questionnaire"
                                    onClick={() => navigate('/manage-plan/questionnaires', { state: { id: questionnaire.id, client: client, questionnaire: questionnaire } })}
                                >
                                    <span>Visualizar Anmanese</span>
                                    <img src={process.env.REACT_APP_PUBLIC_URL + "arrow-quiz.svg"} alt="arrow-quiz" />
                                </div>
                            )}

                            <Stack className='stack-container' spacing={2}>
                                <WorkoutInfo
                                    training={training}
                                    planWeeks={client.plan.weeks}
                                    planActivityId={client.plan.id}
                                />
                                {loading && (<CommonLoading color="#553872" size="large" />)}
                                {!loading && (
                                    <>
                                        <Box display={{ xs: 'block', sm: 'none' }}>
                                            <LibraryMobile trainingId={training.id} client={client} />
                                        </Box>
                                        <ExercisesList
                                            workoutId={training.id}
                                            callbackExercises={getExercises}
                                        />
                                    </>
                                )}
                            </Stack>
                        </div>
                        <Box display={{ xs: 'none', sm: 'block' }}>
                            <Library trainingId={training.id} client={client} />
                        </Box>
                    </DndProvider>
                </PageWrapper>
            </ExercisesContext.Provider>
        </div>
    );
};

export default WorkoutDetails;