import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { CommonLoading } from 'react-loadingg';
import { Typography } from "@mui/material";

import CardStudentAnswer from "./Components/CardStudentAnswer/CardStudentAnswer";
import Subtitle from "../../Components/Subtitle/Subtitle";
import Title from "../../Components/Title/Title";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";

import { GetAnswered } from "../../Services/Questionnares/index";

import "./QuestionnaireAnswers.scss";

const QuestionnaireAnswers = () => {
    const APP_PATH = process.env.REACT_APP_PUBLIC_URL;
    const navigate = useNavigate();
    const location = useLocation();
    const access_token = localStorage.getItem('access_token');

    const [answers, setAnswers] = useState([]);
    const [client] = useState(location.state.client);
    const [questionnaire] = useState(location.state.questionnaire);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const userQuizId = location.state.id;
            const answered = await GetAnswered(access_token, userQuizId);
            const answers = answered?.data?.answereds.map(item => {
                return {
                    question: item.question,
                    asnwer: item.answered
                }
            });
            setAnswers(answers);
            setLoading(false);
        };

        fetchData();
    }, [location.state.id, access_token]);

    return (
        <div className="questionnaire-answers">
            <PageWrapper menuItem="/questionnaire">
                <div className="content">
                    <div className="return" onClick={() => navigate(-1)}>
                        <img src={APP_PATH + "arrow-left.svg"} alt={"arrow-left"} />
                        <span>Voltar para listagem de gerenciamento</span>
                    </div>

                    {client && (
                        <header>
                            <div className="infos">
                                <div>
                                    <Title title="Ver respostas do aluno" />
                                    <Subtitle subtitle="Veja as respostas do aluno abaixo" />
                                </div>

                                <div className="divider" />

                                <div className="questions">
                                    <p>{questionnaire.quizzes.name}</p>
                                    <span>{questionnaire?.quizzes?.questions?.length || 0} perguntas</span>
                                </div>
                            </div>

                            <div className="student">
                                <p>Aluno:</p>

                                <div>
                                    <img src={client?.profileImagePath} alt={client.profileImagePath} />
                                    <span>{client?.name}</span>
                                </div>
                            </div>
                        </header>
                    )}
                    {loading && (<CommonLoading color="#553872" size="large" />)}
                    {!loading && (
                        <div className={`cards-answers ${answers.length < 3 && 'limit-column'}`}>
                            {answers.map((item, key) => (
                                <CardStudentAnswer key={key} index={key} item={item} />
                            ))}
                            {(answers === null || answers.length === 0) && <Typography color="red">Questionário ainda não foi respondido.</Typography>}
                        </div>
                    )}
                </div>
            </PageWrapper>
        </div>
    );
}

export default QuestionnaireAnswers;