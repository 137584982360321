import React, { useState, useEffect } from 'react';
import Carousel, { Modal, ModalGateway } from "react-images";
import {
    Typography,
    Box,
    Divider,
} from "@mui/material";
import { CommonLoading } from 'react-loadingg';

import { GetEvolution } from "../../../../Services/Plans";

const PhotosEvolution = ({ student }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [evolutionPhotos, setEvolutionPhotos] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const [loading, setLoading] = useState(true);

    const handleSetPhotos = (index, currentImg) => {
        setSelectedImage(evolutionPhotos[index]);
        setCurrentImage(currentImg);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setViewerIsOpen(false);
    };

    const formatDate = (date) => {
        const newDate = new Date(date);

        return `${newDate.getDay().toString().padStart(2, '0')}/${newDate.getMonth().toString().padStart(2, '0')}/${newDate.getFullYear()}`;
    };

    useEffect(() => {
        const getPhotos = async () => {
            try {
                const response = await GetEvolution(student.studentId);
                if (response.data.length > 0) {
                    setEvolutionPhotos(response.data);
                    sessionStorage.setItem('latest_photos', JSON.stringify(response.data[0]))
                }
            } catch (ex) { }
            finally {
                setLoading(false);
            }
        };
        if (student) {
            setEvolutionPhotos([]);
            getPhotos();
        }
    }, [student]);

    return (
        <>
            <Box className="photos-evolution" sx={{
                width: '100%',
                maxWidth: '100%',
                height: { xs: '90%', md: '40%' },
                bgcolor: 'background.paper',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                overflowY: 'scroll',
                scrollbarColor: 'grey white',
                borderRadius: 1,
                px: 3,
                py: 2,
                boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.2)'
            }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#553872', mb: 1 }}>Progresso</Typography>

                {evolutionPhotos && evolutionPhotos.length > 0 && evolutionPhotos.map((evolution, index) => (
                    <>
                        <Box key={index} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            mb: 1,
                            mt: 1
                        }}
                            onClick={() => handleSetPhotos(index, 0)}>
                            <Typography sx={{ fontWeight: '600', fontSize: 14 }}>{formatDate(evolution.date)}</Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                cursor: 'pointer'
                            }}>
                                <img
                                    onClick={() => handleSetPhotos(index, 0)}
                                    src={`${process.env.REACT_APP_S3_BUCKET_URL}/${evolution.frontImage}`}
                                    height={window.innerWidth >= 768 ? 150 : 75}
                                    width={window.innerWidth >= 768 ? 150 : 75}
                                    alt=""
                                />
                                <img
                                    onClick={() => handleSetPhotos(index, 1)}
                                    src={`${process.env.REACT_APP_S3_BUCKET_URL}/${evolution.backImage}`}
                                    height={window.innerWidth >= 768 ? 150 : 75}
                                    width={window.innerWidth >= 768 ? 150 : 75}
                                    alt=""
                                />
                                <img
                                    onClick={() => handleSetPhotos(index, 2)}
                                    src={`${process.env.REACT_APP_S3_BUCKET_URL}/${evolution.leftImage}`}
                                    height={window.innerWidth >= 768 ? 150 : 75}
                                    width={window.innerWidth >= 768 ? 150 : 75}
                                    alt=""
                                />
                                <img
                                    onClick={() => handleSetPhotos(index, 3)}
                                    src={`${process.env.REACT_APP_S3_BUCKET_URL}/${evolution.rightImage}`}
                                    height={window.innerWidth >= 768 ? 150 : 75}
                                    width={window.innerWidth >= 768 ? 150 : 75}
                                    alt=""
                                />
                            </Box>
                        </Box>
                        {index !== evolutionPhotos.length - 1 && <Divider component="div" />}
                    </>
                ))}
                {!loading && evolutionPhotos && evolutionPhotos.length === 0 && (
                    <p>Ainda não há fotos da evolução do aluno!</p>
                )}
                {loading && (<CommonLoading color="#553872" size="large" />)}
            </Box>
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal open onClose={closeLightbox} styles={{ width: '200px', height: '200px' }}>
                        <>
                            <Typography sx={{ color: '#FFF', fontWeight: '600', textAlign: 'center', mb: 1 }}>Envio: {formatDate(selectedImage.date)}</Typography>
                            <Carousel
                                currentIndex={currentImage}
                                styles={{
                                    view: (base, state) => ({ ...base, width: 'auto', height: 'auto' })
                                }}
                                views={[
                                    {
                                        source: `${process.env.REACT_APP_S3_BUCKET_URL}/${selectedImage.frontImage}`,
                                        caption: 'Frente'
                                    },
                                    {
                                        source: `${process.env.REACT_APP_S3_BUCKET_URL}/${selectedImage.backImage}`,
                                        caption: 'Costas'
                                    },
                                    {
                                        source: `${process.env.REACT_APP_S3_BUCKET_URL}/${selectedImage.leftImage}`,
                                        caption: 'Lado esquerdo'
                                    },
                                    {
                                        source: `${process.env.REACT_APP_S3_BUCKET_URL}/${selectedImage.rightImage}`,
                                        caption: 'Lado direito'
                                    }
                                ]}
                            />
                        </>
                    </Modal>
                ) : null}
            </ModalGateway>
        </>
    );
};

export default PhotosEvolution;