import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { CommonLoading } from 'react-loadingg';
import { IconButton, FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import { Save } from "@mui/icons-material";

import PrimaryButton from "../../Components/PrimaryButton/PrimaryButton";
import Subtitle from "../../Components/Subtitle/Subtitle";
import Title from "../../Components/Title/Title";
import Header from "../../Components/Header/Header";
import ModalDelete from "../../Components/ModalDelete/ModalDelete";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import ModalEditNameForm from "./Components/ModalEditNameForm/ModalEditNameForm";
import ModalPreview from "./Components/ModalPreview/ModalPreview";

import { GetQuestionnare } from "../../Services/Questionnares/index";
import { CreateQuestion, DeleteQuestion } from "../../Services/Questions/index";

import "./EditQuestionnaire.scss";

const EditQuestionnaire = () => {
    const location = useLocation();

    const [questionnaire, setQuestionnaire] = useState();
    const [questionnaireCurrent, setQuestionnaireCurrent] = useState();
    const [question, setQuestion] = useState();
    const access_token = localStorage.getItem('access_token');
    const [modalDelete, setModalDelete] = useState(false);
    const [modalPreview, setModalPreview] = useState(false);
    const [modalEditNameForm, setModalEditNameForm] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            // Busca todos os questionarios
            const id = location.state.questionnaire.id;
            let questionnaire = await GetQuestionnare(access_token, id);
            // Refatorar para usar mesmo objeto do backend
            questionnaire = {
                id: questionnaire.data.id,
                questionnaireName: questionnaire.data.name,
                questions: questionnaire.data.questions.map(item => {
                    return {
                        id: item.id,
                        description: item.question
                    };
                })
            };
            setQuestionnaire(questionnaire);
            setQuestionnaireCurrent(questionnaire);
            setLoading(false);
        };

        fetchData();
    }, [location.state.questionnaire.id, access_token]);

    const handleModalPreview = () => {
        setModalPreview(!modalPreview);
    };

    const handleModalDelete = () => {
        setModalDelete(!modalDelete);
    };

    const handleModalEditNameForm = () => {
        setModalEditNameForm(!modalEditNameForm);
    };

    const handleSetDescription = (index, e) => {
        const data = [...questionnaire.questions];
        data[index].description = e.target.value;
        setQuestionnaire({ ...questionnaire, questions: data });
    };

    const handleAddQuiz = () => {
        const data = [...questionnaire.questions];
        data.push({
            description: ''
        });
        setQuestionnaire({ ...questionnaire, questions: data });
    };

    const handleSetQuestion = (question) => {
        setQuestion(question);
        handleModalDelete();
    };

    const handleSaveQuestion = () => {
        const dataAll = [];
        questionnaire.questions.forEach((question) => {
            if (question?.id) {
                const data = {
                    id: question?.id,
                    quiz: questionnaire.id,
                    question: question.description
                }
                dataAll.push(data);
            } else {
                const data = {
                    quiz: questionnaire.id,
                    question: question.description
                }
                dataAll.push(data);
            }
        })
        CreateQuestion(access_token, { data: dataAll })
        const difference = questionnaireCurrent.questions.filter(({ id: id1 }) => !questionnaire.questions.some(({ id: id2 }) => id2 === id1));
        difference.forEach(question => {
            DeleteQuestion(access_token, question.id)
        })
    };

    const handleDeleteQuestion = (id) => {
        setQuestionnaire({ ...questionnaire, questions: questionnaire.questions.filter(quiz => quiz.id !== id) });
        handleModalDelete();
    };

    return (
        <>
            {questionnaire && (
                <div className="edit-questionnaire">
                    <PageWrapper menuItem="/questionnaire">
                        <div className="content">
                            <Header
                                title=""
                                subtitle=""
                                name=""
                                occupation=""
                                showInfo={false}
                                showReturn
                            />

                            {loading && (<CommonLoading color="#553872" size="large" />)}
                            {!loading && (
                                <>
                                    <section className="section-title">
                                        <div>
                                            <div>
                                                <Title title="Criar formulário" />
                                                <Subtitle subtitle="Insira as questões do seu formulário abaixo" />
                                            </div>
                                            <div className="divider" />
                                            <div>
                                                <p className="title-blue">Questionário de {questionnaire.questionnaireName}</p>
                                                <Subtitle subtitle={questionnaire.questions.length + " perguntas"} />
                                            </div>
                                        </div>
                                        <div>
                                            <span className="light-blue cursor-pointer" onClick={handleModalEditNameForm}>Editar nome do formulário</span>
                                            <PrimaryButton text="Preview do formulário" onClick={handleModalPreview} />
                                        </div>
                                    </section>

                                    <div className={`cards-quiz ${questionnaire.questions < 3 && 'limit-column'}`}>
                                        {questionnaire.questions.map((item, key) => (
                                            <div className="card-quiz" key={key}>
                                                <div className="title">
                                                    <div>
                                                        <img src={process.env.REACT_APP_PUBLIC_URL + "icon-question.svg"} alt={"icon-question"} />
                                                        <div>
                                                            <p>Pergunta {key + 1}</p>
                                                            <span>Pergunta {key + 1}/{questionnaire.questions.length} do questionário</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={process.env.REACT_APP_PUBLIC_URL + "icon-delete.svg"}
                                                            className="cursor-pointer"
                                                            alt="icon-delete"
                                                            onClick={() => handleSetQuestion(item)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="description">
                                                    <p>Descrição da pergunta</p>
                                                    <FormControl sx={{ width: "95%" }} variant="outlined">
                                                        <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            type="text"
                                                            placeholder="Pergunta"
                                                            value={item.description}
                                                            onChange={(e) => handleSetDescription(key, e)}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="Salvar pergunta"
                                                                        onClick={handleSaveQuestion}
                                                                        edge="end"
                                                                    >
                                                                        <Save sx={{ color: "#1e306e" }} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="card-quiz mh" onClick={handleAddQuiz}>
                                            <div className="add">
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "icon-add-quiz.svg"} alt={"icon-add-quiz"} />
                                                <p className="title-blue">Adicionar pergunta ao questionário</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        {modalDelete && (
                            <ModalDelete
                                handleModal={handleModalDelete}
                                title="Excluir questão"
                                subtitle="Deseja excluir:"
                                text={question.description}
                                id={question.id}
                                handleDelete={handleDeleteQuestion}
                            />
                        )}
                        {modalPreview &&
                            <ModalPreview
                                handleModal={handleModalPreview}
                                questions={questionnaire?.questions}
                            />
                        }
                        {modalEditNameForm &&
                            <ModalEditNameForm
                                handleModal={handleModalEditNameForm}
                                questionnaire={questionnaire}
                                setQuestionnaire={setQuestionnaire}
                            />
                        }
                    </PageWrapper>
                </div>
            )}
            {!questionnaire && (
                <CommonLoading color="#553872" size="large" />
            )}
        </>
    );
}

export default EditQuestionnaire;