import React from 'react';

import Card from '../Card/Card';

import './Cards.scss';

const Cards = ({ totalStudents, newStudents, inactives }) => (
    <div className="cards">
        <Card
            title="Alunos:"
            subtitle={totalStudents > 0 ? `${totalStudents}`: '0'}
            infos="Número total de alunos"
        />
        <Card
            title="Novos alunos:"
            subtitle={newStudents > 0 ? `${newStudents}`: '0'}
            infos="Novos alunos nos últimos 30 dias"
        />
        <Card
            title="Alunos inativos:"
            subtitle={inactives > 0 ? `${inactives}`: ''}
            infos="Alunos inativos"
            showRedArrow
        />
    </div>
);

export default Cards;