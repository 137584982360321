import React, { useEffect, useState } from 'react';
import {
    Divider,
    Stack,
    Tabs,
    Tab,
    Box,
    Switch,
    Typography,
    Alert,
} from '@mui/material';
import { Modal, ModalGateway } from "react-images";
import InfiniteScroll from 'react-infinite-scroll-component';
import OpenWithIcon from '@mui/icons-material/OpenWith';

import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';
import SecondaryButton from '../../../../Components/SecondaryButton/SecondaryButton';
import ExercisePlayer from '../../../../Components/ExercisePlayer/ExercisePlayer';

import ModalNewExercise from './Components/ModalNewExercise/ModalNewExercise';
import ModalNewExerciseLibrary from './Components/ModalNewExerciseLibrary/ModalNewExerciseLibrary';
import ModalNewCardio from './Components/ModalNewCardio/ModalNewCardio';
import DraggableItem from '../DraggableItem/DraggableItem';

import { CreateExerciseLibrary, DeleteExerciseLibrary } from '../../../../Services/Exercises/index'
import { ListExercises } from "../../../../Services/Exercises/index";

import './LibraryMobile.scss';
import { TabContext, TabPanel } from '@mui/lab';

const LibraryMobile = ({ trainingId, client }) => {
    const APP_PATH = process.env.REACT_APP_PUBLIC_URL;
    const CARDIO_GROUP_ID = Number(process.env.REACT_APP_CARDIO_GROUP_ID);
    const access_token = localStorage.getItem('access_token');
    const expiredPlan = new Date(client.plan.dueDate) < new Date();

    const [search, setSearch] = useState("");
    const [searching, setSearching] = useState(false);
    const [searchedExercises, setSearchedExercises] = useState([]);
    const [groups, setGroups] = useState([]);
    const [libraries, setLibraries] = useState([]);
    const [showModalExercise, setShowModalExercise] = useState(false);
    const [showModalCardio, setShowModalCardio] = useState(false);
    const [showModalExerciseLibrary, setShowModalExerciseLibrary] = useState(false);
    const [currentExercise, setCurrentExercise] = useState();
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [tab, setTab] = useState('1');
    const [checked, setChecked] = useState(true);

    const closeLightbox = () => {
        setViewerIsOpen(false);
    };

    const handleSetPhotos = (image) => {
        setCurrentImage(image);
        setViewerIsOpen(true);
    };

    const fetchData = async () => {
        const response = await ListExercises(access_token);
        setLibraries(response.data);
        setGroups(Object.keys(response.data));
        setTab(0);
    };

    const openNewExerciseModal = (exercise) => {
        if (expiredPlan) return;

        setCurrentExercise(exercise);
        if (exercise.group.id === CARDIO_GROUP_ID) {
            setShowModalCardio(true);
        } else {
            setShowModalExercise(true);
        }
    };

    const handleFilterChange = (e) => {
        const searchedValue = e.target.value;
        setSearch(searchedValue);

        if (searchedValue === "") {
            setLibraries(libraries);
            setSearching(false);
            setSearchedExercises([]);
        } else {
            let filteredExs = [];
            groups.forEach((g) => {
                filteredExs = filteredExs.concat(
                    libraries[g].filter((ex) => ex.name.toLocaleLowerCase().includes(searchedValue.toLocaleLowerCase()))
                );
            });
            setSearchedExercises(filteredExs);
            setSearching(true);
        }
    };

    const createExerciseLibrary = async (exercise) => {
        await CreateExerciseLibrary(access_token, exercise);
        fetchData();
    }

    const deleteExerciseLibrary = async (exercise) => {
        await DeleteExerciseLibrary(access_token, exercise.id);
        fetchData();
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="library-mobile">
                <Box className='library-header' sx={{
                    bgcolor: '#FFF',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    borderRadius: '2px',
                }}>
                    <div>
                        <Title title="Biblioteca de exercícios" />
                        <Subtitle subtitle="Pesquise o exercício que procura" />
                    </div>

                    <Alert severity="info" sx={{ mb: 1 }}>
                        Utilize o icone para arrastar o exercicio para a área de treino.
                    </Alert>

                    <div className="search-container">
                        <div className="search">
                            <img src={APP_PATH + "icon-search.svg"} alt="search" />
                            <input
                                type="search"
                                placeholder="Pesquise o exercício"
                                value={search}
                                onChange={(e) => handleFilterChange(e)}
                            />
                        </div>
                    </div>
                    <Box mt={2}>
                        <SecondaryButton onClick={() => setShowModalExerciseLibrary(true)} text="Customizado" icon="icon-add" />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Switch checked={checked} onChange={() => setChecked(!checked)} color="info" />
                        <Typography>Visualizar exercicios</Typography>
                    </Box>
                </Box>

                {(searching && searchedExercises) && (
                    <div className="filtered-exercises">
                        <Stack spacing={3} divider={<Divider orientation="horizontal" flexItem />}>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    overflowX: "scroll",
                                    overflowY: "hidden",
                                    display: 'flex',
                                    flexDirection: 'row',
                                    whiteSpace: 'nowrap',
                                    backgroundColor: '#FFF',
                                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                                    borderRadius: '2px',
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                }}>
                                <InfiniteScroll
                                    dataLength={searchedExercises.length}
                                    hasMore={false}
                                    height="100%"
                                    style={{ display: 'flex', flexDirection: 'row', scrollbarColor: "grey white", borderTop: "0.1px solid grey" }}
                                    scrollableTarget="scrollableDiv"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 3,
                                            overflowX: 'scroll',
                                            px: 1
                                        }}>
                                        {searchedExercises.map((ex, key) => (
                                            <Box key={key} className='library-item' sx={{ minWidth: 200 }}>
                                                <Box className="item-header">
                                                    <div>
                                                        <h4>{ex.name}</h4>
                                                        <Subtitle subtitle={ex.description} />
                                                        <Box className="item-right" sx={{ marginTop: 1, display: 'flex', justifyContent: 'space-between' }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                                                <button
                                                                    onClick={() => openNewExerciseModal(ex)}
                                                                    disabled={expiredPlan}
                                                                    className={expiredPlan ? 'disabled' : ''}
                                                                >+</button>
                                                                {
                                                                    ex.custom ? (
                                                                        <img src={APP_PATH + "icon-delete.svg"} alt="icon-delete" onClick={() => deleteExerciseLibrary(ex)} />
                                                                    ) : <></>
                                                                }
                                                            </Box>

                                                            <DraggableItem item={ex} context="all">
                                                                <OpenWithIcon />
                                                            </DraggableItem>
                                                        </Box>
                                                    </div>
                                                </Box>
                                                {checked && <div className='item-left'>
                                                    <img
                                                        src={ex.image}
                                                        alt={ex.name}
                                                        width="100%"
                                                        height={200}
                                                        onClick={() => handleSetPhotos(ex.media)}
                                                    />
                                                </div>}
                                            </Box>
                                        ))}
                                    </Box>
                                </InfiniteScroll>
                            </div>
                        </Stack>
                    </div >
                )}
                {
                    (!searching && libraries) && (
                        <TabContext value={tab}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box>
                                    <Tabs
                                        value={tab}
                                        onChange={(e, v) => setTab(v)}
                                        aria-label="Groupos musculares"
                                        variant="scrollable"
                                        scrollButtons
                                        allowScrollButtonsMobile
                                    >
                                        {groups.map((group, key) => (
                                            <Tab label={group} value={key} className="menu-tab" />
                                        ))}
                                    </Tabs>
                                </Box>

                                <div
                                    id="scrollableDiv"
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        overflowX: "scroll",
                                        overflowY: "hidden",
                                        display: 'flex',
                                        flexDirection: 'row',
                                        whiteSpace: 'nowrap',
                                        backgroundColor: '#FFF',
                                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                                        borderRadius: '2px',
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                    }}>
                                    <InfiniteScroll
                                        dataLength={searchedExercises.length}
                                        hasMore={false}
                                        height="100%"
                                        style={{ display: 'flex', flexDirection: 'row', scrollbarColor: "grey white", borderTop: "0.1px solid grey" }}
                                        scrollableTarget="scrollableDiv"
                                    >
                                        {groups.map((group, key) => (
                                            <TabPanel value={key}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: 2,
                                                        overflowX: 'scroll',
                                                    }}>
                                                    {libraries[group].map((ex, key) => (
                                                        <Box key={key} className='library-item' sx={{ minWidth: 200 }}>
                                                            <div className="item-header">
                                                                <div>
                                                                    <h4>{ex.name}</h4>
                                                                    <Subtitle subtitle={ex.description} />
                                                                    <Box className="item-right" sx={{ marginTop: 1, display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                                                            <button
                                                                                onClick={() => openNewExerciseModal(ex)}
                                                                                disabled={expiredPlan}
                                                                                className={expiredPlan ? 'disabled' : ''}
                                                                            >+</button>
                                                                            {
                                                                                ex.custom ? (
                                                                                    <img src={APP_PATH + "icon-delete.svg"} alt="icon-delete" onClick={() => deleteExerciseLibrary(ex)} />
                                                                                ) : <></>
                                                                            }
                                                                        </Box>

                                                                        <DraggableItem item={ex} context="all">
                                                                            <OpenWithIcon />
                                                                        </DraggableItem>
                                                                    </Box>
                                                                </div>
                                                            </div>
                                                            {checked && <div className='item-left'>
                                                                <img
                                                                    src={ex.image}
                                                                    alt={ex.name}
                                                                    width={200}
                                                                    height={200}
                                                                    onClick={() => handleSetPhotos(ex.media)}
                                                                />
                                                            </div>}
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </TabPanel>
                                        ))}
                                    </InfiniteScroll>
                                </div>
                            </Box>
                        </TabContext>
                    )
                }
            </div >

            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <ExercisePlayer
                            src={currentImage}
                            name="Exercicio"
                        />
                    </Modal>
                ) : null}
            </ModalGateway>

            <ModalNewExercise
                exercise={currentExercise}
                trainingId={trainingId}
                open={showModalExercise}
                handleModal={() => setShowModalExercise(!showModalExercise)}
            />
            <ModalNewCardio
                open={showModalCardio}
                exercise={currentExercise}
                trainingId={trainingId}
                handleModal={() => setShowModalCardio(!showModalCardio)}
            />
            <ModalNewExerciseLibrary
                onSubmit={createExerciseLibrary}
                open={showModalExerciseLibrary}
                handleModal={() => setShowModalExerciseLibrary(!showModalExerciseLibrary)}
            />
        </>
    );
};

export default LibraryMobile;