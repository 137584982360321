import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';

import CustomDatePicker from '../../../../Components/CustomDatePicker/CustomDatePicker';
import TextFieldTrainMe from '../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';
import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';
import Feedback from '../../../../Components/Feedback/Feedback';

import { UpdatedDiet } from "../../../../Services/Diets/index";

import './DietInfo.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo dia do treino é obrigatório!").nullable(),
    dietStart: yup.string().required("O campo data inicial é obrigatório!"),
    dietEnd: yup.string().required("O campo data final é obrigatório!"),
});

const DietInfo = ({ clientId, dietId, name, dietStart, dietEnd, planActiveId }) => {
    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            dietStart: new Date(),
            dietEnd: new Date()
        }
    });
    const [success, setSucess] = useState(false);

    const access_token = localStorage.getItem('access_token');

    const onSubmitHandler = (data) => {
        const payload = {
            id: dietId,
            name: data.name,
            plansActivity: planActiveId,
            dietStart: data.dietStart,
            dietEnd: data.dietEnd,
            calories: data.calories
        };

        UpdatedDiet(access_token, payload);

        setSucess(true);
        setTimeout(() => {
            setSucess(false);
        }, 2500);
    };

    useEffect(() => {
        if (dietId) {
            setValue('name', name);
            setValue('dietStart', dietStart);
            setValue('dietEnd', dietEnd);
        }
    }, [setValue, dietId, name, dietStart, dietEnd]);

    return (
        <form id="form" className="diet-info">
            <Accordion>
                <AccordionSummary
                    expandIcon={<img src={process.env.REACT_APP_PUBLIC_URL + "icon-accordion.svg"} alt={"icon-accordion"} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div>
                        <Title title="Informações básicas da refeição" />
                        <Subtitle subtitle="Edite as informações abaixo" />
                    </div>
                </AccordionSummary>
                {success && (<Feedback severity="success" text="Informações da refeição alteradas sucesso" />)}

                <AccordionDetails>
                    <div className='accordion-container'>
                        <div className='accordion-item'>
                            <Subtitle subtitle="Refeição" />

                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    name={'name'}
                                    label={'Nome da refeição'}
                                    fullWidth
                                    errors={errors.name}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='accordion-container mt'>
                        <div className='accordion-item'>
                            <div className='dates'>
                                <Subtitle subtitle="Periodo da dieta" />
                                <div>
                                    <div className='datepicker-container'>
                                        <CustomDatePicker
                                            label={"Data Inicial"}
                                            name={"dietStart"}
                                            control={control}
                                            errors={errors.dietStart}
                                        />
                                    </div>
                                    <div className='datepicker-container'>
                                        <CustomDatePicker
                                            label={"Data Final"}
                                            name={"dietEnd"}
                                            control={control}
                                            errors={errors.dietEnd}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='div-button'>
                                <PrimaryButton text="Salvar alterações" onClick={handleSubmit(onSubmitHandler)} />
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </form>
    );
};

export default DietInfo;