import React, { useEffect, useState } from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Divider,
    Modal,
    Stack,
    Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Title from '../../../../Components/Title/Title';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';

import { CreateWithdraw } from "../../../../Services/Withdraw/index";

import './ModalWithdraw.scss';

const ModalWithdraw = ({ open, handleModal, setPendingWithdraw, availableAmount, setWithdrawAmount }) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmitHandler = async () => {
        try {
            setLoading(true);
            setError(false);
            const response = await CreateWithdraw();
            if (response.status) {
                handleModal(false);
                setPendingWithdraw(true);
                setWithdrawAmount(Number(response.data.amount));
            } else {
                setError(true);
            }
        } catch (ex) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })
    };

    useEffect(() => {
        setError(false);
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={() => handleModal(false)}
            aria-labelledby="Efetuar Saque"
            aria-describedby="Efetuar Saque"
        >
            <div className='form-withdraw'>
                <div className='titles'>
                    <div>
                        <div className='div-image'>
                            <PersonAddIcon />
                        </div>
                        <div className='div-title'>
                            <Title title="Efetuar Retirada" />
                            <p>Faça a retirada do total disponivel na sua carteira</p>
                        </div>
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={() => handleModal(false)} />
                </div>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mx: 3, mt: 1 }}>
                    {!consultant.pixKey && (
                        <Stack direction="column" spacing={2}>
                            <Typography variant="h3">Para realizar uma retirada é preciso cadastrar a sua chave PIX.</Typography>
                            <Typography variant="subtitle2">Para cadastrar o seu PIX, basta ir na aba "Minha Conta {'->'} Financeiro"</Typography>
                            <Link to="/manage-account" state={{ finance: true }}>
                                Clique aqui para cadastrar
                            </Link>
                        </Stack>
                    )}
                    {consultant.pixKey && (
                        <>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                px: 2,
                                borderWidth: 0,
                                borderStyle: 'solid',
                                borderColor: '#e5e7eb',
                            }}>
                                <Typography variant='h3'>Valor disponivel</Typography>
                                <Typography variant='h3'>{formatCurrency(availableAmount)}</Typography>
                            </Box>
                            <Divider />
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                px: 2,
                                borderWidth: 0,
                                borderStyle: 'solid',
                                borderColor: '#e5e7eb',
                            }}>
                                <Typography variant='h3'>Valor a receber</Typography>
                                <Typography variant='h3'>{availableAmount > 0 ? formatCurrency(availableAmount - 1.99) : 'R$ 0,00'}</Typography>
                            </Box>
                            <Alert severity="info">
                                Para efetuar a transferência é cobrado uma taxa de R$ 1,99.
                            </Alert>
                            <Alert severity="info">
                                O valor a receber já é descontado de todas as tarifas de processamento dos pagamentos.
                            </Alert>

                            {error && (
                                <Alert severity="warning">
                                    <AlertTitle>Não foi possivel realizar o saque.</AlertTitle>
                                    É necessário que o saldo seja maior que R$ 50,00 para efetuar o saque.
                                </Alert>
                            )}
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <PrimaryButton text="CONFIRMAR" onClick={onSubmitHandler} loading={loading} />
                            </Box>
                        </>
                    )}
                </Box>
            </div>
        </Modal>
    );
};

export default ModalWithdraw;