import React, { useEffect, useState, useCallback } from 'react';
import { matchSorter } from 'match-sorter';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import TextFieldTrainMe from '../../../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../../../Components/PrimaryButton/PrimaryButton';
import Title from '../../../../../../Components/Title/Title';
import Select from '../../../../../../Components/Select/Select';

import { CreateMeal, UpdatedMeal } from "../../../../../../Services/Meals/index";

import './ModalAddMeal.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo de nome do alimento é obrigatório.")
});

const ModalAddMeal = ({
    handleModal,
    mealIndex,
    mealItem,
    meals,
    setMeals,
    mealId,
    isReposition
}) => {
    const options = ['GRAMAS', 'PEDAÇO(S)', 'FATIA(S)', 'MILILITROS', 'UNIDADE(S)', 'À VONTADE'];
    const { control, handleSubmit, setValue, setError, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const foodsLibrary = JSON.parse(sessionStorage.getItem('foodsLibrary'));
    const access_token = localStorage.getItem('access_token');
    const [weightType, setWeightType] = useState(options[0]);
    const [selectedFood, setSelectedFood] = useState();

    const verifyElementClicked = (e) => {
        if (e.target.id === 'modal-add-meal-container') {
            handleModal();
        }
    };

    const onSubmitHandler = async (payload) => {
        const data = [...meals];
        if (weightType !== 'À VONTADE' && !payload.quantity) {
            setError('quantity', { type: 'custom', message: 'O campo de quantidade é obrigatório.' });
            return;
        }

        const requestPayload = {
            diets: mealId,
            name: payload.name,
            type: weightType,
            calories: payload.calories,
            protein: payload.protein,
            carbohydrates: payload.carbohydrates,
            lipids: payload.lipids,
            fiber: payload.fiber,
            reposition: isReposition
        };
        if (payload.quantity) {
            requestPayload['quantity'] = payload.quantity
        }

        if (mealItem !== undefined) {
            // Editar
            data[mealIndex].name = payload.name;
            data[mealIndex].quantity = payload.quantity;
            data[mealIndex].type = weightType;
            data[mealIndex].calories = payload.calories;
            data[mealIndex].protein = payload.protein;
            data[mealIndex].carbohydrates = payload.carbohydrates;
            data[mealIndex].lipids = payload.lipids;
            data[mealIndex].fiber = payload.fiber;
            UpdatedMeal(access_token, { ...requestPayload, id: data[mealIndex].id });
        }
        else {
            // Adicionar
            payload.type = weightType ? weightType : options[0];
            const newMeal = await CreateMeal(access_token, requestPayload);
            data.push({ ...payload, id: newMeal.data.id });
        }

        setMeals(data);
        handleModal();
    };

    const changeModalData = useCallback(({
        name,
        quantity,
        weightType,
        calories,
        protein,
        carbohydrates,
        lipids,
        fiber
    }) => {
        setValue('name', name);
        setValue('quantity', quantity);
        setValue('weightType', weightType);
        setValue('calories', calories);
        setValue('protein', protein);
        setValue('carbohydrates', carbohydrates);
        setValue('lipids', lipids);
        setValue('fiber', fiber);
    }, [setValue]);

    const onSelectFood = (food) => {
        if (food && food.id !== undefined) {
            changeModalData({ ...food, quantity: food.comparativeBase, weightType: food.typeComparativeBase });
            setSelectedFood(food);
        } else {
            setValue('name', '');
            setValue('quantity', 100);
            setValue('weightType', options[0]);
            setValue('calories', 0);
            setValue('protein', 0);
            setValue('carbohydrates', 0);
            setValue('lipids', 0);
            setValue('fiber', 0);
            setSelectedFood(undefined);
        }
    };

    const onChangeQuantity = (e) => {
        const qty = e.target.value;
        if (selectedFood && weightType === 'GRAMAS') {
            changeModalData({
                ...selectedFood,
                quantity: qty,
                calories: (selectedFood.calories * (qty / selectedFood.comparativeBase)).toFixed(2),
                protein: (selectedFood.protein * (qty / selectedFood.comparativeBase)).toFixed(2),
                carbohydrates: (selectedFood.carbohydrates * (qty / selectedFood.comparativeBase)).toFixed(2),
                lipids: (selectedFood.lipids * (qty / selectedFood.comparativeBase)).toFixed(2),
                fiber: (selectedFood.fiber * (qty / selectedFood.comparativeBase)).toFixed(2)
            });
        } else {
            setValue('quantity', qty);
        }
    };

    const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['label'] });

    useEffect(() => {
        if (mealItem !== undefined) {
            changeModalData(mealItem);
        }
    }, [setValue, mealItem, changeModalData]);

    return (
        <div className='modal-add-meal-container' onClick={e => verifyElementClicked(e)} id='modal-add-meal-container'>
            <form className='modal-add'>
                <div className='titles'>
                    <div>
                        <div className='div-image'>
                            <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-spare-exercise.svg"} alt={"icon-modal-spare-exercise"} />
                        </div>
                        <div className='div-title'>
                            <Title title={mealItem ? 'Editar Alimento' : 'Adicionar Alimento'} />
                            <p>Insira as informações abaixo</p>
                        </div>
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleModal} />
                </div>

                <div className="row">
                    <div className='new-quiz full-width'>
                        <div className='input'>
                            <Select
                                name="name"
                                freeSolo
                                options={foodsLibrary}
                                defaultValue={mealItem ? mealItem.name : ''}
                                setValue={onSelectFood}
                                onKeyUp={(e) => setValue('name', e.target.value)}
                                filterOptions={filterOptions}
                                label="Nome do alimento"
                                control={control}
                                errors={errors.name}
                            />
                        </div>
                    </div>

                    <div className='new-quiz'>
                        <div className='input'>
                            <TextFieldTrainMe
                                control={control}
                                defaultValue={mealItem ? mealItem.calories : ''}
                                name="calories"
                                label="Calorias"
                                type="number"
                                fullWidth
                                errors={errors.calories}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className='new-quiz'>
                        <div className='input'>
                            <TextFieldTrainMe
                                control={control}
                                defaultValue={mealItem ? mealItem.protein : ''}
                                name="protein"
                                label="Proteina (g)"
                                type="number"
                                fullWidth
                                errors={errors.protein}
                            />
                        </div>
                    </div>

                    <div className='new-quiz'>
                        <div className='input'>
                            <TextFieldTrainMe
                                control={control}
                                defaultValue={mealItem ? mealItem.carbohydrates : ''}
                                name="carbohydrates"
                                label="Carboidratos (g)"
                                type="number"
                                fullWidth
                                errors={errors.carbohydrates}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className='new-quiz'>
                        <div className='input'>
                            <TextFieldTrainMe
                                control={control}
                                defaultValue={mealItem ? mealItem.lipids : ''}
                                name="lipids"
                                label="Lipidios (g)"
                                type="number"
                                fullWidth
                                errors={errors.lipids}
                            />
                        </div>
                    </div>

                    <div className='new-quiz'>
                        <div className='input'>
                            <TextFieldTrainMe
                                control={control}
                                defaultValue={mealItem ? mealItem.fiber : ''}
                                name="fiber"
                                label="Fibra (g)"
                                type="number"
                                fullWidth
                                errors={errors.fiber}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className='new-quiz full-width'>
                        <div className='select'>
                            <Select
                                name="weightType"
                                options={options}
                                defaultValue={mealItem ? mealItem.type : weightType}
                                label="Selecione a unidade de medida"
                                setValue={setWeightType}
                                control={control}
                                errors={errors.weightType}
                            />
                        </div>
                    </div>

                    {
                        weightType !== 'À VONTADE' && (
                            <div className='new-quiz'>
                                <div className='input'>
                                    <TextFieldTrainMe
                                        control={control}
                                        defaultValue={mealItem ? mealItem.quantity : ''}
                                        name="quantity"
                                        label="Quantidade"
                                        type="number"
                                        onChange={onChangeQuantity}
                                        fullWidth
                                        errors={errors.quantity}
                                    />
                                </div>
                            </div>
                        )}
                </div>

                <div className="button">
                    <PrimaryButton
                        text={mealItem ? 'Editar Alimento' : 'Adicionar Alimento'}
                        onClick={handleSubmit(onSubmitHandler)}
                    />
                </div>
            </form>
        </div>
    );
};

export default ModalAddMeal;