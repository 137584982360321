import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import {
    Alert,
    Box,
    Tab,
    Tabs,
} from "@mui/material";
import { TabContext, TabPanel } from '@mui/lab';

import Header from "../../Components/Header/Header";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import PersonalInfo from "./Components/PersonalInfo/PersonalInfo";
import Questionnaires from "./Components/Questionnaires/Questionnaires";
import Protocols from "./Components/Protocols/Protocols";
import PhotosEvolution from "./Components/PhotosEvolution/PhotosEvolution";
import ChatMessage from "./Components/ChatMessage/ChatMessage";

import { GetStudentDetails } from "../../Services/Students/index";

import "./StudentDetails.scss";

const StudentDetails = () => {
    const location = useLocation();
    const { student } = location.state;
    const [currentStudent, setCurrentStudent] = useState(student);

    const [value, setValue] = useState('1');

    const getDetails = useCallback(async (studentId) => {
        const response = await GetStudentDetails(studentId);
        setCurrentStudent(({
            ...response.data.student,
            profileImagePath: response.data.student.profileImagePath ? `${process.env.REACT_APP_S3_BUCKET_URL}/${response.data.student.profileImagePath}` : 'https://img.freepik.com/vetores-premium/icone-de-um-rosto-de-homem-com-pele-clara_238404-798.jpg'
        }));
    }, []);

    useEffect(() => {
        getDetails(student.studentId);
    }, [getDetails, student.studentId]);

    return (
        <div className="students-details">
            <PageWrapper menuItem="/manage-students">
                <Box className="content">
                    <Header
                        title="Alunos"
                        showReturn
                        subtitle="Seus alunos"
                    />

                    <Box className="content-students" sx={{ mt: 2 }}>
                        <TabContext value={value}>
                            <Box sx={{ mb: 2 }}>
                                <Tabs
                                    value={value}
                                    onChange={(ev, newValue) => setValue(newValue)}
                                    aria-label="Aluno"
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                >
                                    <Tab label="Perfil" value="1" className="menu-tab" />
                                    <Tab label="Protocolos" value="2" className="menu-tab" />
                                    <Tab label="Evoluções" value="3" className="menu-tab" />
                                    <Tab label="Questionários" value="4" className="menu-tab" />
                                    <Tab label="Chat" value="5" className="menu-tab" />
                                </Tabs>
                            </Box>

                            <TabPanel value="1" sx={{ p: 0 }}>
                                <PersonalInfo student={currentStudent} show={true} />
                            </TabPanel>
                            <TabPanel value="2" sx={{ p: 0 }}>
                                <Alert severity="info" sx={{ mb: 1 }}>
                                    *Protocolos representam cada compra do seu aluno e o periodo do plano contratado.
                                </Alert>
                                <Alert severity="warning" sx={{ mb: 1 }}>
                                    Dentro de cada protocolo é possivel montar os treinos e dietas.
                                </Alert>
                                <Protocols
                                    student={currentStudent}
                                    handleUpdate={getDetails}
                                />
                            </TabPanel>
                            <TabPanel value="3" sx={{ p: 0 }}>
                                <PhotosEvolution student={currentStudent} />
                            </TabPanel>
                            <TabPanel value="4" sx={{ p: 0 }}>
                                <Questionnaires
                                    student={currentStudent}
                                    handleUpdate={getDetails}
                                />
                            </TabPanel>
                            <TabPanel value="5" sx={{ p: 0 }}>
                                <ChatMessage student={currentStudent} />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Box>
            </PageWrapper>
        </div>
    )
}

export default StudentDetails;