import React from 'react';
import { useNavigate } from 'react-router-dom';

import Subtitle from "../Subtitle/Subtitle";
import Title from "../Title/Title";

import './StudentHeader.scss';

const StudentHeader = ({ client, tab }) => {
    const navigate = useNavigate();
    const APP_PATH = process.env.REACT_APP_PUBLIC_URL;

    const onNavigate = () => {
        if (tab) {
            sessionStorage.setItem('prevTab', tab);
            navigate(-1, { state: { prevTab: tab } })
        } else {
            navigate(-1);
        }
    };

    return (
        <div className="student-header">
            <div className="return" onClick={onNavigate}>
                <img src={APP_PATH + "arrow-left.svg"} alt={"arrow-left"} />
                <span>Voltar para listagem de gerenciamento</span>
            </div>
            <header>
                <div>
                    <Title title="Gerenciar Treino/Dieta" />
                    <Subtitle subtitle="Selecione abaixo a aba que deseja gerenciar do aluno" />
                </div>
                {client && (
                    <div className="student">
                        <div>
                            <p>Aluno:</p>
                            <img src={client.profileImagePath} alt="Imagem do aluno" />
                            <span>{client.name}</span>
                        </div>
                    </div>
                )}
            </header>
        </div >
    );
};

export default StudentHeader;