import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';

import './ExercisePlayer.scss';

const ExercisePlayer = ({ src, name }) => {
    const ext = src ? src?.split('.')?.slice(-1)[0] : "";
    const isYoutube = src ? src.includes('youtube') : "";
    let videoId = '';

    if (isYoutube) {
        const searchParams = new URLSearchParams(src);
        videoId = (searchParams.get('https://www.youtube.com/watch?v'));
    }
    const [loading, setLoading] = useState(true);

    return (
        <>
            {loading && !isYoutube && (<CircularProgress />)}
            {
                isYoutube && (
                    <iframe className='video'
                        title='Youtube player'
                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                        width={1000}
                        height={500}
                        src={`https://youtube.com/embed/${videoId}?autoplay=0`}>
                    </iframe>
                )
            }
            {ext !== 'mp4' && !isYoutube && (
                <img
                    src={src}
                    alt={name}
                    width={150}
                    height={150}
                    onLoad={() => setLoading(false)}
                />
            )}
            {ext === 'mp4' && !isYoutube && (
                <video
                    loop
                    muted
                    autoPlay
                    src={src}
                    alt={name}
                    preload={'auto'}
                    type={'video/mp4'}
                    width="100%"
                    height="100%"
                    onLoadedData={() => setLoading(false)}
                >
                </video>
            )}
        </>
    );
};

export default ExercisePlayer;