import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Box, AppBar, Toolbar, Typography, SwipeableDrawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import GridViewIcon from '@mui/icons-material/GridView';
import QuizIcon from '@mui/icons-material/Quiz';
import FitnessCenter from "@mui/icons-material/People";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RedeemIcon from '@mui/icons-material/Redeem';
import WalletIcon from '@mui/icons-material/Wallet';

import './SideMenu.scss';

const SideMenu = ({ menuItem }) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const navigate = useNavigate();
    const [menuItemActive, setMenuItemActive] = useState(menuItem);
    const [open, setOpen] = useState(false);
    const [link, setLink] = useState();


    const handleSetActiveItem = (item) => {
        setMenuItemActive(item);
        navigate(item);
    };

    const getMenuMobile = () => (
        <div className="menu-container">
            <div className="logo" onClick={() => setOpen(false)}>
                <img src="/logo-logotipo.png" alt="logo" className="logo" />
                <IconButton
                    edge="start"
                    onClick={() => setOpen(false)}
                    sx={{ marginLeft: 'auto', display: 'block' }}
                    aria-label="close"
                >
                    <CloseIcon style={{ color: '#FFF', fontSize: 28 }} />
                </IconButton>
            </div>
            <Box className={`menu-item manage-students-menu ${menuItemActive === '/manage-students' && 'active'}`} onClick={() => handleSetActiveItem('/manage-students')}>
                <FitnessCenter sx={{ display: { md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Alunos</h4>
            </Box>
            <div className={`menu-item questionnaires ${menuItemActive === '/questionnaire' && 'active'}`} onClick={() => handleSetActiveItem('/questionnaire')}>
                <QuizIcon sx={{ display: { xs: 'flex', md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Questionários</h4>
            </div>
            <div className={`menu-item plans ${menuItemActive === '/plans' && 'active'}`} onClick={() => handleSetActiveItem('/plans')}>
                <GridViewIcon sx={{ display: { md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Consultorias</h4>
            </div>
            <div className={`menu-item dashboard ${menuItemActive === '/dashboard' && 'active'}`} onClick={() => handleSetActiveItem('/dashboard')}>
                <SpaceDashboardIcon sx={{ display: { md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Dashboard</h4>
            </div>
            <div className={`menu-item plans ${menuItemActive === '/wallet' && 'active'}`} onClick={() => handleSetActiveItem('/wallet')}>
                <WalletIcon sx={{ display: { md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Carteira</h4>
            </div>
            <div className={`menu-item my-account ${menuItemActive === '/manage-account' && 'active'}`} onClick={() => handleSetActiveItem('/manage-account')}>
                <ManageAccountsIcon sx={{ display: { md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Minha Conta</h4>
            </div>
        </div>
    );
    const getMenu = () => (
        <div className="menu-container">
            <div className="logo" onClick={() => setOpen(false)}>
                <img src="/logotipo.png" alt="logo" className="logo" />
            </div>
            <div className={`menu-item dashboard ${menuItemActive === '/' && 'active'}`} onClick={() => handleSetActiveItem('/')}>
                <SpaceDashboardIcon sx={{ display: { md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Dashboard</h4>
            </div>
            <div className={`menu-item manage-students-menu ${menuItemActive === '/manage-students' && 'active'}`} onClick={() => handleSetActiveItem('/manage-students')}>
                <FitnessCenter sx={{ display: { md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Alunos</h4>
            </div>
            <div className={`menu-item questionnaires ${menuItemActive === '/questionnaire' && 'active'}`} onClick={() => handleSetActiveItem('/questionnaire')}>
                <QuizIcon sx={{ display: { xs: 'flex', md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Questionários</h4>
            </div>
            <div className={`menu-item plans ${menuItemActive === '/plans' && 'active'}`} onClick={() => handleSetActiveItem('/plans')}>
                <GridViewIcon sx={{ display: { md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Consultorias</h4>
            </div>
            <div className={`menu-item plans ${menuItemActive === '/wallet' && 'active'}`} onClick={() => handleSetActiveItem('/wallet')}>
                <WalletIcon sx={{ display: { md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Carteira</h4>
            </div>
            <div className={`menu-item my-account ${menuItemActive === '/manage-account' && 'active'}`} onClick={() => handleSetActiveItem('/manage-account')}>
                <ManageAccountsIcon sx={{ display: { md: "flex" }, mr: 1, color: '#FFF' }} />
                <h4>Minha Conta</h4>
            </div>
        </div>
    );

    const generateLink = () => {
        const baseUrl = process.env.REACT_APP_NEW_STUDENT_BASE_URL;
        const referralLink = `${baseUrl}/signup?referralId=${consultant.id}`;
        const url = new URL(referralLink).href;
        navigator.clipboard.writeText(url);
        setLink(url);
    };

    return (
        <div className="side-menu">
            <div className="menu-mobile">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" sx={{ bgcolor: '#0c216a' }} >
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={() => setOpen(true)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                Coach
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    {/* Drawer para o menu lateral */}
                    <SwipeableDrawer
                        anchor="left"
                        open={open}
                        onOpen={() => setOpen(true)}   // Corrigido para abrir o menu
                        onClose={() => setOpen(false)} // Corrigido para fechar o menu
                    >
                        <Box
                            sx={{ width: 250, bgcolor: '#0c216a', height: '100%' }}
                            role="presentation"
                            onClick={() => setOpen(false)} // Fecha o drawer ao clicar em um item
                            onKeyDown={() => setOpen(false)}
                        >
                            {getMenuMobile()}
                        </Box>
                        <div
                            className="invite"
                            style={{ display: "flex", justifyContent: "center", backgroundColor: '#0c216a', alignItems: "center", cursor: "pointer" }}
                            onClick={() => generateLink()}
                        >
                            <Tooltip title={`${link ? 'Convite copiado com sucesso' : 'Clique e envie o convite para algum amigo.'}`} arrow>
                                <RedeemIcon sx={{ color: "#c7427b", fontSize: 35 }} className="member-get-member" />
                            </Tooltip>
                        </div>
                    </SwipeableDrawer>
                </Box>
            </div>

            <div className="menu-desktop">
                {getMenu()}
            </div>

            <div
                className="invite"
                style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 25, cursor: "pointer" }}
                onClick={() => generateLink()}
            >
                <Tooltip title={`${link ? 'Convite copiado com sucesso' : 'Clique e envie o convite para algum amigo.'}`} arrow>
                    <RedeemIcon sx={{ color: "#c7427b", fontSize: 35 }} className="member-get-member" />
                </Tooltip>
            </div>
        </div>
    );
};

export default SideMenu;