import React, { useEffect, useState, useCallback } from "react";
import { CommonLoading } from 'react-loadingg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    Box,
    Button,
    IconButton,
    InputBase,
    Paper,
    Typography
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PendingIcon from '@mui/icons-material/Pending';

import Header from "../../Components/Header/Header";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import ModalAddStudent from "../../Components/ModalAddStudent/ModalAddStudent";
import StudentItem from "./Components/StudentItem/StudentItem";
import EmptyState from "../../Components/EmptyState/EmptyState";

import { GetStudents } from "../../Services/Students/index";
import { GetLibrary } from "../../Services/Meals/index";
import Grid from '@mui/material/Grid2';

import "./StudentsMobile.scss";

const StudentsMobile = () => {
    sessionStorage.removeItem('prevTab');
    const access_token = localStorage.getItem('access_token');

    const [show, setShow] = useState(false);
    const [students, setStudents] = useState([]);
    const [showAddStudentModal, setShowAddStudentModal] = useState(false);
    const [search, setSearch] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const loadStudents = useCallback(async (id, remove) => {
        const response = await GetStudents(access_token, page);
        let dataStudents = response.data?.rows.map((s) => ({
            ...s,
            profileImagePath: s.profileImagePath ? `${process.env.REACT_APP_S3_BUCKET_URL}/${s.profileImagePath}` : 'https://img.freepik.com/vetores-premium/icone-de-um-rosto-de-homem-com-pele-clara_238404-798.jpg'
        }));
        if (dataStudents.length === 0) {
            setHasMore(false);
            setStudents([]);
        } else {
            setStudents((prevStudents) => {
                let prevArr = prevStudents;
                if (remove) {
                    prevArr = prevStudents.filter(obj => obj.studentId !== id);
                    return prevArr;
                }
                const mergedStudents = [...dataStudents, ...prevArr].reduce((acc, obj) => {
                    const existingIndex = acc.findIndex(item => item.id === obj.id);
                    if (existingIndex === -1) {
                        acc.push(obj);
                    }
                    return acc;
                }, []);

                if (mergedStudents.length === response.data.count) {
                    setHasMore(false);
                }
                return mergedStudents;
            });
        }
        return dataStudents;
        // eslint-disable-next-line
    }, [access_token, page]);

    const generateLink = async (studentId, plan) => {
        await loadStudents(studentId);
    };

    const fetchMoreData = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const filteredItems = students.filter(student => student.name.toLowerCase().includes(search.toLowerCase()));

    useEffect(() => {
        const getStudents = async () => {
            await loadStudents();
            setShow(true);
        };

        getStudents(page);
    }, [page, loadStudents]);

    useEffect(() => {
        const getLibrary = async () => {
            const foods = await GetLibrary(access_token);
            const library = foods.data.map(f => ({ ...f, label: f.name }))
            sessionStorage.setItem('foodsLibrary', JSON.stringify(library));
        };
        getLibrary();
    }, [access_token]);

    return (
        <div className="students-mobile">
            <PageWrapper menuItem="/manage-students">
                {show && (
                    <Box className="content">
                        <Header
                            title="Alunos"
                            subtitle="Seus alunos"
                        />

                        <Box className="content-students">
                            <Box className="students-title" sx={{ my: 3 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        gap: 2
                                    }}>
                                    <Button
                                        className="new-student-btn"
                                        aria-label="Novo aluno"
                                        onClick={() => setShowAddStudentModal(true)}
                                        startIcon={<AddCircleOutlineIcon />}
                                        sx={{
                                            backgroundColor: '#553872',
                                            color: 'white',
                                            boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.2)',
                                            borderRadius: 2,
                                            paddingX: 2,
                                            width: '50%',
                                            height: 50,
                                            ":hover": { backgroundColor: '#553872' }
                                        }}
                                    >
                                        <Typography sx={{
                                            color: 'white',
                                            fontWeight: '700',
                                            fontSize: 14,
                                        }}>Novo aluno</Typography>
                                    </Button>
                                    <Box sx={{ width: '100%' }}>
                                        <Paper
                                            component="form"
                                            sx={{ display: 'flex', alignItems: 'center', width: { sx: '100%', sm: '35%', md: '25%', lg: '25%', xl: '25%' } }}
                                        >
                                            <InputBase
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder="Pesquisar aluno"
                                                type="search"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                inputProps={{ 'aria-label': 'pesquisar aluno' }}
                                            />
                                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </Box>
                                </Box>
                            </Box>
                            <Grid container spacing={{ xs: 1, md: 4 }}>
                                <Grid container xs={12} md={4} spacing={1}>
                                    <PendingIcon color="error" />
                                    <Typography>Sem Protocolos</Typography>
                                </Grid>
                                <Grid container xs={12} md={4} spacing={1}>
                                    <MoneyOffIcon color="error" />
                                    <Typography>Não pago</Typography>
                                </Grid>
                                <Grid container xs={12} md={4} spacing={1}>
                                    <NewReleasesIcon color="warning" />

                                    <Typography>Pendente montagem</Typography>
                                </Grid>
                                <Grid container xs={12} md={4} spacing={1}>
                                    <CheckCircleIcon color="success" />
                                    <Typography>Finalizado</Typography>
                                </Grid>
                            </Grid>
                            <Box>
                                {students.length === 0 && <EmptyState
                                    showImage={false}
                                    title="Você não possui nenhum aluno."
                                    subtitles={['Clique em Novo Aluno para criar o seu primeiro aluno.']}
                                />}
                                <StudentItem
                                    students={filteredItems}
                                    fetchMoreData={fetchMoreData}
                                    handleUpdate={loadStudents}
                                    hasMore={hasMore}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
                {!show && (
                    <CommonLoading color="#553872" size="large" />
                )}
                <ModalAddStudent
                    onSubmit={generateLink}
                    handleModal={setShowAddStudentModal}
                    open={showAddStudentModal}
                />
            </PageWrapper>
        </div>
    )
}

export default StudentsMobile;