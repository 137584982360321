import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { AppContext } from "./Helpers/Context";

import SignIn from './Pages/SignIn/SignIn';
import ResetPassword from './Pages/ResetPassword/ResetPassword';

import Home from './Pages/Home/Home';
import ManageAccount from './Pages/ManageAccount/ManageAccount';
import Questionnaire from './Pages/Questionnaire/Questionnaire';
import EditQuestionnaire from './Pages/EditQuestionnaire/EditQuestionnaire';
import ManageClientPlan from './Pages/ManageClientPlan/ManageClientPlan';
import QuestionnaireAnswers from './Pages/QuestionnaireAnswers/QuestionnaireAnswers';
import WorkoutDetails from './Pages/WorkoutDetails/WorkoutDetails';
import DietDetails from './Pages/DietDetails/DietDetails';
import Chat from './Pages/Chat/Chat';
import NewStudentForm from './Pages/NewStudentForm/NewStudentForm';
import SignUp from './Pages/SignUp/SignUp';
import Plans from './Pages/Plans/Plans';
import Upgrade from './Pages/Upgrade/Upgrade';
import Terms from './Pages/Terms/Terms';
import Students from './Pages/Students/Students';
import EditPlans from './Pages/EditPlans/EditPlans';
import Wallet from './Pages/Wallet/Wallet';
import StudentsMobile from './Pages/StudentsMobile/StudentsMobile';
import StudentsDetails from './Pages/StudentDetails/StudentDetails';
import PlansWizard from './Pages/PlansWizard/PlansWizard';

import { AuthToken, AuthRefresh } from "./Services/Auth/index";
import { GetConsultant } from './Services/Auth/index';

import Logout from './Services/logout';

import TagManager from 'react-gtm-module';

import './App.scss';

function App() {
  const { state, signOut, signIn } = useContext(AppContext);
  const [isTokenChecked, setIsTokenChecked] = useState(false);
  const isMobile = window.innerWidth < 768;
  const [searchParams] = useSearchParams();

  /* access_token */
  const token = searchParams.get('at');
  /* refresh_token */
  const rt = searchParams.get('rt');

  let access_token = localStorage.getItem('access_token');

  useEffect(() => {
    const refreshToken = async () => {
      const refresh_token = localStorage.getItem('refresh_token');
      const response = await AuthRefresh(refresh_token);

      localStorage.setItem('access_token', response.access_token);
      localStorage.setItem('refresh_token', response.refresh_token);

      return response;
    };

    const login = async () => {
      try {
        // Verifique se o token é válido usando a API
        await AuthToken(access_token);
        const consultant = await GetConsultant(access_token);
        sessionStorage.setItem('consultant', JSON.stringify(consultant.data));

        const refreshResponse = await refreshToken();
        const intervalId = setInterval(async () => {
          await refreshToken();
        }, (refreshResponse.expires_in - 300) * 1000);
        localStorage.setItem('refresh_token_interval_id', intervalId);

        signIn();
      } catch {
        Logout();
        signOut();
      } finally {
        setIsTokenChecked(true);
      }
    };

    if (token) {
      // eslint-disable-next-line
      access_token = token;
      localStorage.setItem('access_token', token);
      localStorage.setItem('refresh_token', rt);
    }
    if (access_token && !state.isSignedIn) {
      login();
    } else {
      setIsTokenChecked(true);
    }
  }, [access_token, signIn, signOut, state.isSignedIn]);

  const tagManagerArgs = {
    gtmId: 'GTM-KCCDFBN2'
  }
  TagManager.initialize(tagManagerArgs)

  return (
    <Routes>
      <Route path="/privacy-police" element={<Terms />} />
      <Route path="/novo-aluno" element={<NewStudentForm />} />
      <Route path="/signup" element={<SignUp />} />

      {!state.isSignedIn && isTokenChecked && (
        <>
          <Route path="/" element={<SignIn />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      )}

      {(state.isSignedIn && access_token) && (
        <>
          <Route path={!isMobile ? '*' : '/dashboard'} element={<Home />} />
          {isMobile && <Route path="*" element={<StudentsMobile />} />}
          <Route path="/upgrade" element={<Upgrade />} />
          {!isMobile && <Route path="/manage-students" element={<Students />} />}
          {isMobile && <Route path="/manage-students" element={<StudentsMobile />} />}
          {isMobile && <Route path="/student/:id" element={<StudentsDetails />} />}
          <Route path="/questionnaire" element={<Questionnaire />} />
          <Route path="/questionnaire/edit" element={<EditQuestionnaire />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/chat/:id" element={<Chat />} />
          <Route path="/manage-plan" element={<ManageClientPlan />} />
          <Route path="/manage-plan/questionnaires" element={<QuestionnaireAnswers />} />
          <Route path="/manage-plan/workout-details" element={<WorkoutDetails />} />
          <Route path="/manage-plan/diet-details" element={<DietDetails />} />
          <Route path="/manage-plan/chat/:id" element={<Chat />} />
          <Route path="/manage-account" element={<ManageAccount />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/plans/edit" element={<EditPlans />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/assistente-protocolos" element={<PlansWizard />} />
        </>
      )}
    </Routes>
  );
}

export default App;