import React from 'react';

import './ModalDelete.scss';

const ModalDelete = ({ handleModal, handleDelete, title, subtitle, text, id }) => {

    const verifyElementClicked = (e) => {
        if (e.target.id === 'modal-delete-container') {
            handleModal();
        }
    };

    return (
        <div className='modal-delete-container' onClick={e => verifyElementClicked(e)} id='modal-delete-container'>
            <div className='modal-delete'>
                <div>
                    <span><img src={process.env.REACT_APP_PUBLIC_URL + "icon-delete-modal.svg"} alt={"icon-delete-modal"} /></span>
                    <h2>{title}</h2>
                    <p>{subtitle}</p>
                    <p>{text}</p>
                    <div>
                        <button className="cancel-btn" onClick={(e) => handleModal(e)}>Cancelar</button>
                        <button className="delete-btn" onClick={(e) => handleDelete(id, e)}>Excluir</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDelete;