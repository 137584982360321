import { GetAsync, PostAsync } from '../api';

/**
 * Método responsável criar um saque
*/
const CreateWithdraw = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await PostAsync(`/withdraw`, config, {});
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por verificar se há saques pendentes
*/
const GetPendingWithdraw = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await GetAsync(`/withdraw/pending`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

export {
    CreateWithdraw,
    GetPendingWithdraw
}