import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Card, CardContent, Typography } from '@mui/material';

import ModalWithdraw from '../ModalWithdraw/ModalWithdraw';

import { GetPendingWithdraw } from '../../../../Services/Withdraw/index';

const CashSummary = ({ pendingAmount, availableAmount, totalWallet, totalManual, total }) => {
    const [openModal, setOpenModal] = useState(false);
    const [pendingWithdraw, setPendingWithdraw] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState(0);

    const formatCurrency = (value) => {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })
    };

    useEffect(() => {
        const getWithdraw = async () => {
            const response = await GetPendingWithdraw();
            setPendingWithdraw(response.data !== undefined);
            setWithdrawAmount(Number(response.data.amount));
        };

        getWithdraw();
    }, []);

    return (
        <>
            <Card>
                <CardContent>
                    <Typography fontSize={20} mb={2}>Resumo</Typography>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-around', gap: 2 }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mb: 1,
                            width: '100%',
                            p: 2,
                            borderRadius: '8px',
                            bgcolor: '#F9FAFB'
                        }}>
                            <Typography variant="body2" fontSize={16}>Pendente</Typography>
                            <Typography variant="body2" fontSize={18} color="#667085" fontWeight={600}>{formatCurrency(pendingAmount)}</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mb: 1,
                            width: '100%',
                            borderRadius: '8px',
                            bgcolor: 'rgb(59 130 246 / 0.05)',
                            p: 2
                        }}>
                            <Typography variant="body2" fontSize={16}>Disponível</Typography>
                            <Typography variant="body2" fontSize={18} color="#17B26A" fontWeight={600}>{formatCurrency(availableAmount)}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        mt: 2,
                        margin: '10px 0 0 0',
                        width: '100%',
                        borderCollapse: 'separate',
                        backgroundColor: 'white',
                        border: '1px solid #EAECF0',
                        borderLeft: '4px solid #EAECF0',
                        borderSpacing: 0,
                        borderRadius: '8px',
                        overflow: 'hidden',
                        whiteSpace: 'normal',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            py: 2,
                            px: 2,
                            borderWidth: 0,
                            borderStyle: 'solid',
                            borderColor: '#e5e7eb',
                            bgcolor: 'rgb(59 130 246 / 0.1)'
                        }}>
                            <Typography variant="body2" fontWeight={700}>Saldo final</Typography>
                            <Typography variant="body2" fontWeight={700}>{formatCurrency(pendingAmount + availableAmount)}</Typography>
                        </Box>
                    </Box>

                    <Button
                        variant="contained"
                        sx={{
                            mt: 2,
                            backgroundColor: '#553872',
                            color: 'white',
                            boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.2)',
                            borderRadius: 2,
                            paddingX: 2,
                            ":hover": { backgroundColor: '#553872' },
                            mb: pendingWithdraw ? 1 : 0
                        }}
                        disabled={pendingWithdraw}
                        onClick={() => setOpenModal(true)}
                    >{pendingWithdraw ? 'Aguardando envio' : 'Efetuar Saque'}
                    </Button>
                    {pendingWithdraw && <Alert severity='success'>Você receberá <b>{formatCurrency(withdrawAmount)}</b> nas próximas 24 horas.</Alert>}

                    <Alert severity="warning" sx={{ mt: 3 }}>
                        Para vendas com cartão de crédito, o saldo pendente estará disponível em 30 dias, enquanto para vendas via PIX, o saldo estará disponível em 1 dia.
                    </Alert>
                </CardContent>
            </Card>

            <ModalWithdraw
                open={openModal}
                handleModal={setOpenModal}
                setPendingWithdraw={setPendingWithdraw}
                availableAmount={availableAmount}
                setWithdrawAmount={setWithdrawAmount}
            />
        </>
    );
};

export default CashSummary;