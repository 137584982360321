import React, { useState } from "react";
import {
    Box,
    Typography,
    Chip,
    IconButton,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import WalletIcon from '@mui/icons-material/Wallet';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Link } from "react-router-dom";

import Feedback from "../../../../Components/Feedback/Feedback";
import ModalDelete from "../../../../Components/ModalDelete/ModalDelete";
import PaymentLink from "../../../../Components/PaymentLink/PaymentLink";

import {
    DeletePlans,
} from '../../../../Services/Plans/index';

const PlanItem = ({ plan, setPlans }) => {
    const COLORS = ['#C7427B', '#1E306E', '#3ac49c', '#db3d60', '#c2c627', '#C7427B', '#1E306E', '#3ac49c', '#db3d60', '#c2c627'];
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const access_token = localStorage.getItem('access_token');
    const baseUrl = process.env.REACT_APP_NEW_STUDENT_BASE_URL;

    const [feedback, setFeedback] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [modalDelete, setModalDelete] = useState(false);

    const handleModalDelete = () => {
        setModalDelete(!modalDelete);
    };

    const removePlan = async (id) => {
        try {
            if (id) {
                await DeletePlans(access_token, id)
            }
            setPlans((prevState) => prevState.filter(p => p.id !== id));
            handleModalDelete();
        } catch {
            setFeedback(true);
            setMessage('Não é possivel deletar um protocolo com clientes ativos.');
            setSeverity('error');
            handleModalDelete();
        }
    };

    return (
        <>
            <Box sx={{
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                borderRadius: '8px',
            }}>
                {feedback && (
                    <Feedback severity={severity} text={message} />
                )}

                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    padding: 5,
                    gap: 1
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box>
                            <Chip
                                label={plan.name}
                                color="primary"
                                sx={{ fontWeight: '700', bgcolor: `${COLORS[plan.id % 10 ?? 0]}1a`, color: COLORS[plan.id % 10 ?? 0] }}
                            />
                        </Box>
                        <Typography variant="subtitle2">{plan.description}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                            <WalletIcon />
                            <Typography fontSize={14} color="#67666b">
                                <Typography component="span" fontWeight={600} fontSize={14}>Valor:</Typography> R$ {plan.price}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                            <TimerOutlinedIcon />
                            <Typography fontSize={14} color="#67666b">
                                <Typography component="span" fontWeight={600} fontSize={14}>Duração:</Typography> {plan.weeks} semanas
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                            <QuestionAnswerOutlinedIcon />
                            <Typography fontSize={14} color="#67666b">
                                <Typography component="span" fontWeight={600} fontSize={14}>Questionário:</Typography> {plan.quiz?.name}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'start',
                        flexDirection: { xs: 'column', sm: 'column' },
                        gap: 1
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'start',
                            gap: 1
                        }}>
                            <Link to="/plans/edit" state={plan} style={{ textDecoration: "none" }} variant="body2">
                                <IconButton onClick={handleModalDelete} sx={{ gap: 1 }}>
                                    <EditIcon />
                                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>Editar Protocolo</Typography>
                                </IconButton>
                            </Link>
                            <IconButton onClick={handleModalDelete} >
                                <DeleteIcon sx={{ color: '#c7427b' }} />
                            </IconButton>
                        </Box>
                        <PaymentLink link={new URL(`${baseUrl}/novo-aluno?pid=${plan.id}&cn=${consultant.name}&cid=${consultant.id}`).href} />
                    </Box>
                </Box>
            </Box>

            {modalDelete && (
                <ModalDelete
                    handleModal={handleModalDelete}
                    title="Excluir protocolo"
                    subtitle="Deseja excluir o protocolo:"
                    text={plan.name}
                    id={plan.id}
                    handleDelete={removePlan}
                />
            )
            }
        </>
    );
};

export default PlanItem;
