import React from 'react';

import Title from '../../../../Components/Title/Title';

import './ModalPreview.scss';

const ModalPreview = ({ handleModal, questions }) => {
    const verifyElementClicked = (e) => {
        if (e.target.id === 'modal-add-container') {
            handleCloseModal();
        }
    };

    const handleCloseModal = () => {
        handleModal();
    };

    return (
        <div className='modal-add-container' onClick={e => verifyElementClicked(e)} id='modal-add-container'>
            <div className='modal-add-question'>
                <div className='titles'>
                    <div>
                        <div className='div-image'>
                            <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-quiz.svg"} alt={"icon-modal-quiz"} />
                        </div>
                        <div className='div-title'>
                            <Title title={'Preview do questionário'} />
                            <p>Veja as perguntas do formulário abaixo</p>
                        </div>
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleCloseModal} />
                </div>

                {questions.length > 0 ? (
                    questions.map((question, key) => (
                        <div key={key} className='new-question'>
                            <p className='p'>Pergunta {key + 1}</p>
                            <div className='input'>
                                <input
                                    name={question.description}
                                    label={'Resposta do aluno'}
                                    value={question.description}
                                    disabled
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='empty-list'>
                        <p>Ainda não há perguntas no questionário, insira a primeira!</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModalPreview;