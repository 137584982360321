import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

import './Feedback.scss';

const Feedback = ({ text, severity }) => {
    return (
        <Alert severity={severity}>
            <AlertTitle>{text}</AlertTitle>
        </Alert>
    );
}

export default Feedback;