import React from 'react';

import './CardStudentAnswer.scss';

const CardStudentAnswer = ({ item, index }) => (
    <div className="card-answers">
        <div className="title">
            <img src={process.env.REACT_APP_PUBLIC_URL + "icon-question.svg"} alt={"icon-question"} />
            <div>
                <p>Pergunta {index + 1}</p>
            </div>
        </div>

        <div className="description">
            <p>Descrição da pergunta</p>
            <input type="text" value={item.question} disabled />
        </div>

        <div className="answer">
            <h4>RESPOSTA:</h4>
            <h6>{item.asnwer}</h6>
        </div>
    </div>
);

export default CardStudentAnswer;