import { DeleteAsync, GetAsync, PostAsync } from '../api';

/**
 * Método responsável criar um agendamento
*/
const CreateSchedule = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/schedules`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável deletar um agendamento
*/
const DeleteSchedule = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await DeleteAsync(`/schedules/${id}`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por listar schedules
*/
const GetSchedules = (token, date) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/schedules?size=100&order=ScheduleStart&orderBy=ASC&date=${date}`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

export {
    GetSchedules,
    CreateSchedule,
    DeleteSchedule
}