import React from "react";
import { useLocation } from 'react-router-dom';

import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import Header from "../../Components/Header/Header";
import PlanForm from './components/PlanForm/PlanForm';

import "./EditPlans.scss";

const EditPlans = () => {
    const location = useLocation();
    const plan = location.state;

    return (
        <div className="plans">
            <PageWrapper menuItem="/plans">
                <div className="content">
                    <Header
                        title={`${plan ? 'Editar' : 'Criar'} consultoria`}
                        subtitle="Automatize seu processo de vendas com as consultorias"
                        showReturn
                    />

                    <div className="plans-container">
                        <PlanForm plan={plan} />
                    </div>
                </div>
            </PageWrapper>
        </div>
    );
};

export default EditPlans;
