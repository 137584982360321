import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Avatar } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Subtitle from '../Subtitle/Subtitle';
import Title from '../Title/Title';

import './Header.scss';

const Header = ({ title, subtitle, showReturn = false, showInfo = true }) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const navigate = useNavigate();

    return (
        <>
            <Grid container spacing={1}>
                <Grid size={{ xs: 12 }}>
                    {showReturn && (
                        <div className="return" onClick={() => navigate(-1)}>
                            <img src={process.env.REACT_APP_PUBLIC_URL + "arrow-left.svg"} alt="arrow-left" />
                            <span>Voltar</span>
                        </div>
                    )}
                </Grid>
                <Grid container size={{ xs: 12, md: 9 }}>
                    <Grid size={{ xs: 6, md: 8 }}>
                        <Title title={title} />
                        <Subtitle subtitle={subtitle} />
                    </Grid>
                    <Grid size={{ xs: 4, md: 4 }}>
                        {showInfo && (
                            <>
                                {(!consultant.plan && consultant.referralId !== 'betatesters') && <Button sx={{
                                    background: 'linear-gradient(76.71deg, rgb(253, 200, 48) -18.89%, rgb(243, 115, 53) 99.03%)',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                }} onClick={() => navigate('/upgrade')}>UPGRADE</Button>}

                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid size={{ xs: 6, md: 3 }} sx={{ display: { xs: 'none', sm: 'block' }, alignItems: "flex-end" }}>
                    <Grid container display="flex" >
                        <Grid size={{ xs: 2 }}>
                            <Avatar
                                alt={consultant.name}
                                src={consultant?.profileImagePath ? `${process.env.REACT_APP_S3_BUCKET_URL}/${consultant.profileImagePath}?${new Date().getMilliseconds()}`
                                    : "https://img.freepik.com/vetores-premium/icone-de-um-rosto-de-homem-com-pele-clara_238404-798.jpg"} />
                        </Grid>
                        <Grid size={{ xs: 10 }}>
                            <strong>{consultant?.name}</strong> <br></br>
                            <span>{consultant?.occupation}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Header;