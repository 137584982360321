import React from 'react';

import './Subtitle.scss';

const Subtitle = ({ subtitle }) => {
    return (
        <div className="subtitle">
            <p>{subtitle}</p>
        </div>
    );
}

export default Subtitle;