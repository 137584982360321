import { Typography } from '@mui/material';
import React from 'react';
import Joyride, { STATUS } from 'react-joyride';

const OnboardingTour = ({ run, setRun, steps, page }) => {

    const handleJoyrideCallback = (data) => {
        const { status } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED].includes(status)) {
            setRun(false);
            localStorage.setItem(`guided-tour-${page}`, 'true');
        }
    };
    let scrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
    );

    return (
        <Joyride
            locale={{
                next: <Typography>Próximo</Typography>,
                back: <Typography>Voltar</Typography>,
                last: <Typography>Fim</Typography>
            }}
            run={run}
            steps={steps}
            continuous={true}
            callback={handleJoyrideCallback}
            styles={{
                options: {
                    zIndex: 10000,
                },
                overlay: { height: scrollHeight * 2 }
            }}
        />
    );
};

export default OnboardingTour;
