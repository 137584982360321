import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Modal } from '@mui/material';

import TextFieldTrainMe from '../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';
import Title from '../../../../Components/Title/Title';

import { CreateDiet } from "../../../../Services/Diets/index";

import './ModalAddDiet.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo nome da refeição é obrigatório!"),
});

const ModalAddDiet = ({ modal, handleModal, dietList, setDietList, diet, setDiet, client, fetchDataDiets }) => {
    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            inicialDate: new Date(),
            endDate: new Date(),
        },
    });
    const access_token = localStorage.getItem('access_token');

    const handleCloseModal = () => {
        setDiet(undefined);
        handleModal();
    };

    const onSubmitHandler = async (data) => {
        const createPayload = {
            name: data.name,
            plansActivity: client.plan.id,
            dietStart: new Date(),
            dietEnd: new Date()
        };

        await CreateDiet(access_token, createPayload);

        const dietListPayload = [...dietList || []];
        dietListPayload.push({
            ...createPayload, info: {
                totalCalories: 0,
                protein: 0,
                carbohydrates: 0,
                lipids: 0,
                fiber: 0
            }
        });
        setDietList(dietListPayload);

        handleCloseModal();

        // Atualiza a listagem
        fetchDataDiets(client.plan.id);
    };

    useEffect(() => {
        if (diet) {
            setValue('name', diet.name);
            setValue('inicialDate', diet.inicialDate);
            setValue('endDate', diet.endDate);
        }
    }, [setValue, diet])

    return (
        <Modal
            open={modal}
            onClose={handleModal}
            className='modal-plans'
            id="modal-plans"
        >
            <div className='modal-add-container'>
                <form className='modal-add' id='form' onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className='titles'>
                        <div>
                            <div className='div-image'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-diet.svg"} alt={"icon-modal-diet"} />
                            </div>
                            <div className='div-title'>
                                <Title title={`${diet ? 'Editar' : 'Adicionar'} refeição`} />
                                <p>Insira as informações da dieta abaixo</p>
                            </div>
                        </div>

                        <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleCloseModal} />
                    </div>

                    <div className='new-quiz'>
                        <div className='input'>
                            <TextFieldTrainMe
                                control={control}
                                name={'name'}
                                label={'Insira o nome da refeição'}
                                fullWidth
                                errors={errors.name}
                            />
                        </div>
                    </div>

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <PrimaryButton text={`${diet ? 'Editar' : 'Criar'} refeição`} />
                    </Box>
                </form>
            </div>
        </Modal>
    );
};

export default ModalAddDiet;