import React, { useContext, useCallback } from "react";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, ListItemButton, ListItemIcon } from "@mui/material";

import { ChatContext } from '../../../../Context/ChatContext';

import "./ChatListItem.scss";

const ChatListItem = ({ chat }) => {
  const consultant = JSON.parse(sessionStorage.getItem('consultant'));
  const { setUserInfo, setChatId, chatId } = useContext(ChatContext);

  const setChat = useCallback(async () => {
    setChatId(chat.id);
    setUserInfo(chat.userInfo);
  }, [chat.id, chat.userInfo, setChatId, setUserInfo]);

  const getLastMessage = () => {
    if (chat.lastMessage?.content) {
      if (chat.lastMessage?.content.length > 25) {
        return ` ${chat.lastMessage?.content.substring(0, 25)}...`;
      }
      return ` ${chat.lastMessage?.content}`;
    } else {
      return "-";
    }
  };

  return (
    <div className="chat-list-item">
      <ListItem
        alignItems="flex-start"
        disablePadding
        sx={{ borderLeft: chatId === chat.id ? "3px solid #1e306e" : "" }}
      >
        <ListItemButton
          onClick={() => setChat()}
          selected={chatId === chat.id}
        >
          <ListItemAvatar>
            <Avatar
              alt={chat.userInfo?.name}
              src={chat.userInfo?.profileImagePath ? `${process.env.REACT_APP_S3_BUCKET_URL}/${chat.userInfo.profileImagePath}` : ""}
            />
          </ListItemAvatar>
          <ListItemText
            primary={chat.userInfo.name}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {chat.lastMessage?.sender.id === consultant.id ? 'Você:' : `${chat.userInfo.name}:`}
                </Typography>
                {getLastMessage()}
              </React.Fragment>
            }
          />
          {chat.unreadMessages > 0 && (
            <ListItemIcon>
              <Box sx={{
                backgroundColor: "#db3d60",
                borderRadius: "100%",
                height: "25px",
                width: "25px",
                textAlign: "center",
                color: "white"
              }}>
                {chat.unreadMessages}
              </Box>
            </ListItemIcon>
          )}
        </ListItemButton>
      </ListItem>
      <Divider variant="fullWidth" component="div" />
    </div>
  );
};

export default ChatListItem;
